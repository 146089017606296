// src/components/BaconCipherAlphabet.js
import React from 'react';

const BaconCipherAlphabet = () => {
  const baconAlphabet = {
    'A': 'AAAAA', 'B': 'AAAAB', 'C': 'AAABA', 'D': 'AAABB', 'E': 'AABAA',
    'F': 'AABAB', 'G': 'AABBA', 'H': 'AABBB', 'I': 'ABAAA', 'J': 'ABAAB',
    'K': 'ABABA', 'L': 'ABABB', 'M': 'ABBAA', 'N': 'ABBAB', 'O': 'ABBBA',
    'P': 'ABBBB', 'Q': 'BAAAA', 'R': 'BAAAB', 'S': 'BAABA', 'T': 'BAABB',
    'U': 'BABAA', 'V': 'BABAB', 'W': 'BABBA', 'X': 'BABBB', 'Y': 'BBAAA',
    'Z': 'BBAAB'
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <h2 className="text-2xl font-bold mb-4">Bacon's Cipher Alphabet</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {Object.entries(baconAlphabet).map(([letter, code]) => (
          <div key={letter} className="flex flex-col items-center p-2 border rounded">
            <span className="text-2xl font-bold">{letter}</span>
            <span className="text-sm font-mono">{code}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BaconCipherAlphabet;