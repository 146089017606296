import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LearnDropdown from "./CiphersDropDown";
import ThemeToggle from "./ThemeToggle";
import BasicsDropdown from "./BasicsDropDown";
const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="sticky top-0 bg-primary-light dark:bg-primary-dark text-white p-4 z-50">
      <div className="container mx-auto flex justify-between items-center">
      <div
  className="text-2xl font-bold cursor-pointer max-w-[50px] max-h-[50px]"  // Adjust sizes as needed
  onClick={() => navigate("/")}
>
  <img src="/logo2.png" alt="Logo" className="w-full h-full object-contain" />
</div>
        <div className="hidden md:flex items-center space-x-4">
          <div
            className="cursor-pointer hover:text-secondary-light"
            onClick={() => navigate("/about")}
          >
            About
          </div>
          <BasicsDropdown />
          <LearnDropdown />
          <div
            className="cursor-pointer hover:text-secondary-light"
            onClick={() => navigate("/challenge")}
          >
            Challenge
          </div>
          <div
            className="cursor-pointer hover:text-secondary-light"
            onClick={() => navigate("/products")}
          >
            Resources
          </div>
          <div
            className="cursor-pointer hover:text-secondary-light"
            onClick={() => navigate("/education")}
          >
            Education
          </div>
          <div className="relative">
            <ThemeToggle />
          </div>
        </div>
        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className="focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
              ></path>
            </svg>
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden">
          <div className="p-4 space-y-4">
            <div
              className="cursor-pointer hover:text-secondary-light"
              onClick={() => {
                navigate("/about");
                toggleMenu();
              }}
            >
              About
            </div>

            <BasicsDropdown toggleMenu={toggleMenu} />
            <LearnDropdown toggleMenu={toggleMenu} />
            <div
              className="cursor-pointer hover:text-secondary-light"
              onClick={() => {
                navigate("/challenge");
                toggleMenu();
              }}
            >
              Challenge
            </div>
            <div
              className="cursor-pointer hover:text-secondary-light"
              onClick={() => {
                navigate("/products");
                toggleMenu();
              }}
            >
              Resources
            </div>
            <div
              className="cursor-pointer hover:text-secondary-light"
              onClick={() => {
                navigate("/education");
                toggleMenu();
              }}
            >
              Education
            </div>
            <div className="relative">
              <ThemeToggle />
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
