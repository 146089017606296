import React, { useState, useEffect } from 'react';

const AffineCipherApp = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [a, setA] = useState(5);
  const [b, setB] = useState(8);
  const [mode, setMode] = useState('encode');
  const [testQuestion, setTestQuestion] = useState('');
  const [testAnswer, setTestAnswer] = useState('');
  const [testA, setTestA] = useState(0);
  const [testB, setTestB] = useState(0);
  const [feedback, setFeedback] = useState('');

  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  const modInverse = (a, m) => {
    for (let x = 1; x < m; x++) {
      if (((a % m) * (x % m)) % m === 1) {
        return x;
      }
    }
    return 1;
  };

  const isValidA = (value) => {
    return value % 2 !== 0 && value % 13 !== 0;
  };

  const encodeAffine = (text, a, b) => {
    return text.toUpperCase().replace(/[A-Z]/g, char => {
      const x = alphabet.indexOf(char);
      return alphabet[(a * x + b) % 26];
    });
  };

  const decodeAffine = (text, a, b) => {
    const aInverse = modInverse(a, 26);
    return text.toUpperCase().replace(/[A-Z]/g, char => {
      const y = alphabet.indexOf(char);
      return alphabet[(aInverse * (y - b + 26)) % 26];
    });
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    processInput(e.target.value, a, b);
  };

  const handleAChange = (e) => {
    const value = parseInt(e.target.value);
    if (isValidA(value)) {
      setA(value);
      processInput(input, value, b);
    }
  };

  const handleBChange = (e) => {
    const value = parseInt(e.target.value);
    setB(value);
    processInput(input, a, value);
  };

  const processInput = (text, a, b) => {
    const processedText = mode === 'encode' ? encodeAffine(text, a, b) : decodeAffine(text, a, b);
    setOutput(processedText);
  };

  const handleAppModeChange = (newMode) => {
    setMode(newMode);
    setInput('');
    setOutput('');
    setTestQuestion('');
    setTestAnswer('');
    setTestA(0);
    setTestB(0);
    setFeedback('');
    if (newMode === 'test') {
      generateTestQuestion();
    }
  };

  const generateTestQuestion = () => {
    const words = ['AFFINE', 'CIPHER', 'CRYPTOGRAPHY', 'SECRET', 'MESSAGE'];
    const randomWord = words[Math.floor(Math.random() * words.length)];
    const randomA = [1, 3, 5, 7, 9, 11, 15, 17, 19, 21, 23, 25][Math.floor(Math.random() * 12)];
    const randomB = Math.floor(Math.random() * 26);
    const encryptedWord = encodeAffine(randomWord, randomA, randomB);
    setTestQuestion(encryptedWord);
    setTestA(randomA);
    setTestB(randomB);
    setTestAnswer('');
    setFeedback('');
  };

  const checkTestAnswer = () => {
    const decryptedAnswer = decodeAffine(testQuestion, testA, testB);
    if (testAnswer.toUpperCase() === decryptedAnswer) {
      setFeedback('Correct! Well done!');
    } else {
      setFeedback('Incorrect. Try again!');
    }
  };

  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-4">Affine Cipher</h2>
      
      <div className="mb-4">
        <button 
          onClick={() => handleAppModeChange('encode')} 
          className={`mr-2 px-4 py-2 rounded ${mode === 'encode' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Encode
        </button>
        <button 
          onClick={() => handleAppModeChange('decode')} 
          className={`mr-2 px-4 py-2 rounded ${mode === 'decode' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Decode
        </button>
        <button 
          onClick={() => {
            if (mode !== 'test') {
              handleAppModeChange('test');
            } else {
              generateTestQuestion();
            }
          }} 
          className={`px-4 py-2 rounded ${mode === 'test' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          {mode === 'test' ? 'New Test' : 'Test Skills'}
        </button>
      </div>

      {mode !== 'test' && (
        <>
          <div className="mb-4 flex space-x-4">
            <div>
              <label className="block text-sm font-medium mb-1">a:</label>
              <input
                type="number"
                value={a}
                onChange={handleAChange}
                className="w-16 p-1 border rounded"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">b:</label>
              <input
                type="number"
                value={b}
                onChange={handleBChange}
                className="w-16 p-1 border rounded"
              />
            </div>
          </div>
          <div className="mb-4">
            <textarea
              value={input}
              onChange={handleInputChange}
              placeholder={`Enter text to ${mode}`}
              className="w-full p-2 border rounded"
              rows="4"
            />
          </div>
          <div className="mb-4">
            <h3 className="font-bold mb-2">{mode === 'encode' ? 'Encoded' : 'Decoded'} Text:</h3>
            <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded break-all">
              {output}
            </div>
          </div>
        </>
      )}

      {mode === 'test' && (
        <>
          <div className="mb-4">
            <h3 className="font-bold mb-2">Decrypt this code:</h3>
            <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded break-all">
              {testQuestion}
            </div>
            <p className="mt-2">a: {testA}, b: {testB}</p>
          </div>
          <div className="mb-4">
            <input
              type="text"
              value={testAnswer}
              onChange={(e) => setTestAnswer(e.target.value)}
              placeholder="Enter decrypted text"
              className="w-full p-2 border rounded"
            />
          </div>
          <button 
            onClick={checkTestAnswer}
            className="px-4 py-2 bg-green-500 text-white rounded"
          >
            Check Answer
          </button>
          {feedback && (
            <div className="mt-4 p-2 bg-gray-100 dark:bg-gray-700 rounded">
              {feedback}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AffineCipherApp;