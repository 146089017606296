import React, { useContext } from 'react';
import { ThemeContext } from '../utils/themeContext';

const History = () => {
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark';

  const HighlightedText = ({ children }) => (
    <span className={`font-semibold ${isDarkMode ? 'text-primary-dark' : 'text-primary-light'}`}>
      {children}
    </span>
  );

  return (
    <div className={`max-w-3xl mx-auto p-6 ${isDarkMode ? 'bg-dark text-text-dark' : 'bg-secondary-light text-text-light'}`}>
      <header className="border-b-2 border-primary-light dark:border-primary-dark pb-4 mb-6">
        <h1 className={`text-4xl font-bold ${isDarkMode ? 'text-primary-dark' : 'text-primary-light'}`}>
          The History of Ciphers
        </h1>
      </header>

      <div>
        <div className="mb-8">
          <div className={`font-bold text-xl mb-2 ${isDarkMode ? 'text-primary-dark' : 'text-primary-light'}`}>
            Ancient Times - Middle Ages
          </div>
          <p className="mb-4">
            The history of ciphers stretches back to ancient civilizations. Early forms of secret writing appeared in Egypt around 1900 BCE, with non-standard hieroglyphs used in tomb inscriptions. In ancient Greece, the Spartans used a device called the <HighlightedText>scytale</HighlightedText> for military communications, which involved wrapping a strip of parchment around a rod and writing across it. The unwrapped strip would appear meaningless unless wrapped around a rod of the same diameter.
          </p>
          <p className="mb-4">
            The Roman Empire saw the use of simple <HighlightedText>substitution ciphers</HighlightedText>, most famously the <HighlightedText>Caesar cipher</HighlightedText>, where each letter in the message was shifted a fixed number of positions in the alphabet. This basic concept dominated cryptography for centuries. During the Islamic Golden Age (8th-13th centuries), Arab scholars made significant advancements in cryptanalysis. Al-Kindi's work on <HighlightedText>frequency analysis</HighlightedText> in the 9th century laid the foundation for breaking substitution ciphers.
          </p>
        </div>

        <div className="mb-8">
          <div className={`font-bold text-xl mb-2 ${isDarkMode ? 'text-primary-dark' : 'text-primary-light'}`}>
            Renaissance - 19th Century
          </div>
          <p className="mb-4">
            The Renaissance brought about a revolution in cipher complexity. In 1465, Leon Battista Alberti invented the <HighlightedText>polyalphabetic cipher</HighlightedText>, which used multiple substitution alphabets. This innovation made ciphers significantly harder to break and led to the development of more advanced systems like the <HighlightedText>Vigenère cipher</HighlightedText> in the 16th century. These ciphers were considered unbreakable for centuries and were widely used in diplomacy and warfare.
          </p>
        </div>

        <div className="mb-8">
          <div className={`font-bold text-xl mb-2 ${isDarkMode ? 'text-primary-dark' : 'text-primary-light'}`}>
            19th - Early 20th Century
          </div>
          <p className="mb-4">
            The 19th and early 20th centuries saw the mechanization of ciphers. The invention of the telegraph created a need for more secure commercial codes. World War I spurred further advancements, with the breaking of the <HighlightedText>Zimmermann Telegram</HighlightedText> playing a crucial role in bringing the United States into the war. The interwar period saw the development of <HighlightedText>rotor machines</HighlightedText>, culminating in the famous <HighlightedText>Enigma machine</HighlightedText> used by Germany in World War II.
          </p>
        </div>

        <div className="mb-8">
          <div className={`font-bold text-xl mb-2 ${isDarkMode ? 'text-primary-dark' : 'text-primary-light'}`}>
            Mid-20th Century - Present
          </div>
          <p className="mb-4">
            The advent of computers in the mid-20th century revolutionized both cryptography and cryptanalysis. The breaking of the Enigma code by Allied codebreakers at Bletchley Park, using early computing devices, was a pivotal moment in the history of cryptography. In the decades since, the rise of digital technology has led to the development of incredibly complex cipher systems. The invention of <HighlightedText>public-key cryptography</HighlightedText> in the 1970s by Whitfield Diffie and Martin Hellman marked the beginning of modern cryptography, enabling secure communication over insecure channels and forming the backbone of internet security today.
          </p>
        </div>
      </div>
    </div>
  );
};

export default History;