import React, { useState } from 'react';
import { Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EnigmaMachineApp from '../../components/ciphers/EnigmaMachine';

const EnigmaMachinePage = () => {
  const [expandedSection, setExpandedSection] = useState('generalInfo');

  const handleToggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <div className="min-h-screen flex flex-col bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <div className="flex flex-1 flex-col md:flex-row">
        <div className="md:w-2/3 p-6">
        <h1 className="text-3xl font-bold mb-6">
        Enigma: The Revolutionary Machine that Changed Cryptography
          </h1>
          <div className="space-y-4">
            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('generalInfo')}>
                <h2 className="text-xl font-semibold">General Information</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'generalInfo' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'generalInfo'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Enigma machine was an electro-mechanical encryption device used extensively by Nazi Germany during World War II. It was a complex cipher machine that looked like a typewriter but incorporated a series of rotors to scramble plaintext messages into ciphertext [1].
                  </p>
                  <p className="mb-4">
                    The machine's complexity allowed for approximately 158,962,555,217,826,360,000 different settings, making it one of the most sophisticated encryption devices of its time [2].
                  </p>
                  <p>
                    The Enigma's significance in history is twofold: it played a crucial role in World War II communications, and the efforts to break its code led to significant advancements in cryptanalysis and early computer science [3].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('history')}>
                <h2 className="text-xl font-semibold">History</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'history' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'history'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Enigma machine was invented by German engineer Arthur Scherbius in 1918, initially for commercial use. In the 1920s, various versions of the Enigma were adopted by military and government services of several countries, most notably Nazi Germany [1].
                  </p>
                  <p className="mb-4">
                    During World War II, the German military used the Enigma extensively, believing it to be unbreakable. However, Polish cryptographers, including Marian Rejewski, made crucial breakthroughs in decrypting Enigma messages in the 1930s [2].
                  </p>
                  <p className="mb-4">
                    Building on the Polish work, British cryptanalysts at Bletchley Park, including Alan Turing, developed techniques and machines to decrypt Enigma messages throughout the war. This effort, codenamed Ultra, provided crucial intelligence that significantly impacted the course of the war [3].
                  </p>
                  <p>
                    The breaking of the Enigma code remained a closely guarded secret for decades after the war. It's estimated that cracking Enigma shortened the war by two to four years, potentially saving millions of lives [4].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('howItWorks')}>
                <h2 className="text-xl font-semibold">How It Works</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'howItWorks' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'howItWorks'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Enigma machine's encryption process involves several components [1]:
                  </p>
                  <ol className="list-decimal pl-6 space-y-2">
                    <li>Keyboard: For inputting the plaintext.</li>
                    <li>Plugboard: Allows pairs of letters to be swapped before entering the rotor mechanism.</li>
                    <li>Rotors: Typically three or four rotating discs, each with 26 electrical contacts on each face, representing the alphabet.</li>
                    <li>Reflector: Redirects current back through the rotors by a different route.</li>
                    <li>Lampboard: Displays the enciphered letter.</li>
                  </ol>
                  <p className="mt-4">
                    When a key is pressed, electrical current flows through the plugboard, then through the rotors in a complex path, is reflected back through the rotors, and finally lights up a lamp indicating the enciphered letter [2].
                  </p>
                  <p className="mt-4">
                    The rotors move with each keystroke, changing the electrical pathway and thus the substitution alphabet for each letter. This movement creates a polyalphabetic substitution cipher, making frequency analysis much more difficult [3].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('significance')}>
                <h2 className="text-xl font-semibold">Significance and Legacy</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'significance' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'significance'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Enigma machine and the efforts to break its code had far-reaching impacts:
                  </p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Military: Decryption of Enigma messages provided crucial intelligence during World War II, influencing numerous military decisions [3].</li>
                    <li>Cryptography: The challenge of breaking Enigma led to significant advancements in cryptanalysis techniques [2].</li>
                    <li>Computer Science: The development of machines to crack Enigma, particularly the Bombe and Colossus, contributed to the birth of modern computing [4].</li>
                    <li>Ethics: The story of Enigma raises important questions about the balance between national security and individual privacy [1].</li>
                  </ul>
                  <p className="mt-4">
                    Today, the Enigma machine stands as a symbol of the crucial role of information security and the potential for human ingenuity to overcome seemingly insurmountable challenges [3].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('references')}>
                <h2 className="text-xl font-semibold">References</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'references' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'references'}>
                <div className="mt-2">
                  <ol className="list-decimal pl-5">
                    <li>
                      Singh, S. (1999). The Code Book: The Science of Secrecy from Ancient Egypt to Quantum Cryptography. Anchor Books.
                    </li>
                    <li>
                      Copeland, B. J. (2004). The Essential Turing. Oxford University Press.
                    </li>
                    <li>
                      Kahn, D. (1996). The Codebreakers: The Comprehensive History of Secret Communication from Ancient Times to the Internet. Scribner.
                    </li>
                    <li>
                      Hodges, A. (2014). Alan Turing: The Enigma. Princeton University Press.
                    </li>
                  </ol>
                </div>
              </Collapse>
            </div>
          </div>
        </div>

        <div className="md:w-1/3 p-6 bg-secondary-light dark:bg-secondary-dark shadow-lg">
          <EnigmaMachineApp/>
        </div>
      </div>
    </div>
  );
};

export default EnigmaMachinePage;