import React, { useState, useEffect, useRef, useContext } from "react";
import * as d3 from "d3";
import { Slider, Switch } from "@mui/material";
import { ThemeContext } from "../../utils/themeContext";

const CaesarCipher = () => {
  const [inputText, setInputText] = useState("");
  const [shift, setShift] = useState(3);
  const [outputText, setOutputText] = useState("");
  const [isDecryptMode, setIsDecryptMode] = useState(false);
  const { theme } = useContext(ThemeContext);
  const svgRef = useRef(null);

  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const width = 250;
  const height = 250;
  const radius = Math.min(width, height) / 2 - 20;

  useEffect(() => {
    drawWheel(theme);
  }, [theme]);

  useEffect(() => {
    updateCipher(inputText);
  }, [inputText, shift, isDecryptMode]);

  const drawWheel = () => {
    const isDarkMode = theme === "dark";
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const wheel = svg
      .append("g")
      .attr("transform", `translate(${width / 2},${height / 2})`);

    const strokeColor = isDarkMode
      ? "stroke-primary-dark"
      : "stroke-primary-light";
    const textColor = isDarkMode ? "white" : "black"; // Direct color value
    const lineColor = isDarkMode
      ? "stroke-secondary-dark"
      : "stroke-third-light";

    wheel
      .append("circle")
      .attr("class", `${strokeColor} fill-none`)
      .attr("r", radius);

    wheel
      .append("circle")
      .attr("class", `${strokeColor} fill-none stroke-dashed`)
      .attr("r", radius - 15);

    const angleStep = (2 * Math.PI) / 26;

    alphabet.split("").forEach((letter, i) => {
      const angle = i * angleStep - Math.PI / 2;
      const x = radius * Math.cos(angle);
      const y = radius * Math.sin(angle);

      const letterGroup = wheel
        .append("g")
        .attr("class", "letter-group")
        .attr("transform", `translate(${x},${y})`);

      letterGroup
        .append("text")
        .attr("class", "text-xs text-center")
        .attr("dy", "0.35em")
        .attr("fill", textColor) // Set fill directly
        .text(letter);

      wheel
        .append("line")
        .attr("class", `${lineColor}`)
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", x)
        .attr("y2", y);
    });

    svg
      .append("path")
      .attr(
        "d",
        `M${width / 2},${height / 2 - radius - 15} L${width / 2 - 10},${
          height / 2 - radius
        } L${width / 2 + 10},${height / 2 - radius} Z`
      )
      .attr("fill", "#10B981")
      .attr(
        "transform",
        `rotate(180, ${width / 2}, ${height / 2 - radius - 15})`
      );
  };

  const updateCipher = (text) => {
    const newOutputText = text
      .toUpperCase()
      .split("")
      .map((char) => {
        if (alphabet.includes(char)) {
          const index = isDecryptMode
            ? (alphabet.indexOf(char) - shift + 26) % 26
            : (alphabet.indexOf(char) + shift) % 26;
          return alphabet[index];
        }
        return char;
      })
      .join("");

    setOutputText(newOutputText);
    if (text.length > 0) {
      rotateWheel(text[text.length - 1]);
    }
  };

  const rotateWheel = (char) => {
    const isDarkMode = theme === "dark";
    if (!char || !alphabet.includes(char.toUpperCase())) return;

    const svg = d3.select(svgRef.current);
    const processedChar = isDecryptMode
      ? alphabet[(alphabet.indexOf(char.toUpperCase()) - shift + 26) % 26]
      : alphabet[(alphabet.indexOf(char.toUpperCase()) + shift) % 26];
    const rotationAngle = (-alphabet.indexOf(processedChar) * 360) / 26;

    const textColor = isDarkMode ? "white" : "black"; // Direct color value

    svg
      .selectAll("text")
      .transition()
      .duration(500)
      .attr("fill", (d) => (d === processedChar ? "#22D3EE" : textColor)) // Set fill directly
      .attr("font-size", (d) => (d === processedChar ? "16px" : "12px"));

    svg
      .select("g")
      .transition()
      .duration(1000)
      .attr(
        "transform",
        `translate(${width / 2},${height / 2}) rotate(${rotationAngle})`
      );

    svg
      .selectAll(".letter-group")
      .transition()
      .duration(1000)
      .attr("transform", (d, i) => {
        const angle = i * (360 / 26) - 90;
        const x = radius * Math.cos((angle * Math.PI) / 180);
        const y = radius * Math.sin((angle * Math.PI) / 180);
        return `translate(${x},${y}) rotate(${-rotationAngle})`;
      });
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value.toUpperCase());
  };

  const handleReset = () => {
    setInputText("");
    setShift(3);
    setIsDecryptMode(false);
    setOutputText("");
    drawWheel();
  };

  return (
    <div
      className={`p-4 rounded-lg shadow-lg bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark`}
    >
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-lg sm:text-xl md:text-2xl font-semibold">
          Caesar Cipher
        </h1>
      </div>
      <div className="space-y-4">
        <input
          type="text"
          value={inputText}
          onChange={handleInputChange}
          placeholder="Enter text"
          className={`w-full p-2 rounded bg-secondary-light dark:bg-secondary-dark text-text-light dark:text-text-dark`}
        />
        <div className="flex items-center space-x-2">
          <span className="w-8 text-sm">Shift:</span>
          <Slider
            value={shift}
            onChange={(e, newValue) => setShift(newValue)}
            min={0}
            max={25}
            step={1}
            valueLabelDisplay="auto"
            size="small"
            className="flex-grow"
          />
        </div>
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Switch
              checked={isDecryptMode}
              onChange={() => setIsDecryptMode(!isDecryptMode)}
              size="small"
            />
            <span className="text-sm">Decrypt</span>
          </div>
          <button
            onClick={handleReset}
            className={`px-3 py-1 text-sm rounded bg-primary-light dark:bg-primary-dark text-text-dark dark:text-white hover:bg-primary-light/80 dark:hover:bg-primary-dark/80`}
          >
            Reset
          </button>
        </div>
      </div>
      <div className="flex justify-center my-4">
        <svg
          ref={svgRef}
          className="w-full h-auto"
          viewBox="0 0 250 250"
          preserveAspectRatio="xMidYMid meet"
        ></svg>
      </div>
      <div
        className={`mt-4 p-3 rounded bg-secondary-light dark:bg-secondary-dark`}
      >
        <p className="font-mono text-sm break-all">{outputText || "Output"}</p>
      </div>
    </div>
  );
};

export default CaesarCipher;
