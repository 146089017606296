import React from 'react';

const MorseCodeChart = () => {
  const morseCodeMap = {
    'A': '.-', 'B': '-...', 'C': '-.-.', 'D': '-..', 'E': '.', 'F': '..-.',
    'G': '--.', 'H': '....', 'I': '..', 'J': '.---', 'K': '-.-', 'L': '.-..',
    'M': '--', 'N': '-.', 'O': '---', 'P': '.--.', 'Q': '--.-', 'R': '.-.',
    'S': '...', 'T': '-', 'U': '..-', 'V': '...-', 'W': '.--', 'X': '-..-',
    'Y': '-.--', 'Z': '--..', '0': '-----', '1': '.----', '2': '..---',
    '3': '...--', '4': '....-', '5': '.....', '6': '-....', '7': '--...',
    '8': '---..', '9': '----.'
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 gap-4">
        {Object.entries(morseCodeMap).map(([letter, code]) => (
          <div key={letter} className="flex flex-col items-center p-2 border rounded">
            <span className="text-2xl font-bold">{letter}</span>
            <span className="text-lg font-mono">{code}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MorseCodeChart;