// src/pages/PlayfairCipherPage.js
import React, { useState } from 'react';
import { Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PlayfairCipher from '../../components/ciphers/PlayfairCipherApp.js';
import PlayfairGrid from '../../components/ciphers/PlayfairGrid.js';

const PlayfairCipherPage = () => {
  const [expandedSection, setExpandedSection] = useState('generalInfo');

  const handleToggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <div className="min-h-screen flex flex-col bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <div className="flex flex-1 flex-col md:flex-row">
        <div className="md:w-2/3 p-6">
        <h1 className="text-3xl font-bold mb-6">
          Playfair Cipher: Pairing Letters for Powerful Encryption
          </h1>
          <div className="space-y-4">
            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('generalInfo')}>
                <h2 className="text-xl font-semibold">General Information</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'generalInfo' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'generalInfo'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Playfair cipher is a manual symmetric encryption technique and was the first literal digraph substitution cipher. It employs a 5x5 grid of letters constructed using a keyword [1].
                  </p>
                  <p className="mb-4">
                    Unlike simple substitution ciphers that operate on single letters, the Playfair cipher encrypts pairs of letters (digraphs), which makes it significantly harder to break using frequency analysis [2].
                  </p>
                  <p>
                    The Playfair cipher was considered so strong that it was used by the British Army in the Boer War and World War I, and by the U.S. Army and other Allied forces during World War II [3].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('history')}>
                <h2 className="text-xl font-semibold">History</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'history' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'history'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Playfair cipher was invented by Charles Wheatstone in 1854, but it bears the name of Lord Playfair who promoted the use of the cipher. Wheatstone presented the cipher to Lord Playfair, who in turn promoted its use in politics and the military [1].
                  </p>
                  <p className="mb-4">
                    It was used for tactical purposes by British forces in the Second Boer War and in World War I. The Australian Army also used the Playfair cipher as its standard field cipher in World War II [3].
                  </p>
                  <p>
                    The Playfair is no longer used by military forces because of the advent of digital encryption devices. However, it remains an important historical cipher and is still used as a pedagogical tool in cryptography education [4].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('usage')}>
                <h2 className="text-xl font-semibold">Usage and Characteristics</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'usage' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'usage'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Playfair cipher operates on pairs of letters (digraphs), making it significantly more secure than simple substitution ciphers. Here's how it works:
                  </p>
                  <h3 className="font-semibold mb-2">Key Table Creation:</h3>
                  <ol className="list-decimal pl-5 mb-4">
                    <li>Choose a keyword (e.g., "SECRET").</li>
                    <li>Create a 5x5 grid.</li>
                    <li>Fill in the keyword (removing duplicates) from left to right, top to bottom.</li>
                    <li>Fill the remaining cells with the unused letters of the alphabet (usually combining I and J).</li>
                  </ol>
                  <h3 className="font-semibold mb-2">Encryption Rules:</h3>
                  <ol className="list-decimal pl-5 mb-4">
                    <li>Divide the plaintext into digraphs (pairs of letters). If there's an odd number of letters, append an 'X'. An example could be the plaintext "ACE", which would be converted to "AC EX". This covers the addition of the "X" after the "E" to complete the final pair.</li>
                    <li>For each digraph, locate the two letters in the key table.</li>
                    <li>Apply the following rules:
                      <ul className="list-disc pl-5 mt-2">
                        <li>If the letters are in different rows and columns, replace them with the letters on the same row but at the other pair of corners of the rectangle defined by the original pair.</li>
                        <li>If the letters are in the same row, replace them with the letters to their immediate right (wrapping around to the left side of the row if necessary).</li>
                        <li>If the letters are in the same column, replace them with the letters immediately below (wrapping around to the top of the column if necessary).</li>
                        <li>If the letters are the same, separate them with an 'X' and encrypt the new pairs. An example could be the plaintext "CALL", which would be converted to "CA LX". This covers the duplicate letters for "LL".</li>
                      </ul>
                    </li>
                  </ol>
                  <h3 className="font-semibold mb-2">Example:</h3>
                  <p className="mb-4">
                    Using the key "SECRET" and plaintext "ROUTER":
                    <br />
                    1. Divide plaintext: RO UT ER
                    <br />
                    2. Encrypt: 
                    <br />
                    RO → EQ (rectangle rule)
                    <br />
                    UT → ZG (column rule)
                    <br />
                    ER → CT (row rule)
                    <br />
                    3. Ciphertext: EQ ZG CT
                  </p>
                  <p className="mb-4">
                    Decryption follows the same process but reverses the direction of the rules (left instead of right, up instead of down).
                  </p>
                  <p>
                    While more secure than simple substitution ciphers, the Playfair cipher is still vulnerable to frequency analysis of digraphs and known-plaintext attacks. However, its historical significance and the insights it provides into the evolution of cryptography make it an important subject of study.
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('grid')}>
                <h2 className="text-xl font-semibold">Playfair Grid</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'grid' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'grid'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Playfair grid, or key square, is a 5x5 grid of letters that serves as the key for both encryption and decryption. It's typically constructed as follows:
                  </p>
                  <ol className="list-decimal pl-5 mb-4">
                    <li>Choose a keyword and remove duplicate letters.</li>
                    <li>Fill the grid with the keyword letters first.</li>
                    <li>Fill the remaining spaces with the unused letters of the alphabet.</li>
                    <li>Traditionally, I and J are combined in one cell to fit the 26-letter alphabet into a 5x5 grid.</li>
                  </ol>
                  <PlayfairGrid />
                  <p className="mt-4">
                    This grid is then used to find letter pairs and apply the Playfair cipher rules for encryption and decryption [2].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('references')}>
                <h2 className="text-xl font-semibold">References</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'references' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'references'}>
                <div className="mt-2">
                  <ol className="list-decimal pl-5">
                    <li>
                      Singh, S. (1999). The Code Book: The Science of Secrecy from Ancient Egypt to Quantum Cryptography. Anchor Books. pp. 120-126.
                    </li>
                    <li>
                      Lyons, J. (2012). "Practical Cryptography: Playfair Cipher." Practical Cryptography. <a href="http://practicalcryptography.com/ciphers/playfair-cipher/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">http://practicalcryptography.com/ciphers/playfair-cipher/</a>
                    </li>
                    <li>
                      Kahn, D. (1996). The Codebreakers: The Comprehensive History of Secret Communication from Ancient Times to the Internet. Scribner. pp. 198-202.
                    </li>
                    <li>
                      Stamp, M. & Low, R. M. (2007). Applied Cryptanalysis: Breaking Ciphers in the Real World. Wiley-Interscience. pp. 40-48.
                    </li>
                  </ol>
                </div>
              </Collapse>
            </div>
          </div>
        </div>

        <div className="md:w-1/3 p-6 bg-secondary-light dark:bg-secondary-dark shadow-lg">
          <PlayfairCipher />
        </div>
      </div>
    </div>
  );
};

export default PlayfairCipherPage;

