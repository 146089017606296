import React, { useContext } from 'react';
import { ThemeContext } from '../utils/themeContext';

const WhatIsCipher = () => {
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark';

  return (
    <div className={`max-w-3xl mx-auto p-6 ${isDarkMode ? 'bg-dark text-text-dark' : 'bg-secondary-light text-text-light'}`}>
      <header className="border-b-2 border-primary-light dark:border-primary-dark pb-4 mb-6">
        <h1 className={`text-4xl font-bold ${isDarkMode ? 'text-primary-dark' : 'text-primary-light'}`}>
          What are Ciphers?
        </h1>
      </header>

      <section className="mb-8">
        <p className="mb-4">
          Ciphers are at the heart of <span className="font-bold text-primary-light dark:text-primary-dark">cryptography</span>, the art and science of secure communication. A cipher is a method of transforming a message into an unreadable form, ensuring that only those with the proper key can decipher and understand it. Unlike codes, which replace words or phrases with predetermined symbols, ciphers work at the level of individual letters or small groups of letters. This distinction allows ciphers to be more flexible and adaptable to various types of messages.
        </p>
        <p className="mb-4">
          The basic principle behind a cipher is <span className="font-bold text-primary-light dark:text-primary-dark">substitution or transposition</span> of letters. In substitution ciphers, each letter of the original message (known as plaintext) is replaced by another letter or symbol. A simple example is the Caesar cipher, where each letter is shifted a fixed number of positions in the alphabet. Transposition ciphers, on the other hand, rearrange the order of letters in the message without changing the letters themselves. More complex ciphers may combine both substitution and transposition methods.
        </p>
        <p className="mb-4">
          As technology has advanced, so too have ciphers. While early ciphers were implemented by hand, the 20th century saw the rise of mechanical cipher machines like the famous <span className="font-bold text-primary-light dark:text-primary-dark">Enigma</span>, used by Germany in World War II. Today, ciphers are primarily implemented through computer algorithms, capable of encrypting vast amounts of data quickly and securely. Modern ciphers use complex mathematical functions and often involve public key cryptography, where different keys are used for encryption and decryption.
        </p>
        <p className="mb-4">
          The importance of ciphers in our daily lives cannot be overstated. They protect our online communications, secure financial transactions, safeguard sensitive data, and play a crucial role in national security. From the simple substitution ciphers of antiquity to the sophisticated algorithms of the digital age, ciphers continue to evolve, constantly adapting to new challenges and threats in the ongoing battle to keep information secure.
        </p>
      </section>

      <h2 className={`text-3xl font-semibold mb-4 ${isDarkMode ? 'text-primary-dark' : 'text-primary-light'}`}>
        Codes and Ciphers: The Art of Secret Communication
      </h2>
      <section>
        <p className="mb-4">
          Codes and ciphers have been integral to secure communication for millennia, playing crucial roles in warfare, diplomacy, and personal privacy. While often used interchangeably, codes and ciphers are distinct cryptographic methods, each with its own characteristics and applications.
        </p>
        <p className="mb-4">
          <span className="font-bold text-primary-light dark:text-primary-dark">Codes</span> are systems of communication where words, phrases, or sentences are replaced by pre-arranged symbols, numbers, or other words. For example, a commercial code might replace common business phrases with shorter code words to save on telegram costs. Codes can be as simple as a list of substitutions or as complex as entire codebooks. The strength of a code lies in its secrecy; once the code is known, messages can be easily deciphered.
        </p>
        <p className="mb-4">
          <span className="font-bold text-primary-light dark:text-primary-dark">Ciphers</span>, on the other hand, operate at the level of individual letters, numbers, or bits. They use a specific algorithm to transform the original message (plaintext) into an unreadable form (ciphertext). Ciphers can be broadly categorized into substitution ciphers, where each unit of the plaintext is replaced by another, and transposition ciphers, where the units are rearranged. Unlike codes, many modern ciphers rely on publicly known algorithms but use secret keys for security.
        </p>
        <p className="mb-4">
          The history of codes and ciphers is rich with innovation and intrigue. Ancient civilizations used simple substitution ciphers, while the Renaissance saw the development of more complex polyalphabetic ciphers. The 20th century brought mechanical encryption devices like the Enigma machine, and the digital age has ushered in sophisticated computer-based encryption algorithms.
        </p>
        <p className="mb-4">
          Today, while codes are still used in some specialized applications, ciphers dominate modern cryptography. Advanced ciphers protect our digital communications, secure financial transactions, and safeguard sensitive data. As technology evolves, so too do the methods of creating and breaking codes and ciphers, continuing the age-old dance between codemakers and codebreakers in the realm of secret communication.
        </p>
      </section>
    </div>
  );
};

export default WhatIsCipher;