// src/components/EducationBanner.js
import React from 'react';

const EducationBanner = () => {
  return (
    <div className="relative py-16 bg-primary-light dark:bg-primary-dark mt-16">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            Empowering Minds Through Cipher Education
          </h2>
          <p className="mt-4 text-xl text-secondary-light dark:text-secondary-dark">
            Discover the fascinating world of cryptography and enhance your problem-solving skills.
          </p>
        </div>
      </div>
    </div>
  );
};

export default EducationBanner;