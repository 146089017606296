import React, { useState } from 'react';
import { Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RailFenceCipher from '../../components/ciphers/RailFenceCipherApp';
import RailFenceVisual from '../../components/ciphers/RailFenceVisual';

const RailFenceCipherPage = () => {
  const [expandedSection, setExpandedSection] = useState('generalInfo');

  const handleToggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <div className="min-h-screen flex flex-col bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <div className="flex flex-1 flex-col md:flex-row">
        <div className="md:w-2/3 p-6">
        <h1 className="text-3xl font-bold mb-6">
        Rail Fence Cipher: Zigzag Your Way to Secrecy
          </h1>
          <div className="space-y-4">
            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('generalInfo')}>
                <h2 className="text-xl font-semibold">General Information</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'generalInfo' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'generalInfo'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Rail Fence Cipher, also known as the Zigzag Cipher, is a simple transposition cipher that writes the message in a zigzag pattern across a number of "rails" or rows, and then reads off the cipher row by row [1].
                  </p>
                  <p className="mb-4">
                    It's a type of route transposition cipher, where the order of the letters is changed (transposed) according to a fixed route or pattern. The Rail Fence Cipher is one of the simplest forms of this type of encryption [2].
                  </p>
                  <p>
                    Despite its simplicity, the Rail Fence Cipher demonstrates key principles of transposition ciphers and serves as an excellent introduction to more complex encryption methods [3].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('history')}>
                <h2 className="text-xl font-semibold">History</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'history' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'history'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The exact origin of the Rail Fence Cipher is unclear, but it's one of the oldest and simplest ciphers. Transposition ciphers like the Rail Fence have been used in various forms throughout history due to their simplicity and ease of implementation [1].
                  </p>
                  <p className="mb-4">
                    While not secure for modern cryptographic purposes, it has been used in combination with other ciphers to add an extra layer of complexity. For example, during the American Civil War, the Union Army used a combination of route ciphers, including variations of the Rail Fence Cipher [4].
                  </p>
                  <p>
                    Today, the Rail Fence Cipher is primarily used for educational purposes, serving as an introduction to the concepts of transposition in cryptography [2].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('usage')}>
                <h2 className="text-xl font-semibold">Usage and Characteristics</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'usage' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'usage'}>
                <div className="mt-2">
                  <p className="mb-4">
                    To use the Rail Fence Cipher, you choose a number of "rails" or rows. The plaintext is written downwards and diagonally across the rails, then upwards when the bottom rail is reached. When the top rail is reached, the direction is reversed again [3].
                  </p>
                  <p className="mb-4">
                    The ciphertext is then read off row by row. The number of rails and the offset (where you start writing on the first rail) can be used as the key for the cipher [2].
                  </p>
                  <p className="mb-4">
                    Key characteristics of the Rail Fence Cipher include:
                  </p>
                  <ul className="list-disc pl-5 mb-4">
                    <li>Transposition: It rearranges the order of letters rather than substituting them.</li>
                    <li>Simplicity: Easy to implement and explain, making it useful for teaching.</li>
                    <li>Variable key: The number of rails can be changed to create different ciphertexts.</li>
                    <li>Preservation of letter frequency: The frequency of letters in the plaintext is maintained in the ciphertext.</li>
                  </ul>
                  <p>
                    While simple to implement, the Rail Fence Cipher is not secure against modern cryptanalysis techniques. Its primary value lies in its educational role and historical significance in the development of more complex transposition ciphers [1].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('visual')}>
                <h2 className="text-xl font-semibold">Rail Fence Visual</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'visual' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'visual'}>
                <div className="mt-2">
                  <p className="mb-4">
                    Below is a visual representation of how the Rail Fence Cipher works. This diagram shows how the plaintext is written in a zigzag pattern across the rails, and then read off row by row to produce the ciphertext [3].
                  </p>
                  <RailFenceVisual />
                  <p className="mt-4">
                    This visual representation helps to understand the transposition process and how the number of rails affects the resulting ciphertext.
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('references')}>
                <h2 className="text-xl font-semibold">References</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'references' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'references'}>
                <div className="mt-2">
                  <ol className="list-decimal pl-5">
                    <li>
                      Singh, S. (1999). The Code Book: The Science of Secrecy from Ancient Egypt to Quantum Cryptography. Anchor Books. pp. 8-9.
                    </li>
                    <li>
                      Lyons, J. (2012). "Practical Cryptography: Rail Fence Cipher." Practical Cryptography. <a href="http://practicalcryptography.com/ciphers/rail-fence-cipher/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">http://practicalcryptography.com/ciphers/rail-fence-cipher/</a>
                    </li>
                    <li>
                      Stamp, M. & Low, R. M. (2007). Applied Cryptanalysis: Breaking Ciphers in the Real World. Wiley-Interscience. pp. 38-39.
                    </li>
                    <li>
                      Kahn, D. (1996). The Codebreakers: The Comprehensive History of Secret Communication from Ancient Times to the Internet. Scribner. pp. 214-215.
                    </li>
                  </ol>
                </div>
              </Collapse>
            </div>
          </div>
        </div>

        <div className="md:w-1/3 p-6 bg-secondary-light dark:bg-secondary-dark shadow-lg">
          <RailFenceCipher />
        </div>
      </div>
    </div>
  );
};

export default RailFenceCipherPage;