import React, { useState } from "react";
import { Collapse, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import BifidCipher from "../../components/ciphers/BifidCipherApp";
import BifidGrid from "../../components/ciphers/BifidGrid";

const BifidCipherPage = () => {
  const [expandedSection, setExpandedSection] = useState("generalInfo");

  const handleToggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <div className="min-h-screen flex flex-col bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <div className="flex flex-1 flex-col md:flex-row">
        <div className="md:w-2/3 p-6">
          <h1 className="text-3xl font-bold mb-6">
            Bifid Cipher: Double the Complexity, Double the Security
          </h1>
          <div className="space-y-4">
            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("generalInfo")}
              >
                <h2 className="text-xl font-semibold">General Information</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "generalInfo" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "generalInfo"}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Bifid cipher is a cipher that combines the Polybius
                    square with transposition, adding significant complexity to
                    the encryption process. It was invented by Felix Delastelle
                    and first published in 1901 [1].
                  </p>
                  <p className="mb-4">
                    This cipher uses a 5x5 grid (similar to the Polybius square)
                    filled with the alphabet, usually omitting 'J' or combining
                    'I' and 'J'. Each letter is then represented by its row and
                    column numbers [1].
                  </p>
                  <p>
                    The Bifid cipher is part of a family of polygraphic
                    substitution ciphers invented by Delastelle, which also
                    includes the trifid cipher and the four-square cipher. These
                    ciphers were significant in the development of cryptography
                    as they introduced the concept of fractionation [2].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("history")}
              >
                <h2 className="text-xl font-semibold">History</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "history" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "history"}>
                <div className="mt-2">
                  <p className="mb-4">
                    Felix Delastelle, a French cryptographer, invented the Bifid
                    cipher in the late 19th century. It was first published in
                    the journal "Sachet" in 1901 [1].
                  </p>
                  <p className="mb-4">
                    Delastelle was not a professional cryptographer but an
                    enthusiast who made significant contributions to the field.
                    His work on polygraphic substitution ciphers was ahead of
                    its time and influenced later developments in cryptography
                    [2].
                  </p>
                  <p>
                    While the Bifid cipher was never widely used for official or
                    military communications, it represented an important step in
                    the evolution of encryption techniques. Its combination of
                    substitution and transposition made it significantly more
                    secure than many of its contemporaries [3].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("process")}
              >
                <h2 className="text-xl font-semibold">Encryption Process</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "process" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "process"}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Bifid cipher encryption process involves several steps :
                  </p>
                  <ol className="list-decimal pl-5 mb-4">
                    <li className="mb-2">
                      Convert each letter to its row and column numbers using
                      the Polybius square.
                    </li>
                    <li className="mb-2">
                      Write all the row numbers, then all the column numbers.
                    </li>
                    <li className="mb-2">
                      Read off pairs of numbers and convert back to letters
                      using the grid.
                    </li>
                  </ol>
                  <p className="mb-4">
                    This process effectively mixes up the original message,
                    making it much harder to decrypt without knowing the exact
                    method and key.
                  </p>
                  <p>
                    The use of both substitution (via the Polybius square) and
                    transposition (in the rearrangement of numbers) is what
                    gives the Bifid cipher its strength. This combination
                    disrupts the normal frequency distribution of letters,
                    making traditional cryptanalysis techniques less effective
                    [3].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("security")}
              >
                <h2 className="text-xl font-semibold">Security and Usage</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "security" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "security"}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Bifid cipher is significantly more secure than simple
                    substitution ciphers. Its combination of substitution and
                    transposition makes it resistant to frequency analysis [2].
                  </p>
                  <p className="mb-4">
                    Key features of the Bifid cipher's security include:
                  </p>
                  <ul className="list-disc pl-5 mb-4">
                    <li>
                      Fractionation: Each letter is split into two parts before
                      recombining, which thoroughly mixes the message.
                    </li>
                    <li>
                      Polyalphabetic nature: The same letter can be encoded
                      differently depending on its position in the message.
                    </li>
                    <li>
                      Large key space: The 5x5 grid allows for numerous possible
                      keys.
                    </li>
                  </ul>
                  <p>
                    While not secure enough for modern cryptographic needs, the
                    Bifid cipher represents an important step in the evolution
                    of cryptography. It's still used in some puzzle games and as
                    an educational tool for introducing more complex
                    cryptographic concepts [3].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("grid")}
              >
                <h2 className="text-xl font-semibold">Bifid Cipher Grid</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "grid" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "grid"}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Bifid cipher uses a 5x5 grid, similar to the Polybius
                    square. This grid forms the basis of both the encryption and
                    decryption processes.
                  </p>
                  <BifidGrid />
                  <p className="mt-4">
                    In this grid, each letter is assigned a unique pair of
                    coordinates. These coordinates are then used in the
                    encryption process to thoroughly mix up the message.
                  </p>
                  <p className="mt-4">
                    To decode the message, you will need to use the keyword to
                    construct the grid. Then, use all the encrypted coordinates,
                    where the first half represents the rows and the second half
                    represents the columns.
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("references")}
              >
                <h2 className="text-xl font-semibold">References</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "references" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "references"}>
                <div className="mt-2">
                  <ol className="list-decimal pl-5">
                    <li>
                      Kahn, D. (1996). The Codebreakers: The Comprehensive
                      History of Secret Communication from Ancient Times to the
                      Internet. Scribner. pp. 275-276.
                    </li>

                    <li>
                      Bauer, C. P. (2013). Secret History: The Story of
                      Cryptology. CRC Press. pp. 131-133.
                    </li>
                    <li>
                      Stamp, M. & Low, R. M. (2007). Applied Cryptanalysis:
                      Breaking Ciphers in the Real World. Wiley-Interscience.
                      pp. 88-92.
                    </li>
                  </ol>
                </div>
              </Collapse>
            </div>
          </div>
        </div>

        <div className="md:w-1/3 p-6 bg-secondary-light dark:bg-secondary-dark shadow-lg">
          <BifidCipher />
        </div>
      </div>
    </div>
  );
};

export default BifidCipherPage;
