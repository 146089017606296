import React, { useContext } from 'react';
import { ThemeContext } from '../utils/themeContext';

const About = () => {
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark';

  return (
    <div className={`max-w-3xl mx-auto p-6 ${isDarkMode ? 'bg-dark text-text-dark' : 'bg-secondary-light text-text-light'}`}>
      <header className="border-b-2 border-primary-light dark:border-primary-dark pb-4 mb-6">
        <h1 className={`text-4xl font-bold ${isDarkMode ? 'text-primary-dark' : 'text-primary-light'}`}>
          About LearnCipher.org
        </h1>
      </header>

      <section className="mb-8">
        <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-primary-dark' : 'text-primary-light'}`}>
          What is LearnCipher.org?
        </h2>
        <p className="mb-4">
          LearnCipher.org is a free educational resource dedicated to the fascinating world of ciphers and cryptography. Our goal is to provide a comprehensive platform for anyone interested in learning about the art and science of secret communication.
        </p>
        <p className="mb-4">
          Here, you'll find a wealth of information on various cipher types, from ancient methods to modern cryptographic systems. We offer detailed explanations, historical context, and practical examples to help you understand how ciphers work and how they've evolved over time.
        </p>
      </section>

      <section className="mb-8">
        <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-primary-dark' : 'text-primary-light'}`}>
          Interactive Learning
        </h2>
        <p className="mb-4">
          One of the unique features of LearnCipher.org is our collection of interactive tools. These allow you to experiment with different ciphers hands-on, encrypting and decrypting messages in real-time. By engaging with these practical components, you can reinforce your understanding of cryptographic principles and see how these methods work in practice.
        </p>
        <p className="mb-4">
          Whether you're a student, a history enthusiast, or someone with a general interest in codes and puzzles, our interactive features provide an engaging way to explore the world of ciphers.
        </p>
      </section>

      <section>
        <h2 className={`text-2xl font-semibold mb-4 ${isDarkMode ? 'text-primary-dark' : 'text-primary-light'}`}>
          About the Creator
        </h2>
        <p className="mb-4">
          LearnCipher.org was created by a self-taught full stack developer with a background in blockchain infrastructure and software engineering. The site was born out of a deep fascination with ciphers, cryptography, and problem-solving.
        </p>
        <p className="mb-4">
          As the creator, I've always been intrigued by the minds of great code breakers and problem solvers throughout history. I often find myself wondering about their thought processes, the moments of insight they must have experienced, and the persistence they showed when faced with seemingly unsolvable puzzles.
        </p>
        <p className="mb-4">
          This curiosity drives me to delve deep into the mindset of cryptographers and codebreakers. I believe that by understanding how these brilliant individuals approached problems, we can gain valuable insights applicable not just to cryptography, but to problem-solving in general. It's fascinating to consider how they might have tackled the challenges of their time with the tools available to them, and how their innovative thinking laid the groundwork for modern cryptography.
        </p>
        <p className="mb-4">
          Through LearnCipher.org, I aim to share this passion and inspire others to think deeply about the art and science of secret communication. Whether you're here to learn about historical ciphers, understand the basics of modern encryption, or simply challenge yourself with some cryptographic puzzles, I hope you'll find LearnCipher.org both educational and engaging.
        </p>
      </section>
    </div>
  );
};

export default About;