// src/components/BaconCipherApp.js
import React, { useState } from 'react';

const BaconCipher = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [mode, setMode] = useState('encode'); // 'encode' or 'test'
  const [testQuestion, setTestQuestion] = useState('');
  const [testAnswer, setTestAnswer] = useState('');
  const [feedback, setFeedback] = useState('');

  const baconAlphabet = {
    'A': 'AAAAA', 'B': 'AAAAB', 'C': 'AAABA', 'D': 'AAABB', 'E': 'AABAA',
    'F': 'AABAB', 'G': 'AABBA', 'H': 'AABBB', 'I': 'ABAAA', 'J': 'ABAAB',
    'K': 'ABABA', 'L': 'ABABB', 'M': 'ABBAA', 'N': 'ABBAB', 'O': 'ABBBA',
    'P': 'ABBBB', 'Q': 'BAAAA', 'R': 'BAAAB', 'S': 'BAABA', 'T': 'BAABB',
    'U': 'BABAA', 'V': 'BABAB', 'W': 'BABBA', 'X': 'BABBB', 'Y': 'BBAAA',
    'Z': 'BBAAB'
  };

  const reverseBaconAlphabet = Object.fromEntries(
    Object.entries(baconAlphabet).map(([key, value]) => [value, key])
  );

  const handleInputChange = (e) => {
    setInput(e.target.value);
    if (mode === 'encode') {
      processInput(e.target.value);
    }
  };

  const processInput = (text) => {
    const processedText = text.toUpperCase().split('').map(char => 
      baconAlphabet[char] || char
    ).join(' ');
    setOutput(processedText);
  };

  const handleAppModeChange = (newMode) => {
    setMode(newMode);
    setInput('');
    setOutput('');
    setTestAnswer('');
    setFeedback('');
    if (newMode === 'test') {
      generateTestQuestion();
    }
  };

  const handleTestButtonClick = () => {
    if (mode === 'test') {
      generateTestQuestion();
    } else {
      handleAppModeChange('test');
    }
  };

  const generateTestQuestion = () => {
    const words = ['BACON', 'CIPHER', 'SECRET', 'MESSAGE', 'STEGANOGRAPHY'];
    const randomWord = words[Math.floor(Math.random() * words.length)];
    const encryptedWord = randomWord.split('').map(char => baconAlphabet[char]).join(' ');
    setTestQuestion(encryptedWord);
    setTestAnswer('');
    setFeedback('');
  };

  const checkTestAnswer = () => {
    const correctAnswer = testQuestion.split(' ').map(group => 
      reverseBaconAlphabet[group] || group
    ).join('');
    if (testAnswer.toUpperCase() === correctAnswer) {
      setFeedback('Correct! Well done!');
    } else {
      setFeedback('Incorrect. Try again!');
    }
  };

  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-4">Bacon's Cipher</h2>
      
      <div className="mb-4">
        <button 
          onClick={() => handleAppModeChange('encode')} 
          className={`mr-2 px-4 py-2 rounded ${mode === 'encode' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Encode
        </button>
        <button 
          onClick={handleTestButtonClick} 
          className={`px-4 py-2 rounded ${mode === 'test' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Test Skills
        </button>
      </div>

      {mode === 'encode' && (
        <>
          <div className="mb-4">
            <textarea
              value={input}
              onChange={handleInputChange}
              placeholder="Enter text to encrypt"
              className="w-full p-2 border rounded"
              rows="4"
            />
          </div>
          <div className="mb-4">
            <h3 className="font-bold mb-2">Encrypted Text:</h3>
            <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded break-all">
              {output}
            </div>
          </div>
        </>
      )}

      {mode === 'test' && (
        <>
          <div className="mb-4">
            <h3 className="font-bold mb-2">Decrypt this code:</h3>
            <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded break-all">
              {testQuestion}
            </div>
          </div>
          <div className="mb-4">
            <input
              type="text"
              value={testAnswer}
              onChange={(e) => setTestAnswer(e.target.value)}
              placeholder="Enter decrypted text"
              className="w-full p-2 border rounded"
            />
          </div>
          <button 
            onClick={checkTestAnswer}
            className="px-4 py-2 bg-green-500 text-white rounded"
          >
            Check Answer
          </button>
          {feedback && (
            <div className="mt-4 p-2 bg-gray-100 dark:bg-gray-700 rounded">
              {feedback}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BaconCipher;