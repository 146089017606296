import React, { useState } from 'react';
import { BigInteger } from 'jsbn';

const RSAEncryptionApp = () => {
  const [p, setP] = useState('');
  const [q, setQ] = useState('');
  const [e, setE] = useState('');
  const [d, setD] = useState('');
  const [n, setN] = useState('');
  const [message, setMessage] = useState('');
  const [encryptedMessage, setEncryptedMessage] = useState('');
  const [decryptedMessage, setDecryptedMessage] = useState('');
  const [mode, setMode] = useState('encrypt');

  const generateRandomBigInteger = (bitLength) => {
    const bytes = Math.ceil(bitLength / 8);
    const randomBytes = window.crypto.getRandomValues(new Uint8Array(bytes));
    let hex = Array.from(randomBytes, (byte) => byte.toString(16).padStart(2, '0')).join('');
    return new BigInteger(hex, 16);
  };

  const isProbablyPrime = (n, k = 10) => {
    if (n.compareTo(BigInteger.ONE) <= 0) return false;
    if (n.equals(BigInteger.valueOf(2))) return true;

    const nMinusOne = n.subtract(BigInteger.ONE);

    for (let i = 0; i < k; i++) {
        console.log(k)
      let a;
      do {
        a = generateRandomBigInteger(n.bitLength());
      } while (a.compareTo(BigInteger.ONE) <= 0 || a.compareTo(nMinusOne) >= 0);

      if (a.modPow(nMinusOne, n).compareTo(BigInteger.ONE) !== 0) {
        return false;
      }
    }
    return true;
  };

  const generatePrime = () => {
    let candidate;
    do {
      candidate = generateRandomBigInteger(512);
      candidate = candidate.or(BigInteger.ONE); // Ensure the number is odd
    } while (!isProbablyPrime(candidate));
    return candidate.toString();
  };

  const generatePublicExponent = () => {
    const commonExponents = ['3', '5', '17', '257', '65537'];
    return commonExponents[Math.floor(Math.random() * commonExponents.length)];
  };

  const generateP = () => {
    setP(generatePrime());
  };

  const generateQ = () => {
    setQ(generatePrime());
  };

  const generateE = () => {
    setE(generatePublicExponent());
  };

  const generateKeys = () => {
    const bigP = new BigInteger(p);
    const bigQ = new BigInteger(q);
    const bigN = bigP.multiply(bigQ);
    const bigPhi = bigP.subtract(BigInteger.ONE).multiply(bigQ.subtract(BigInteger.ONE));
    const bigE = new BigInteger(e);
    const bigD = bigE.modInverse(bigPhi);

    setN(bigN.toString());
    setD(bigD.toString());
  };

  const encrypt = () => {
    const bigMessage = new BigInteger(message.split('').map(char => char.charCodeAt(0).toString(16).padStart(2, '0')).join(''), 16);
    const bigN = new BigInteger(n);
    const bigE = new BigInteger(e);
    const encrypted = bigMessage.modPow(bigE, bigN);
    setEncryptedMessage(encrypted.toString(16));
  };

  const decrypt = () => {
    const bigEncrypted = new BigInteger(encryptedMessage, 16);
    const bigN = new BigInteger(n);
    const bigD = new BigInteger(d);
    const decrypted = bigEncrypted.modPow(bigD, bigN);
    const decryptedString = hexToString(decrypted.toString(16));
    setDecryptedMessage(decryptedString);
  };

  const hexToString = (hex) => {
    let str = '';
    for (let i = 0; i < hex.length; i += 2) {
      str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    }
    return str;
  };

  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-4">RSA Encryption/Decryption</h2>
      
      <div className="mb-4">
        <button 
          onClick={() => setMode('encrypt')} 
          className={`mr-2 px-4 py-2 rounded ${mode === 'encrypt' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Encrypt
        </button>
        <button 
          onClick={() => setMode('decrypt')} 
          className={`px-4 py-2 rounded ${mode === 'decrypt' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Decrypt
        </button>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">Prime p:</label>
        <div className="flex">
          <input
            type="text"
            value={p}
            onChange={(e) => setP(e.target.value)}
            className="w-full p-2 border rounded mr-2"
            placeholder="Enter prime number p"
          />
          <button 
            onClick={generateP}
            className="px-4 py-2 bg-green-500 text-white rounded"
          >
            Generate p
          </button>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">Prime q:</label>
        <div className="flex">
          <input
            type="text"
            value={q}
            onChange={(e) => setQ(e.target.value)}
            className="w-full p-2 border rounded mr-2"
            placeholder="Enter prime number q"
          />
          <button 
            onClick={generateQ}
            className="px-4 py-2 bg-green-500 text-white rounded"
          >
            Generate q
          </button>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">Public exponent e:</label>
        <div className="flex">
          <input
            type="text"
            value={e}
            onChange={(e) => setE(e.target.value)}
            className="w-full p-2 border rounded mr-2"
            placeholder="Enter public exponent e"
          />
          <button 
            onClick={generateE}
            className="px-4 py-2 bg-green-500 text-white rounded"
          >
            Generate e
          </button>
        </div>
      </div>

      <button 
        onClick={generateKeys}
        className="mb-4 px-4 py-2 bg-green-500 text-white rounded"
      >
        Generate Keys
      </button>

      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">Modulus n:</label>
        <input
          type="text"
          value={n}
          readOnly
          className="w-full p-2 border rounded bg-gray-100"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">Private exponent d:</label>
        <input
          type="text"
          value={d}
          readOnly
          className="w-full p-2 border rounded bg-gray-100"
        />
      </div>

      <div className="mb-4">
        <textarea
          value={mode === 'encrypt' ? message : encryptedMessage}
          onChange={(e) => mode === 'encrypt' ? setMessage(e.target.value) : setEncryptedMessage(e.target.value)}
          placeholder={mode === 'encrypt' ? "Enter message to encrypt" : "Enter message to decrypt"}
          className="w-full p-2 border rounded"
          rows="4"
        />
      </div>

      <button 
        onClick={mode === 'encrypt' ? encrypt : decrypt}
        className="mb-4 px-4 py-2 bg-blue-500 text-white rounded"
      >
        {mode === 'encrypt' ? 'Encrypt' : 'Decrypt'}
      </button>

      <div className="mb-4">
        <h3 className="font-bold mb-2">{mode === 'encrypt' ? 'Encrypted' : 'Decrypted'} Message:</h3>
        <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded break-all">
          {mode === 'encrypt' ? encryptedMessage : decryptedMessage}
        </div>
      </div>
    </div>
  );
};

export default RSAEncryptionApp;