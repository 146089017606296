import React, { useState, useEffect } from "react";

const BifidGrid = () => {
  const [keys, setKeys] = useState(["CODE"]);
  const [grid, setGrid] = useState([]);
  const [plaintext] = useState("HELLO");
  const [currentLetterIndex, setCurrentLetterIndex] = useState(-1);
  const [coordinates, setCoordinates] = useState([]);
  const [encryptedCoordinates, setEncryptedCoordinates] = useState([]);
  const [encryptedText, setEncryptedText] = useState("");
  const [stage, setStage] = useState("encoding"); // "encoding" or "decoding"

  useEffect(() => {
    setGrid(generateGrid(keys));
  }, [keys]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentLetterIndex((prev) => {
        if (prev + 1 >= plaintext.length) {
          if (stage === "encoding") {
            setStage("decoding");
            return -1;
          } else {
            setStage("encoding");
            setCoordinates([]);
            setEncryptedCoordinates([]);
            setEncryptedText("");
            return -1;
          }
        }
        return prev + 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [plaintext.length, stage]);

  useEffect(() => {
    if (currentLetterIndex >= 0) {
      if (stage === "encoding") {
        const letter = plaintext[currentLetterIndex];
        const coord = findCoordinates(letter);
        setCoordinates((prev) => [...prev, coord]);
      } else {
        const newCoord = encryptedCoordinates[currentLetterIndex];
        const letter = findLetter(newCoord);
        setEncryptedText((prev) => prev + letter);
      }
    } else if (currentLetterIndex === -1 && stage === "decoding") {
      const rowCoords = coordinates.map(coord => coord[0]);
      const colCoords = coordinates.map(coord => coord[1]);
      const flattenedCoords = [...rowCoords, ...colCoords];
      const newCoords = [];
      for (let i = 0; i < flattenedCoords.length; i += 2) {
        newCoords.push([flattenedCoords[i], flattenedCoords[i + 1]]);
      }
      setEncryptedCoordinates(newCoords);
    }
  }, [currentLetterIndex, plaintext, stage]);

  const generateGrid = (keys) => {
    const alphabet = "ABCDEFGHIKLMNOPQRSTUVWXYZ";
    let gridChars = [];
    let usedChars = new Set();

    for (let key of keys) {
      key = key.toUpperCase().replace(/J/g, "I").replace(/[^A-Z]/g, "");
      for (let char of key) {
        if (!usedChars.has(char)) {
          gridChars.push(char);
          usedChars.add(char);
        }
      }
    }

    for (let char of alphabet) {
      if (!usedChars.has(char) && gridChars.length < 25) {
        gridChars.push(char);
      }
    }

    return gridChars;
  };

  const findCoordinates = (letter) => {
    const index = grid.indexOf(letter);
    return [Math.floor(index / 5) + 1, (index % 5) + 1];
  };

  const findLetter = (coord) => {
    const index = (coord[0] - 1) * 5 + (coord[1] - 1);
    return grid[index];
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-lg bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <h3 className="text-lg font-semibold mb-2">Bifid Cipher Grid</h3>
      <div className="mb-4">
        <p>Keywords: {keys.join(", ")}</p>
        <p>Plaintext: {plaintext}</p>
        <p>Encrypted: {encryptedText}</p>
      </div>
      <div className="relative w-72 h-72">
        <div className="grid grid-cols-6 gap-1 w-full h-full">
          <div className="w-full h-full"></div>
          {[1, 2, 3, 4, 5].map((num) => (
            <div
              key={`col-${num}`}
              className={`w-full h-full flex items-center justify-center font-bold text-gray-500 ${
                (coordinates[currentLetterIndex] && coordinates[currentLetterIndex][1] === num && stage === "encoding") ||
                (encryptedCoordinates[currentLetterIndex] && encryptedCoordinates[currentLetterIndex][1] === num && stage === "decoding")
                  ? "bg-green-200"
                  : ""
              }`}
            >
              {num}
            </div>
          ))}
          {[1, 2, 3, 4, 5].map((num) => (
            <React.Fragment key={`row-${num}`}>
              <div
                className={`w-full h-full flex items-center justify-center font-bold text-gray-500 ${
                  (coordinates[currentLetterIndex] && coordinates[currentLetterIndex][0] === num && stage === "encoding") ||
                  (encryptedCoordinates[currentLetterIndex] && encryptedCoordinates[currentLetterIndex][0] === num && stage === "decoding")
                    ? "bg-green-200"
                    : ""
                }`}
              >
                {num}
              </div>
              {grid.slice((num - 1) * 5, num * 5).map((char, index) => (
                <div
                  key={index}
                  className={`w-full h-full border flex items-center justify-center font-bold ${
                    (char === plaintext[currentLetterIndex] && stage === "encoding") ||
                    (char === encryptedText[currentLetterIndex] && stage === "decoding")
                      ? "bg-yellow-200"
                      : ""
                  }`}
                >
                  {char}
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="mt-4">
        <h4 className="font-semibold">Coordinates:</h4>
        <p>
          Row: {coordinates.map(coord => coord[0]).join(", ")}
        </p>
        <p>
          Column: {coordinates.map(coord => coord[1]).join(", ")}
        </p>
      </div>
      <div className="mt-4">
        <h4 className="font-semibold">Encrypted Coordinates:</h4>
        <p>
          {encryptedCoordinates.map((coord, index) => (
            <span key={index} className={index === currentLetterIndex && stage === "decoding" ? "bg-red-200" : ""}>
              ({coord.join(",")})
              {index < encryptedCoordinates.length - 1 ? ", " : ""}
            </span>
          ))}
        </p>
      </div>
    </div>
  );
};

export default BifidGrid;