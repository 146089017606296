import React, { useState } from 'react';
import { Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AESEncryptionApp from '../../components/ciphers/AESEncryptionApp';

const AESEncryptionPage = () => {
  const [expandedSection, setExpandedSection] = useState('generalInfo');

  const handleToggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <div className="min-h-screen flex flex-col bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <div className="flex flex-1 flex-col md:flex-row">
        <div className="md:w-2/3 p-6">
        <h1 className="text-3xl font-bold mb-6">
        AES: The Gold Standard of Symmetric Encryption 
          </h1>
          <div className="space-y-4">
            {/* General Information section */}
            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('generalInfo')}>
                <h2 className="text-xl font-semibold">General Information</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'generalInfo' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'generalInfo'}>
                <div className="mt-2">
                  <p className="mb-4">
                    AES (Advanced Encryption Standard) is a symmetric block cipher adopted as an encryption standard by the U.S. government. It is widely used worldwide to encrypt sensitive data [1].
                  </p>
                  <p>
                    AES operates on a 4x4 column-major order matrix of bytes, termed the state. Most AES calculations are performed in a special finite field [2].
                  </p>
                </div>
              </Collapse>
            </div>

            {/* History section */}
            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('history')}>
                <h2 className="text-xl font-semibold">History</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'history' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'history'}>
                <div className="mt-2">
                  <p className="mb-4">
                    AES was announced by the National Institute of Standards and Technology (NIST) in 2001 after a 5-year standardization process. It was originally developed as Rijndael by Belgian cryptographers Joan Daemen and Vincent Rijmen [1].
                  </p>
                  <p>
                    AES replaced the Data Encryption Standard (DES), which had been in use since 1977. AES was chosen for its combination of security, performance, efficiency, ease of implementation, and flexibility [3].
                  </p>
                </div>
              </Collapse>
            </div>

            {/* How It Works section */}
            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('howItWorks')}>
                <h2 className="text-xl font-semibold">How It Works</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'howItWorks' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'howItWorks'}>
                <div className="mt-2">
                  <h3 className="text-lg font-semibold mb-2">Initialization Vector (IV)</h3>
                  <p className="mb-4">
                    Before we dive into the AES process, it's important to understand the role of the Initialization Vector (IV):
                  </p>
                  <ul className="list-disc pl-6 space-y-2 mb-4">
                    <li>The IV is a fixed-size input to a cryptographic primitive that is typically required to be random or pseudorandom.</li>
                    <li>In AES, the IV is used to ensure that encrypting the same message twice doesn't result in the same ciphertext.</li>
                    <li>The IV should be unique for each encryption operation under the same key.</li>
                    <li>It's typically generated using a cryptographically secure random number generator.</li>
                    <li>The IV is usually prepended to the ciphertext and isn't considered secret - it's necessary for decryption.</li>
                  </ul>

                  <h3 className="text-lg font-semibold mb-2">AES Encryption Process</h3>
                  <p className="mb-4">
                    AES is a block cipher that processes data in fixed-size blocks (128 bits). Here's a step-by-step breakdown of the process:
                  </p>
                  <ol className="list-decimal pl-6 space-y-2 mb-4">
                    <li>
                      <p><strong>Key Expansion:</strong> The AES key is expanded into a key schedule, which is used in each round of encryption.</p>
                    </li>
                    <li>
                      <p><strong>Initial Round:</strong> AddRoundKey - each byte of the state is combined with a byte of the round key using bitwise XOR.</p>
                    </li>
                    <li>
                      <p><strong>Main Rounds:</strong> The number of rounds depends on the key size (10 for 128-bit, 12 for 192-bit, 14 for 256-bit). Each round consists of:</p>
                      <ul className="list-disc pl-6 mt-2">
                        <li>SubBytes: Non-linear substitution of each byte using a substitution table (S-box).</li>
                        <li>ShiftRows: Cyclic shifting of the rows of the state array.</li>
                        <li>MixColumns: Mixing operation which operates on the columns of the state, combining the four bytes in each column.</li>
                        <li>AddRoundKey: Each byte of the state is combined with the round key using bitwise XOR.</li>
                      </ul>
                    </li>
                    <li>
                      <p><strong>Final Round:</strong> Consists of SubBytes, ShiftRows, and AddRoundKey (note: no MixColumns in the final round).</p>
                    </li>
                  </ol>

                  <h3 className="text-lg font-semibold mb-2">Modes of Operation</h3>
                  <p className="mb-4">
                    AES is typically used with a mode of operation to encrypt messages longer than the block size. Common modes include:
                  </p>
                  <ul className="list-disc pl-6 space-y-2 mb-4">
                    <li><strong>ECB (Electronic Codebook):</strong> Simplest mode, but not recommended for most applications due to security vulnerabilities.</li>
                    <li><strong>CBC (Cipher Block Chaining):</strong> Uses an IV and chains blocks together. Each block is XORed with the previous ciphertext block before being encrypted.</li>
                    <li><strong>CTR (Counter):</strong> Turns the block cipher into a stream cipher. It generates the next keystream block by encrypting successive values of a counter.</li>
                    <li><strong>GCM (Galois/Counter Mode):</strong> Provides both encryption and authentication. It's widely used for its performance and security properties.</li>
                  </ul>

                  <p>
                    The choice of mode affects how the IV is used and how the encryption process is carried out across multiple blocks of data.
                  </p>
                  <p className="mt-4">
                  AES can be quite complex to understand all concepts. If some of these topics are hard to grasp, we encourage you to study them further to gain a deeper understanding. Feel free to check out the education and resources pages. Provided is an interactive model to test out AES encryption and its core elements.
                  </p>
                </div>
              </Collapse>
            </div>

            {/* Significance section */}
            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('significance')}>
                <h2 className="text-xl font-semibold">Significance</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'significance' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'significance'}>
                <div className="mt-2">
                  <p className="mb-4">
                    AES has several key features that contribute to its widespread use:
                  </p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Security: As of 2021, there are no known practical attacks against AES that would allow an attacker to recover the key [2].</li>
                    <li>Speed: AES is faster than many other symmetric encryption algorithms, both in software and hardware implementations [3].</li>
                    <li>Flexibility: AES can be implemented efficiently on a wide range of platforms [4].</li>
                    <li>Worldwide adoption: AES is used in numerous protocols and standards, including Wi-Fi security, mobile device encryption, and VPNs [1].</li>
                  </ul>
                  <p className="mt-4">
                    AES has been analyzed extensively and is now used worldwide. It supersedes the Data Encryption Standard (DES), which was published in 1977. The algorithm described by AES is a symmetric-key algorithm, meaning the same key is used for both encrypting and decrypting the data [4].
                  </p>
                  
                </div>
              </Collapse>
            </div>

            {/* References section */}
            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('references')}>
                <h2 className="text-xl font-semibold">References</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'references' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'references'}>
                <div className="mt-2">
                  <ol className="list-decimal pl-5">
                    <li>
                      Daemen, J., & Rijmen, V. (2002). The Design of Rijndael: AES - The Advanced Encryption Standard. Springer Science & Business Media.
                    </li>
                    <li>
                      Paar, C., & Pelzl, J. (2009). Understanding Cryptography: A Textbook for Students and Practitioners. Springer Science & Business Media.
                    </li>
                    <li>
                      Ferguson, N., Schneier, B., & Kohno, T. (2010). Cryptography Engineering: Design Principles and Practical Applications. John Wiley & Sons.
                    </li>
                    <li>
                      National Institute of Standards and Technology. (2001). Announcing the Advanced Encryption Standard (AES). Federal Information Processing Standards Publication 197.
                    </li>
                  </ol>
                </div>
              </Collapse>
            </div>
          </div>
        </div>

        <div className="md:w-1/3 p-6 bg-secondary-light dark:bg-secondary-dark shadow-lg">
          <AESEncryptionApp/>
        </div>
      </div>
    </div>
  );
};

export default AESEncryptionPage;