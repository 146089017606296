// src/components/VigenereCipherApp.js
import React, { useState } from 'react';

const VigenereCipher = () => {
  const [input, setInput] = useState('');
  const [key, setKey] = useState('');
  const [output, setOutput] = useState('');
  const [mode, setMode] = useState('encode'); // 'encode' or 'test'
  const [testQuestion, setTestQuestion] = useState('');
  const [testAnswer, setTestAnswer] = useState('');
  const [testKey, setTestKey] = useState('');
  const [feedback, setFeedback] = useState('');

  const handleInputChange = (e) => {
    setInput(e.target.value);
    if (mode === 'encode') {
      processInput(e.target.value, key);
    }
  };

  const handleKeyChange = (e) => {
    setKey(e.target.value);
    if (mode === 'encode') {
      processInput(input, e.target.value);
    }
  };

  const processInput = (text, key) => {
    if (!key) return;
    const processedText = text.toUpperCase().split('').map((char, index) => {
      if (char.match(/[A-Z]/)) {
        const shift = key.toUpperCase().charCodeAt(index % key.length) - 65;
        return String.fromCharCode((char.charCodeAt(0) - 65 + shift) % 26 + 65);
      }
      return char;
    }).join('');
    setOutput(processedText);
  };

  const handleAppModeChange = (newMode) => {
    setMode(newMode);
    setInput('');
    setKey('');
    setOutput('');
    setTestQuestion('');
    setTestAnswer('');
    setTestKey('');
    setFeedback('');
    if (newMode === 'test') {
      generateTestQuestion();
    }
  };

  const handleTestButtonClick = () => {
    if (mode === 'test') {
      generateTestQuestion();
    } else {
      handleAppModeChange('test');
    }
  };

  const generateTestQuestion = () => {
    const words = ['VIGENERE', 'CIPHER', 'POLYALPHABETIC', 'ENCRYPTION', 'YES'];
    const keys = ['KEY', 'SECRET', 'CIPHER', 'ENCODE'];
    const randomWord = words[Math.floor(Math.random() * words.length)];
    const randomKey = keys[Math.floor(Math.random() * keys.length)];
    const encryptedWord = randomWord.split('').map((char, index) => {
      const shift = randomKey.toUpperCase().charCodeAt(index % randomKey.length) - 65;
      return String.fromCharCode((char.charCodeAt(0) - 65 + shift) % 26 + 65);
    }).join('');
    setTestQuestion(encryptedWord);
    setTestKey(randomKey);
    setTestAnswer('');
    setFeedback('');
  };

  const checkTestAnswer = () => {
    const decryptedAnswer = testQuestion.split('').map((char, index) => {
      const shift = testKey.toUpperCase().charCodeAt(index % testKey.length) - 65;
      return String.fromCharCode((char.charCodeAt(0) - 65 - shift + 26) % 26 + 65);
    }).join('');
    if (testAnswer.toUpperCase() === decryptedAnswer) {
      setFeedback('Correct! Well done!');
    } else {
      setFeedback('Incorrect. Try again!');
    }
  };

  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-4">Vigenère Cipher</h2>
      
      <div className="mb-4">
        <button 
          onClick={() => handleAppModeChange('encode')} 
          className={`mr-2 px-4 py-2 rounded ${mode === 'encode' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Encode
        </button>
        <button 
          onClick={handleTestButtonClick} 
          className={`px-4 py-2 rounded ${mode === 'test' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Test Skills
        </button>
      </div>

      {mode === 'encode' && (
        <>
          <div className="mb-4">
            <input
              type="text"
              value={key}
              onChange={handleKeyChange}
              placeholder="Enter key"
              className="w-full p-2 border rounded mb-2"
            />
            <textarea
              value={input}
              onChange={handleInputChange}
              placeholder="Enter text to encrypt"
              className="w-full p-2 border rounded"
              rows="4"
            />
          </div>
          <div className="mb-4">
            <h3 className="font-bold mb-2">Encrypted Text:</h3>
            <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded break-all">
              {output}
            </div>
          </div>
        </>
      )}

      {mode === 'test' && (
        <>
          <div className="mb-4">
            <h3 className="font-bold mb-2">Decrypt this code:</h3>
            <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded break-all">
              {testQuestion}
            </div>
            <p className="mt-2">Key: {testKey}</p>
          </div>
          <div className="mb-4">
            <input
              type="text"
              value={testAnswer}
              onChange={(e) => setTestAnswer(e.target.value)}
              placeholder="Enter decrypted text"
              className="w-full p-2 border rounded"
            />
          </div>
          <button 
            onClick={checkTestAnswer}
            className="px-4 py-2 bg-green-500 text-white rounded"
          >
            Check Answer
          </button>
          {feedback && (
            <div className="mt-4 p-2 bg-gray-100 dark:bg-gray-700 rounded">
              {feedback}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default VigenereCipher;