import React, { useState, useEffect } from 'react';

const CustomSelector = ({ value, onChange, options, label }) => {
  const currentIndex = options.indexOf(value);

  const handleIncrement = () => {
    if (currentIndex < options.length - 1) {
      onChange(options[currentIndex + 1]);
    }
  };

  const handleDecrement = () => {
    if (currentIndex > 0) {
      onChange(options[currentIndex - 1]);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <label className="block text-sm font-medium mb-1">{label}:</label>
      <div className="flex items-center">
        <button
          onClick={handleDecrement}
          className="px-2 py-1 bg-gray-200 rounded-l"
          disabled={currentIndex === 0}
        >
          ▼
        </button>
        <span className="px-3 py-1 border-t border-b">{value}</span>
        <button
          onClick={handleIncrement}
          className="px-2 py-1 bg-gray-200 rounded-r"
          disabled={currentIndex === options.length - 1}
        >
          ▲
        </button>
      </div>
    </div>
  );
};

const AffineCipherGrid = () => {
  const validAValues = [1, 3, 5, 7, 9, 11, 15, 17, 19, 21, 23, 25];
  const validBValues = Array.from({ length: 26 }, (_, i) => i);

  const [a, setA] = useState(5);
  const [b, setB] = useState(8);
  const [grid, setGrid] = useState([]);

  useEffect(() => {
    generateGrid();
  }, [a, b]);

  const generateGrid = () => {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let newGrid = [];

    for (let i = 0; i < 26; i++) {
      const encryptedIndex = (a * i + b) % 26;
      newGrid.push({
        plaintext: alphabet[i],
        ciphertext: alphabet[encryptedIndex],
        plaintextIndex: i,
        ciphertextIndex: encryptedIndex
      });
    }

    setGrid(newGrid);
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-lg bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <h3 className="text-lg font-semibold mb-2">Affine Cipher Transformation</h3>
      <div className="mb-4 flex space-x-4">
        <CustomSelector
          value={a}
          onChange={setA}
          options={validAValues}
          label="a"
        />
        <CustomSelector
          value={b}
          onChange={setB}
          options={validBValues}
          label="b"
        />
      </div>
      <div className="grid grid-cols-6 gap-px text-xs">
        <div className="font-bold p-1 bg-gray-200">Plaintext</div>
        <div className="font-bold p-1 bg-gray-200">Index</div>
        <div className="font-bold p-1 bg-gray-200">Formula</div>
        <div className="font-bold p-1 bg-gray-200">Result</div>
        <div className="font-bold p-1 bg-gray-200">Mod 26</div>
        <div className="font-bold p-1 bg-gray-200">Ciphertext</div>
        {grid.map((item, index) => (
          <React.Fragment key={index}>
            <div className={`p-1 ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>{item.plaintext}</div>
            <div className={`p-1 ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>{item.plaintextIndex}</div>
            <div className={`p-1 ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>{`${a} * ${item.plaintextIndex} + ${b}`}</div>
            <div className={`p-1 ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>{a * item.plaintextIndex + b}</div>
            <div className={`p-1 ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>{item.ciphertextIndex}</div>
            <div className={`p-1 ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>{item.ciphertext}</div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default AffineCipherGrid;