import React, { useState } from "react";
import { Collapse, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CaesarCipher from "../../components/ciphers/CaesarCipherApp";
import LayeredEncryptionGraphic from "../../components/ciphers/CaesarCipherGraph";

const CaesarCipherPage = () => {
  const [expandedSection, setExpandedSection] = useState("generalInfo");

  const handleToggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <div className="min-h-screen flex flex-col bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <div className="flex flex-1 flex-col md:flex-row">
        <div className="md:w-2/3 p-6">
        <h1 className="text-3xl font-bold mb-6">
        Caesar Cipher: The Ancient Art of Shifting Letters
          </h1>
          <div className="space-y-4">
            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("generalInfo")}
              >
                <h2 className="text-xl font-semibold">General Information</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "generalInfo" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "generalInfo"}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Caesar Cipher is one of the simplest and most widely
                    known encryption techniques. It works by shifting each
                    letter in the plaintext a fixed number of positions down or
                    up the alphabet. For example, with a shift of 3, 'A' becomes
                    'D', 'B' becomes 'E', and so on. The decryption process
                    involves shifting the letters back by the same number of
                    places.
                  </p>
                  <p className="mb-4">
                    This cipher is a type of substitution cipher, specifically a
                    monoalphabetic cipher, where each letter of the plaintext is
                    mapped to another letter in the alphabet. The shift value,
                    also known as the key, determines how the letters are
                    mapped.
                  </p>
                  <p className="mb-4">
                    Despite its simplicity, the Caesar Cipher is a fundamental
                    concept in cryptography. It serves as an excellent
                    introduction to the principles of encryption and decryption,
                    paving the way for understanding more complex cryptographic
                    systems.
                  </p>
                  <p>
                    While not secure for modern applications, the Caesar Cipher
                    has inspired the development of more sophisticated
                    encryption algorithms, contributing significantly to the
                    evolution of cryptography [1].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("history")}
              >
                <h2 className="text-xl font-semibold">History</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "history" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "history"}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Caesar Cipher is named after Julius Caesar, the Roman
                    general and statesman who allegedly used it to communicate
                    secretly with his generals. Historical records, particularly
                    the writings of Suetonius in "The Twelve Caesars," suggest
                    that Caesar used a shift of three for his encrypted messages
                    [2].
                  </p>
                  <p className="mb-4">
                    Caesar employed this cipher during his military campaigns,
                    around 58-50 BC. The simplicity of the method made it easy
                    for his soldiers to use in the field, yet effective enough
                    to protect sensitive information from enemies who might
                    intercept the messages.
                  </p>
                  <p className="mb-4">
                    Interestingly, while Caesar is credited with its use in
                    military contexts, similar substitution ciphers were known
                    in other ancient civilizations. For instance, Hebrew
                    scholars used a cipher called Atbash, which reversed the
                    alphabet, as early as 500-600 BC [3].
                  </p>
                  <p>
                    Over time, the Caesar Cipher evolved. Variants like the
                    Vigenère cipher, developed in the 16th century, built upon
                    its principles to create more complex and secure encryption
                    methods.
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("useCases")}
              >
                <h2 className="text-xl font-semibold">Use Cases</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "useCases" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "useCases"}>
                <div className="mt-2">
                  <p className="mb-4">
                    While the Caesar Cipher is not secure by modern
                    cryptographic standards, it still finds applications in
                    various contexts:
                  </p>
                  <ol className="list-decimal pl-5 mb-4">
                    <li className="mb-2">
                      Educational purposes: It serves as an excellent
                      introduction to the concepts of encryption and
                      cryptography in computer science and mathematics courses.
                    </li>
                    <li className="mb-2">
                      Puzzles and games: Many puzzle books and games use Caesar
                      Cipher as a fun way to encode messages or clues.
                    </li>
                    <li className="mb-2">
                      ROT13: A variant of the Caesar Cipher with a fixed shift
                      of 13, ROT13 is sometimes used in online forums to obscure
                      spoilers or offensive content.
                    </li>
                    <li className="mb-2">
                      Steganography: In some cases, the Caesar Cipher is used as
                      part of more complex steganographic techniques to hide the
                      existence of a message.
                    </li>
                    <li>
                      Historical reenactments: Groups interested in Roman
                      history might use the Caesar Cipher for authentic
                      communication during reenactments.
                    </li>
                  </ol>
                  <p className="mb-4">
                    It's important to note that while these uses can be fun and
                    educational, the Caesar Cipher should never be used for
                    securing sensitive information in real-world applications.
                  </p>
                  <div className="mt-4">
                    <LayeredEncryptionGraphic />
                  </div>
                </div>
              </Collapse>
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("interactiveModel")}
              >
                <h2 className="text-xl font-semibold">Interactive Model</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "interactiveModel" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "interactiveModel"}>
                <div className="mt-2">
                  <p className="mb-4">
                    Our interactive Caesar Cipher model allows users to
                    experience firsthand how this ancient encryption method
                    works. By inputting text and adjusting the shift value, you
                    can see the encryption and decryption process in real-time.
                  </p>
                  <p className="mb-4">Key features of the interactive model:</p>
                  <ul className="list-disc pl-5 mb-4">
                    <li>
                      Encrypt and decrypt messages with adjustable shift values
                    </li>
                    <li>
                      Visual representation of the cipher wheel to illustrate
                      letter substitutions
                    </li>
                    <li>
                      Real-time updates as you type or change the shift value
                    </li>
                    <li>
                      Option to work with different alphabets or character sets
                    </li>
                  </ul>
                  <p className="mb-4">
                    This hands-on approach helps reinforce the understanding of
                    how substitution ciphers work and demonstrates both the
                    strengths and weaknesses of the Caesar Cipher. By
                    experimenting with different shift values and messages,
                    users can gain insights into why this method, while
                    revolutionary for its time, is considered insecure by modern
                    standards.
                  </p>
                  <p>
                    We encourage you to try various texts and shift values to
                    see how the encryption changes. Can you decrypt a message
                    without knowing the shift value? This exercise can help you
                    understand the concept of brute-force attacks in
                    cryptography.
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("references")}
              >
                <h2 className="text-xl font-semibold">References</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "references" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "references"}>
                <div className="mt-2">
                  <ol className="list-decimal pl-5">
                    <li>
                      Singh, S. (1999). The Code Book: The Science of Secrecy
                      from Ancient Egypt to Quantum Cryptography. Anchor Books.
                      pp. 8-14.
                    </li>
                    <li>
                      Suetonius. The Twelve Caesars. Penguin Classics, Revised
                      Edition (2007). "The Divine Julius," section 56.
                    </li>
                    <li>
                      Kahn, D. (1996). The Codebreakers: The Comprehensive
                      History of Secret Communication from Ancient Times to the
                      Internet. Scribner. pp. 73-79.
                    </li>
                    <li>
                      Luciano, D., & Prichett, G. (1987). Cryptology: From
                      Caesar Ciphers to Public-Key Cryptosystems. The College
                      Mathematics Journal, 18(1), 2-17.{" "}
                      <a
                        href="http://www.jstor.org/stable/2686311"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        http://www.jstor.org/stable/2686311
                      </a>
                    </li>
                  </ol>
                </div>
              </Collapse>
            </div>
          </div>
        </div>

        <div className="md:w-1/3 p-6 bg-secondary-light dark:bg-secondary-dark shadow-lg">
          <CaesarCipher />
        </div>
      </div>
    </div>
  );
};

export default CaesarCipherPage;
