// src/components/LearnCiphers.js
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LearnCiphers = ({toggleMenu}) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const timeoutRef = useRef(null);

  const cipherTypes = [
    { name: 'Morse Code', path: '/learn/morse-code' },
    { name: 'Caesar\'s Cipher', path: '/learn/caesars-cipher' },
    { name: 'Pigpen Cipher', path: '/learn/pigpen-cipher' },
    { name: 'Bacon\'s Cipher', path: '/learn/bacons-cipher' },
    { name: 'Vigenere Cipher', path: '/learn/vigenere-cipher' },
    { name: 'Playfair Cipher', path: '/learn/playfair-cipher' },
    { name: 'Rail Fence Cipher', path: '/learn/rail-fence-cipher' },
    { name: 'Bifid Cipher', path: '/learn/bifid-cipher' },
    { name: 'Affine Cipher', path: '/learn/affine-cipher' },
    { name: 'Enigma', path: '/learn/enigma' },
    { name: 'AES (Advanced Encryption Standard)', path: '/learn/aes' },
    { name: 'RSA (Rivest–Shamir–Adleman)', path: '/learn/rsa' },
  ];

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleMouseEnter = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 300); // 300ms delay before closing
  };

  return (
    <div 
      className="relative"
      ref={dropdownRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center"
      >
        Ciphers
        <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>
      {isOpen && (
        <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
          {cipherTypes.map((cipher, index) => (
            <div
              key={index}
              className="block px-4 py-2 text-gray-800 hover:bg-gray-100 cursor-pointer"
              onClick={() => {
                navigate(cipher.path);
                setIsOpen(false);
                if (toggleMenu) toggleMenu(); // Close mobile menu if it exists
              }}
            >
              {cipher.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LearnCiphers;