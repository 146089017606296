// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from './utils/themeContext';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Products from './pages/Resources.js';
import Education from './pages/Education';
import Challenge from './pages/Challenge.js';
import WhatIsCipher from './pages/WhatIsCipher.js';
import History from './pages/History.js';
import KeyWords from './pages/KeyWords.js';
import MorseCode from './pages/Learn/MorseCode';
import CaesarCipher from './pages/Learn/CaesarCipher';
import PigpenCipher from './pages/Learn/PigpenCipher';
import BaconCipher from './pages/Learn/BaconCipher.js';
import VigenereCipher from './pages/Learn/VigenereCipher.js';
import RailFenceCipher from './pages/Learn/RailFenceCipher.js';
import PlayfairCipher from './pages/Learn/PlayfairCipher.js';
import BifidCipher from './pages/Learn/BifidCipher.js';
import AffineCipher from './pages/Learn/AffineCipher.js';
import Enigma from './pages/Learn/Enigma.js';
import RSAEncryptionPage from './pages/Learn/RSA.js';
import AESEncryptionPage from './pages/Learn/AES.js';
import ScrollToTop from './pages/ScrollToTop.js';

function App() {
  return (
    <ThemeProvider>
      <Router>
        <ScrollToTop/>
        <div className="flex flex-col min-h-screen bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
          <Navbar />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/products" element={<Products />} />
              <Route path="/education" element={<Education />} />
              <Route path="/challenge" element={<Challenge />} />
              <Route path="/what-is-a-cipher" element={<WhatIsCipher />} />
              <Route path="/history" element={<History />} />
              <Route path="/key-words" element={<KeyWords />} />
              <Route path="/learn/morse-code" element={<MorseCode />} />
              <Route path="/learn/caesars-cipher" element={<CaesarCipher />} />
              <Route path="/learn/pigpen-cipher" element={<PigpenCipher />} />
              <Route path="/learn/bacons-cipher" element={<BaconCipher />} />
              <Route path="/learn/vigenere-cipher" element={<VigenereCipher />} />
              <Route path="/learn/playfair-cipher" element={<PlayfairCipher />} />
              <Route path="/learn/rail-fence-cipher" element={<RailFenceCipher />} />
              <Route path="/learn/bifid-cipher" element={<BifidCipher />} />
              <Route path="/learn/affine-cipher" element={<AffineCipher />} />
              <Route path="/learn/enigma" element={<Enigma />} />
              <Route path="/learn/rsa" element={<RSAEncryptionPage />} />
              <Route path="/learn/aes" element={<AESEncryptionPage />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
