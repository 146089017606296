// src/components/PlayfairCipherApp.js
import React, { useState } from 'react';

const PlayfairCipherApp = () => {
  const [input, setInput] = useState('');
  const [key, setKey] = useState('');
  const [output, setOutput] = useState('');
  const [decodeInput, setDecodeInput] = useState('');
  const [decodeOutput, setDecodeOutput] = useState('');
  const [mode, setMode] = useState('encode');
  const [testQuestion, setTestQuestion] = useState('');
  const [testAnswer, setTestAnswer] = useState('');
  const [testKey, setTestKey] = useState('');
  const [feedback, setFeedback] = useState('');

  const generateKeyTable = (key) => {
    let keyTable = new Array(5).fill().map(() => new Array(5));
    let keySet = new Set();
    let alphabet = 'ABCDEFGHIKLMNOPQRSTUVWXYZ';
    let i = 0, j = 0;

    key = key.toUpperCase().replace(/J/g, 'I').replace(/[^A-Z]/g, '');
    for (let char of key) {
      if (!keySet.has(char)) {
        keyTable[i][j] = char;
        keySet.add(char);
        j++;
        if (j === 5) {
          i++;
          j = 0;
        }
      }
    }

    for (let char of alphabet) {
      if (!keySet.has(char)) {
        keyTable[i][j] = char;
        j++;
        if (j === 5) {
          i++;
          j = 0;
        }
      }
    }

    return keyTable;
  };

  const findPosition = (keyTable, letter) => {
    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < 5; j++) {
        if (keyTable[i][j] === letter) {
          return [i, j];
        }
      }
    }
  };

  const encodeDigraph = (keyTable, a, b) => {
    let [a1, a2] = findPosition(keyTable, a);
    let [b1, b2] = findPosition(keyTable, b);

    if (a1 === b1) {
      return keyTable[a1][(a2 + 1) % 5] + keyTable[b1][(b2 + 1) % 5];
    } else if (a2 === b2) {
      return keyTable[(a1 + 1) % 5][a2] + keyTable[(b1 + 1) % 5][b2];
    } else {
      return keyTable[a1][b2] + keyTable[b1][a2];
    }
  };

  const decodeDigraph = (keyTable, a, b) => {
    let [a1, a2] = findPosition(keyTable, a);
    let [b1, b2] = findPosition(keyTable, b);

    if (a1 === b1) {
      return keyTable[a1][(a2 - 1 + 5) % 5] + keyTable[b1][(b2 - 1 + 5) % 5];
    } else if (a2 === b2) {
      return keyTable[(a1 - 1 + 5) % 5][a2] + keyTable[(b1 - 1 + 5) % 5][b2];
    } else {
      return keyTable[a1][b2] + keyTable[b1][a2];
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    processInput(e.target.value, key);
  };

  const handleKeyChange = (e) => {
    setKey(e.target.value);
    processInput(input, e.target.value);
    processDecodeInput(decodeInput, e.target.value);
  };

  const processInput = (text, key) => {
    if (!key) return;
    const keyTable = generateKeyTable(key);
    text = text.toUpperCase().replace(/J/g, 'I').replace(/[^A-Z]/g, '');
    let result = '';
    for (let i = 0; i < text.length; i += 2) {
      if (i === text.length - 1) {
        text += 'X';
      }
      result += encodeDigraph(keyTable, text[i], text[i + 1]);
    }
    setOutput(result);
  };

  const handleDecodeInputChange = (e) => {
    setDecodeInput(e.target.value);
    processDecodeInput(e.target.value, key);
  };

  const processDecodeInput = (text, key) => {
    if (!key) return;
    const keyTable = generateKeyTable(key);
    text = text.toUpperCase().replace(/[^A-Z]/g, '');
    let result = '';
    for (let i = 0; i < text.length; i += 2) {
      if (i + 1 < text.length) {
        result += decodeDigraph(keyTable, text[i], text[i + 1]);
      }
    }
    setDecodeOutput(result);
  };

  const handleAppModeChange = (newMode) => {
    setMode(newMode);
    setInput('');
    setKey('');
    setOutput('');
    setDecodeInput('');
    setDecodeOutput('');
    setTestQuestion('');
    setTestAnswer('');
    setTestKey('');
    setFeedback('');
    if (newMode === 'test') {
      generateTestQuestion();
    }
  };

  const generateTestQuestion = () => {
    const words = ['PLAYFAIR', 'CIPHER', 'DIGRAPH', 'ENCRYPTION', 'KEYWORD'];
    const keys = ['MONARCHY', 'SECRET', 'CIPHER', 'KEYWORD'];
    const randomWord = words[Math.floor(Math.random() * words.length)];
    const randomKey = keys[Math.floor(Math.random() * keys.length)];
    const keyTable = generateKeyTable(randomKey);
    let encryptedWord = '';
    for (let i = 0; i < randomWord.length; i += 2) {
      if (i === randomWord.length - 1) {
        randomWord += 'X';
      }
      encryptedWord += encodeDigraph(keyTable, randomWord[i], randomWord[i + 1]);
    }
    setTestQuestion(encryptedWord);
    setTestKey(randomKey);
    setTestAnswer('');
    setFeedback('');
  };

  const checkTestAnswer = () => {
    const keyTable = generateKeyTable(testKey);
    let decryptedAnswer = '';
    for (let i = 0; i < testQuestion.length; i += 2) {
      decryptedAnswer += decodeDigraph(keyTable, testQuestion[i], testQuestion[i + 1]);
    }
    if (testAnswer.toUpperCase().replace(/[^A-Z]/g, '') === decryptedAnswer) {
      setFeedback('Correct! Well done!');
    } else {
      setFeedback('Incorrect. Try again!');
    }
  };

  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-4">Playfair Cipher</h2>
      
      <div className="mb-4">
        <button 
          onClick={() => handleAppModeChange('encode')} 
          className={`mr-2 px-4 py-2 rounded ${mode === 'encode' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Encode/Decode
        </button>
        <button 
          onClick={() => handleAppModeChange('test')} 
          className={`px-4 py-2 rounded ${mode === 'test' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Test Skills
        </button>
      </div>

      {mode === 'encode' && (
        <>
          <div className="mb-4">
            <input
              type="text"
              value={key}
              onChange={handleKeyChange}
              placeholder="Enter key"
              className="w-full p-2 border rounded mb-2"
            />
            <h3 className="font-bold mb-2">Encode:</h3>
            <textarea
              value={input}
              onChange={handleInputChange}
              placeholder="Enter text to encrypt"
              className="w-full p-2 border rounded"
              rows="4"
            />
          </div>
          <div className="mb-4">
            <h3 className="font-bold mb-2">Encrypted Text:</h3>
            <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded break-all">
              {output}
            </div>
          </div>
          <div className="mb-4">
            <h3 className="font-bold mb-2">Decode:</h3>
            <textarea
              value={decodeInput}
              onChange={handleDecodeInputChange}
              placeholder="Enter text to decrypt"
              className="w-full p-2 border rounded"
              rows="4"
            />
          </div>
          <div className="mb-4">
            <h3 className="font-bold mb-2">Decrypted Text:</h3>
            <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded break-all">
              {decodeOutput}
            </div>
          </div>
        </>
      )}

      {mode === 'test' && (
        <>
          <div className="mb-4">
            <h3 className="font-bold mb-2">Decrypt this code:</h3>
            <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded break-all">
              {testQuestion}
            </div>
            <p className="mt-2">Key: {testKey}</p>
          </div>
          <div className="mb-4">
            <input
              type="text"
              value={testAnswer}
              onChange={(e) => setTestAnswer(e.target.value)}
              placeholder="Enter decrypted text"
              className="w-full p-2 border rounded"
            />
          </div>
          <button 
            onClick={checkTestAnswer}
            className="px-4 py-2 bg-green-500 text-white rounded"
          >
            Check Answer
          </button>
          {feedback && (
            <div className="mt-4 p-2 bg-gray-100 dark:bg-gray-700 rounded">
              {feedback}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PlayfairCipherApp;