import React, { useState } from "react";
import { Collapse, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import RSAEncryptionApp from "../../components/ciphers/RSAEncryptionApp";

const RSAEncryptionPage = () => {
  const [expandedSection, setExpandedSection] = useState("generalInfo");

  const handleToggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <div className="min-h-screen flex flex-col bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <div className="flex flex-1 flex-col md:flex-row">
        <div className="md:w-2/3 p-6">
        <h1 className="text-3xl font-bold mb-6">
        RSA: Public Key Cryptography for the Modern Age
          </h1>
          <div className="space-y-4">
            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("generalInfo")}
              >
                <h2 className="text-xl font-semibold">General Information</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "generalInfo" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "generalInfo"}>
                <div className="mt-2">
                  <p className="mb-4">
                    RSA (Rivest–Shamir–Adleman) is a public-key cryptosystem
                    that is widely used for secure data transmission. It is an
                    asymmetric cryptographic algorithm, meaning it uses two
                    different keys: a public key for encryption and a private
                    key for decryption [1].
                  </p>
                  <p className="mb-4">
                    The security of RSA relies on the practical difficulty of
                    factoring the product of two large prime numbers, known as
                    the factoring problem. RSA is considered secure given
                    sufficiently long keys and the use of up-to-date
                    implementations [2].
                  </p>
                  <p>
                    RSA is commonly used in e-commerce protocols and is believed
                    to be secure given sufficiently long keys and the use of
                    updated implementations. It can be used for both public key
                    encryption and digital signatures [3].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("history")}
              >
                <h2 className="text-xl font-semibold">History</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "history" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "history"}>
                <div className="mt-2">
                  <p className="mb-4">
                    RSA was publicly described in 1977 by Ron Rivest, Adi
                    Shamir, and Leonard Adleman at MIT. The algorithm was
                    patented by MIT in 1983 [1].
                  </p>
                  <p className="mb-4">
                    Interestingly, the same system was developed in 1973 at GCHQ
                    (the British signals intelligence agency) by Clifford Cocks.
                    However, due to the top-secret classification of the work,
                    it was not revealed until 1997 [2].
                  </p>
                  <p>
                    RSA was among the first practical public-key cryptosystems
                    and is widely used for secure data transmission. It marked a
                    revolution in cryptography, introducing the concept of
                    public-key cryptography to the wider public [3].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("howItWorks")}
              >
                <h2 className="text-xl font-semibold">How It Works</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "howItWorks" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "howItWorks"}>
                <div className="mt-2">
                  <p className="mb-4">
                    RSA encryption involves a series of mathematical operations.
                    Here's the process:
                  </p>
                  <ol className="list-decimal pl-6 space-y-4">
                    <li>
                      <p>Select two large prime numbers, p and q.</p>
                    </li>
                    <li>
                      <p>
                        Compute n = p × q. This value is used as the modulus for
                        both the public and private keys.
                      </p>
                    </li>
                    <li>
                      <p>
                        Calculate φ(n) = (p - 1)(q - 1). This is Euler's totient
                        function.
                      </p>
                      <p className="text-sm text-gray-600">
                        This function calculates the number of integers less
                        than n that are coprime to n.
                      </p>
                    </li>
                    <li>
                      <p>
                        Choose an integer e such that 1 &lt; e &lt; φ(n) and
                        gcd(e, φ(n)) = 1.
                      </p>
                      <p className="text-sm text-gray-600">
                        e is the public key exponent. It must be coprime to
                        φ(n).
                      </p>
                    </li>
                    <li>
                      <p>
                        Determine d to satisfy the congruence relation de ≡ 1
                        (mod φ(n)).
                      </p>
                      <p className="text-sm text-gray-600">
                        d is the private key exponent. It's the modular
                        multiplicative inverse of e modulo φ(n).
                      </p>
                    </li>
                  </ol>
                  <p className="mt-4">
                    The public key consists of the modulus n and the public
                    exponent e. The private key consists of the modulus n and
                    the private exponent d.
                  </p>
                  <p className="mt-4">
                    Encryption is performed using the public key (n, e):
                  </p>
                  <p className="font-mono">c = m^e mod n</p>
                  <p className="text-sm text-gray-600">
                    Where m is the message and c is the ciphertext.
                  </p>
                  <p className="mt-4">
                    Decryption is performed using the private key (n, d):
                  </p>
                  <p className="font-mono">m = c^d mod n</p>
                  <p className="mt-4">
                    The security of RSA relies on the practical difficulty of
                    factoring the product of two large prime numbers, the
                    factoring problem [4].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("significance")}
              >
                <h2 className="text-xl font-semibold">
                  Significance and Applications
                </h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "significance" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "significance"}>
                <div className="mt-2">
                  <p className="mb-4">
                    RSA has several significant features and applications:
                  </p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>
                      Public-Key Cryptography: RSA was one of the first
                      practical public-key cryptosystems, revolutionizing the
                      field of cryptography [1].
                    </li>
                    <li>
                      Digital Signatures: RSA can be used to create digital
                      signatures, providing authentication and non-repudiation
                      [3].
                    </li>
                    <li>
                      Key Exchange: It's used in secure key exchange protocols,
                      allowing secure communication over insecure channels [2].
                    </li>
                    <li>
                      E-commerce: RSA is widely used in e-commerce protocols to
                      ensure the security of online transactions [4].
                    </li>
                    <li>
                      Secure Communication: It's used in various secure
                      communication protocols, including SSL/TLS, which is the
                      basis for HTTPS [3].
                    </li>
                  </ul>
                  <p className="mt-4">
                    Despite advances in quantum computing potentially
                    threatening its security in the future, RSA remains a
                    cornerstone of internet security today [4].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("interactiveModel")}
              >
                <h2 className="text-xl font-semibold">Interactive Model</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "interactiveModel" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "interactiveModel"}>
                <div className="mt-2">
                  <p className="mb-4">
                    The interactive RSA Encryption App demonstrates the key
                    components and processes of RSA encryption. Here's an
                    explanation of each element:
                  </p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>
                      <strong>Prime p and Prime q:</strong> These are the two
                      large prime numbers that form the basis of the RSA
                      algorithm. In practice, these would be much larger
                      (typically 2048 bits or more), but for this demonstration,
                      we use smaller primes for computational efficiency.
                    </li>
                    <li>
                      <strong>Public exponent e:</strong> This is part of the
                      public key. It's typically a small odd integer that is
                      coprime to (p-1)(q-1). Common values are 3, 5, 17, 257, or
                      65537. The app randomly selects from these values.
                    </li>
                    <li>
                      <strong>Modulus n:</strong> This is the product of p and q
                      (n = p * q). It's part of both the public and private
                      keys.
                    </li>
                    <li>
                      <strong>Private exponent d:</strong> This is part of the
                      private key. It's calculated as the modular multiplicative
                      inverse of e modulo (p-1)(q-1).
                    </li>
                    <li>
                      <strong>Message:</strong> This is the plaintext you want
                      to encrypt. In RSA, this message must be converted to a
                      number smaller than n.
                    </li>
                    <li>
                      <strong>Encrypted Message:</strong> This is the ciphertext
                      resulting from the encryption process. It's calculated as
                      (message^e) mod n.
                    </li>
                    <li>
                      <strong>Decrypted Message:</strong> This is the result of
                      decrypting the ciphertext. It should match the original
                      message if everything works correctly. It's calculated as
                      (ciphertext^d) mod n.
                    </li>
                  </ul>
                  <p className="mt-4">
                    The "Generate Keys" button creates the public and private
                    keys based on the entered or generated p, q, and e values.
                    The "Encrypt" and "Decrypt" buttons perform the respective
                    operations using these keys.
                  </p>
                  <p className="mt-4">
                    Note that this implementation is for educational purposes
                    only. In practice, RSA encryption involves additional steps
                    like padding schemes to enhance security, and the prime
                    numbers used would be much larger.
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("references")}
              >
                <h2 className="text-xl font-semibold">References</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "references" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "references"}>
                <div className="mt-2">
                  <ol className="list-decimal pl-5">
                    <li>
                      Rivest, R. L., Shamir, A., & Adleman, L. (1978). A method
                      for obtaining digital signatures and public-key
                      cryptosystems. Communications of the ACM, 21(2), 120-126.
                    </li>
                    <li>
                      Katz, J., & Lindell, Y. (2014). Introduction to Modern
                      Cryptography. Chapman and Hall/CRC.
                    </li>
                    <li>
                      Menezes, A. J., Van Oorschot, P. C., & Vanstone, S. A.
                      (1996). Handbook of Applied Cryptography. CRC press.
                    </li>
                    <li>
                      Stallings, W. (2017). Cryptography and Network Security:
                      Principles and Practice (7th ed.). Pearson.
                    </li>
                  </ol>
                </div>
              </Collapse>
            </div>
          </div>
        </div>

        <div className="md:w-1/3 p-6 bg-secondary-light dark:bg-secondary-dark shadow-lg">
          <RSAEncryptionApp />
        </div>
      </div>
    </div>
  );
};

export default RSAEncryptionPage;
