import React, { useState } from 'react';
import { Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AffineCipherApp from '../../components/ciphers/AffineCipherApp';
import AffineCipherGrid from '../../components/ciphers/AffineCipherGrid';

const AffineCipherPage = () => {
  const [expandedSection, setExpandedSection] = useState('generalInfo');

  const handleToggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <div className="min-h-screen flex flex-col bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <div className="flex flex-1 flex-col md:flex-row">
        <div className="md:w-2/3 p-6">
        <h1 className="text-3xl font-bold mb-6">
        Affine Cipher: Where Mathematics Meets Cryptography
          </h1>
          <div className="space-y-4">
            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('generalInfo')}>
                <h2 className="text-xl font-semibold">General Information</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'generalInfo' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'generalInfo'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Affine cipher is a type of monoalphabetic substitution cipher, where each letter in an alphabet is mapped to its numeric equivalent, encrypted using a simple mathematical function, and converted back to a letter [1].
                  </p>
                  <p className="mb-4">
                    The encryption function is E(x) = (ax + b) mod m, where:
                    - x is the numeric value of the letter (e.g., 0 for 'A', 1 for 'B', etc.)
                    - a and b are the key values
                    - m is the size of the alphabet (26 for English)
                  </p>
                  <p>
                    The Affine cipher combines the multiplicative cipher and the Caesar cipher, creating a more complex encryption that is still relatively easy to understand and implement [2].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('history')}>
                <h2 className="text-xl font-semibold">History</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'history' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'history'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Affine cipher is part of the family of monoalphabetic substitution ciphers that have been used for thousands of years. While the exact origin of the Affine cipher is not well-documented, similar principles have been used in cryptography since ancient times [3].
                  </p>
                  <p className="mb-4">
                    The mathematical foundations of the Affine cipher were well understood by the 19th century, thanks to advancements in number theory by mathematicians like Carl Friedrich Gauss [1].
                  </p>
                  <p>
                    Although the Affine cipher is no longer used for serious cryptographic purposes due to its vulnerability to modern cryptanalysis techniques, it remains an important educational tool in the study of cryptography and number theory [4].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('howItWorks')}>
                <h2 className="text-xl font-semibold">How It Works</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'howItWorks' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'howItWorks'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Affine cipher works as follows:
                  </p>
                  <ol className="list-decimal pl-6 space-y-2">
                    <li>Choose two keys: 'a' (which must be coprime to 26) and 'b' (any integer from 0 to 25).</li>
                    <li>Convert each letter in the plaintext to its numerical equivalent (A=0, B=1, ..., Z=25).</li>
                    <li>Apply the encryption function E(x) = (ax + b) mod 26 to each number.</li>
                    <li>Convert the resulting numbers back to letters.</li>
                  </ol>
                  <p className="mt-4">
                    For decryption, the inverse function is used: D(y) = a^(-1)(y - b) mod 26, where a^(-1) is the modular multiplicative inverse of a modulo 26 [2].
                  </p>
                  <p className="mt-4">
                    The security of the Affine cipher relies on the difficulty of determining the keys 'a' and 'b'. However, with only 312 possible key combinations, it's vulnerable to brute force attacks and frequency analysis [4].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('detailedBreakdown')}>
                <h2 className="text-xl font-semibold">Detailed Breakdown</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'detailedBreakdown' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'detailedBreakdown'}>
                <div className="mt-2">
                  <h3 className="text-lg font-semibold mb-2">Co-prime Numbers and Key Selection</h3>
                  <p className="mb-4">
                    In the Affine cipher, the key 'a' must be coprime to 26 (the size of the English alphabet). Two numbers are considered coprime (or relatively prime) if their greatest common divisor (GCD) is 1.
                  </p>
                  <p className="mb-4">
                    The numbers coprime to 26 are: 1, 3, 5, 7, 9, 11, 15, 17, 19, 21, 23, 25.
                  </p>
                  <p className="mb-4">
                    This requirement ensures that each letter in the plaintext maps to a unique letter in the ciphertext, making the encryption reversible.
                  </p>
                  <h3 className="text-lg font-semibold mb-2">Key Combinations</h3>
                  <p className="mb-4">
                    The total number of possible key combinations for the Affine cipher is 12 * 26 = 312. This is because there are 12 possible values for 'a' (the coprime numbers listed above) and 26 possible values for 'b' (0 to 25).
                  </p>
                  <p className="mb-4">
                    Here are some example key combinations and their effects:
                  </p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>(a=1, b=0): This is equivalent to no encryption, as it maps each letter to itself.</li>
                    <li>(a=1, b=1): This is equivalent to the Caesar cipher with a shift of 1.</li>
                    <li>(a=3, b=7): A typical Affine cipher combination, providing a non-linear mapping.</li>
                    <li>(a=25, b=25): This provides maximum "scrambling" but is still easily breakable.</li>
                  </ul>
                  <p className="mt-4">
                    Despite the seemingly large number of combinations, the Affine cipher is still considered weak by modern standards due to its vulnerability to frequency analysis and the relatively small key space.
                  </p>

                  <h3 className="text-lg font-semibold mt-4 mb-2">Encryption Function: E(x) = (ax + b) mod 26</h3>
                  <p className="mb-2">
                    The heart of the Affine cipher is its encryption function: E(x) = (ax + b) mod 26. Let's break this down:
                  </p>
                  <ul className="list-disc pl-6 space-y-2 mb-4">
                    <li>x: The numerical value of the plaintext letter (A=0, B=1, ..., Z=25)</li>
                    <li>a: The first part of the key (must be coprime to 26)</li>
                    <li>b: The second part of the key (any integer from 0 to 25)</li>
                    <li>mod 26: The modulo operation, keeping the result in the range 0-25</li>
                  </ul>
                  <p className="mb-4">
                    This function is applied to each letter in the plaintext to produce the ciphertext.
                  </p>

                  <h4 className="text-md font-semibold mb-2">How it works:</h4>
                  <ol className="list-decimal pl-6 space-y-2 mb-4">
                    <li>Multiply the plaintext number (x) by 'a'</li>
                    <li>Add 'b' to the result</li>
                    <li>Take the result modulo 26</li>
                    <li>Convert the final number back to a letter</li>
                  </ol>

                  <h4 className="text-md font-semibold mb-2">The Modulo Operation:</h4>
                  <p className="mb-2">
                    The modulo operation (mod) finds the remainder after division. For example:
                  </p>
                  <ul className="list-disc pl-6 space-y-2 mb-4">
                    <li>17 mod 5 = 2 (17 divided by 5 is 3 remainder 2)</li>
                    <li>28 mod 26 = 2 (28 divided by 26 is 1 remainder 2)</li>
                  </ul>
                  <p className="mb-4">
                    In the Affine cipher, mod 26 ensures our result always corresponds to a letter in the alphabet (0-25).
                  </p>

                  <h4 className="text-md font-semibold mb-2">Example:</h4>
                  <p className="mb-2">
                    Let's encrypt the letter 'C' using a=5 and b=8:
                  </p>
                  <ol className="list-decimal pl-6 space-y-2 mb-4">
                    <li>'C' is the 3rd letter, so x = 2</li>
                    <li>E(2) = (5 * 2 + 8) mod 26</li>
                    <li>E(2) = 18 mod 26</li>
                    <li>18 corresponds to 'S'</li>
                  </ol>
                  <p className="mb-4">
                    Therefore, with these keys, 'C' would be encrypted to 'S'.
                  </p>

                  <p>
                    This function, combined with the careful selection of 'a' and 'b', forms the basis of the Affine cipher's encryption process.
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('grid')}>
                <h2 className="text-xl font-semibold">Affine Cipher Grid</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'grid' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'grid'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The grid below demonstrates how different combinations of 'a' and 'b' keys affect the encryption. Each cell shows the ciphertext letter that the plaintext letter (row) would be encrypted to using the given 'a' and 'b' values (column) [3].
                  </p>
                  <p className="mb-4">
                    Remember that for a: needs to be coprime to 26.The numbers coprime to 26 are: 1, 3, 5, 7, 9, 11, 15, 17, 19, 21, 23, 25.
                    For b: it can be between 0-25.
                  </p>
                  <p className="mb-4">
                    Change the a: and b: values to see how the calculations change. Pay close attention to how the modulo function works on the final result.
                  </p>
                  <AffineCipherGrid />
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('significance')}>
                <h2 className="text-xl font-semibold">Significance and Limitations</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'significance' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'significance'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Affine cipher is significant in the study of cryptography for several reasons:
                  </p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>It introduces the concept of modular arithmetic in cryptography.</li>
                    <li>It demonstrates how mathematical functions can be used for encryption.</li>
                    <li>It serves as a stepping stone to understanding more complex ciphers.</li>
                  </ul>
                  <p className="mt-4">
                    However, the Affine cipher has several limitations:
                  </p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>It's vulnerable to frequency analysis, as letter frequencies are preserved.</li>
                    <li>With only 312 possible keys, it's susceptible to brute force attacks.</li>
                    <li>It doesn't provide diffusion, meaning a change in one plaintext letter only affects one ciphertext letter [4].</li>
                  </ul>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('references')}>
                <h2 className="text-xl font-semibold">References</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'references' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'references'}>
                <div className="mt-2">
                  <ol className="list-decimal pl-5">
                    <li>
                      Trappe, W., & Washington, L. C. (2006). Introduction to Cryptography with Coding Theory. Pearson Prentice Hall. pp. 20-22.
                    </li>
                    <li>
                      Katz, J., & Lindell, Y. (2014). Introduction to Modern Cryptography. Chapman and Hall/CRC. pp. 12-14.
                    </li>
                    <li>
                      Singh, S. (1999). The Code Book: The Science of Secrecy from Ancient Egypt to Quantum Cryptography. Anchor Books. pp. 14-16.
                    </li>
                    <li>
                      Stamp, M. (2011). Information Security: Principles and Practice. John Wiley & Sons. pp. 24-26.
                    </li>
                  </ol>
                </div>
              </Collapse>
            </div>
          </div>
        </div>

        <div className="md:w-1/3 p-6 bg-secondary-light dark:bg-secondary-dark shadow-lg">
          <AffineCipherApp />
        </div>
      </div>
    </div>
  );
};

export default AffineCipherPage;