import React from 'react';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div className="relative isolate px-6 pt-14 lg:px-8 bg-background-light dark:bg-background-dark">
      <div className="absolute inset-0 z-[-1] overflow-hidden">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute inset-0 w-full h-full object-cover opacity-40"
          src="/background.mp4"
        ></video>
      </div>
      <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
        <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-primary-light to-secondary-light dark:from-primary-dark dark:to-secondary-dark opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style={{clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"}}></div>
      </div>
      
      <div className="relative z-10 mx-auto max-w-2xl py-24 sm:py-48 lg:py-48">
        <div className="hidden sm:mb-8 sm:flex sm:justify-center">
          <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-text-light dark:text-text-dark ring-1 ring-text-light/10 dark:ring-text-dark/10 hover:ring-text-light/20 dark:hover:ring-text-dark/20">
            Explore the world of ciphers. <a href="/what-is-a-cipher" className="font-semibold text-primary-light dark:text-primary-dark"><span className="absolute inset-0" aria-hidden="true"></span>Learn more <span aria-hidden="true">&rarr;</span></a>
          </div>
        </div>
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-text-light dark:text-text-dark sm:text-6xl">Cipher Education: Unlock the Secrets of Encryption</h1>
          <p className="mt-6 text-lg leading-8 text-text-light dark:text-text-dark opacity-80">Dive into the fascinating world of ciphers and encryption. From ancient methods to modern techniques, discover how information has been protected throughout history.</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/learn/morse-code"
              className="rounded-md bg-primary-light dark:bg-primary-dark px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-light/80 dark:hover:bg-primary-dark/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-light dark:focus-visible:outline-primary-dark"
            >
              Get started
            </Link>
            <Link
              to="/about"
              className="text-sm font-semibold leading-6 text-text-light dark:text-text-dark"
            >
              Learn more <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
