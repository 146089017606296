import React, { useState, useEffect } from 'react';

const PlayfairGrid = () => {
  const [key] = useState('SECRET');
  const [plaintext] = useState('ROUTER');
  const [grid, setGrid] = useState([]);
  const [ciphertext] = useState('EQZGCT');
  const [highlightedCells, setHighlightedCells] = useState([]);
  const [currentRule, setCurrentRule] = useState('');
  const [currentStep, setCurrentStep] = useState(-1);

  useEffect(() => {
    const newGrid = generateKeyTable(key);
    setGrid(newGrid);
    
   
  }, [key, plaintext]);

  useEffect(() => {
    if (grid.length === 0) return;

    const interval = setInterval(() => {
      setCurrentStep((prevStep) => (prevStep + 1) % 3);
    }, 5000);

    return () => clearInterval(interval);
  }, [grid]);

  useEffect(() => {
    if (grid.length === 0 || currentStep === -1) return;

    const pair = [plaintext[currentStep * 2], plaintext[currentStep * 2 + 1]];
    const encryptedPair = encryptPair(pair[0], pair[1], grid);
    const plainPositions = findPositions(pair[0], pair[1]);
    const encryptedPositions = findPositions(encryptedPair[0], encryptedPair[1]);
    setHighlightedCells([...plainPositions, ...encryptedPositions]);
    setCurrentRule(getEncryptionRule(plainPositions));
  }, [currentStep, plaintext, grid]);

  const generateKeyTable = (key) => {
    let keyTable = new Array(5).fill().map(() => new Array(5));
    let keySet = new Set();
    let alphabet = 'ABCDEFGHIKLMNOPQRSTUVWXYZ';
    let i = 0, j = 0;

    key = key.toUpperCase().replace(/J/g, 'I').replace(/[^A-Z]/g, '');
    for (let char of key) {
      if (!keySet.has(char)) {
        keyTable[i][j] = char;
        keySet.add(char);
        j++;
        if (j === 5) {
          i++;
          j = 0;
        }
      }
    }

    for (let char of alphabet) {
      if (!keySet.has(char) && !keyTable[4][4]) {
        keyTable[i][j] = char;
        j++;
        if (j === 5) {
          i++;
          j = 0;
        }
      }
    }

    return keyTable;
  };

  const findPositions = (char1, char2) => {
    if (!grid || grid.length === 0) return [];

    const positions = [];
    for (let i = 0; i < 5; i++) {
      for (let j = 0; j < 5; j++) {
        if (grid[i][j] === char1 || grid[i][j] === char2) {
          positions.push([i, j]);
        }
      }
    }
    return positions;
  };

  const getEncryptionRule = (positions) => {
    if (positions.length !== 2) return '';
    const [[row1, col1], [row2, col2]] = positions;

    if (row1 === row2) return 'Same row: Replace with letters to the right';
    if (col1 === col2) return 'Same column: Replace with letters below';
    return 'Different row and column: Replace with letters at the other corners of the rectangle';
  };

  const encryptPair = (char1, char2, gridTable) => {
    const positions = findPositions(char1, char2);
    if (positions.length !== 2) return '';
    const [[row1, col1], [row2, col2]] = positions;

    if (row1 === row2) {
      return gridTable[row1][(col1 + 1) % 5] + gridTable[row2][(col2 + 1) % 5];
    } else if (col1 === col2) {
      return gridTable[(row1 + 1) % 5][col1] + gridTable[(row2 + 1) % 5][col2];
    } else {
      return gridTable[row1][col2] + gridTable[row2][col1];
    }
  };

  const encryptFullText = (text, gridTable) => {
    let result = '';
    for (let i = 0; i < text.length; i += 2) {
      const char1 = text[i];
      const char2 = text[i + 1] || 'X';
      result += encryptPair(char1, char2, gridTable);
    }
    return result;
  };

  if (grid.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white p-4 rounded-lg shadow-lg bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <h3 className="text-lg font-semibold mb-2">Playfair Grid</h3>
      <div className="mb-4">
        <p>Key: {key}</p>
        <p>
          Plaintext: {plaintext.split('').map((char, index) => (
            <span key={index} className={Math.floor(index / 2) === currentStep ? 'text-blue-500 font-bold' : ''}>
              {char}
            </span>
          ))}
        </p>
        <p>
          Ciphertext: {ciphertext.split('').map((char, index) => (
            <span key={index} className={Math.floor(index / 2) === currentStep ? 'text-green-500 font-bold' : ''}>
              {char}
            </span>
          ))}
        </p>
      </div>
      <div className="mb-4">
        <p>Current Rule: {currentRule}</p>
      </div>
      <div className="grid grid-cols-5 gap-1 mb-4">
        {grid.flat().map((letter, index) => (
          <div 
            key={index} 
            className={`border p-1 text-center font-bold aspect-square flex items-center justify-center text-sm
              ${highlightedCells.slice(0, 2).some(([row, col]) => row * 5 + col === index) ? 'bg-blue-200' : ''}
              ${highlightedCells.slice(2).some(([row, col]) => row * 5 + col === index) ? 'bg-green-200' : ''}`}
          >
            {letter}
          </div>
        ))}
      </div>
      
    </div>
  );
};

export default PlayfairGrid;