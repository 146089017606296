import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const CaesarCipherVisualizer = ({ inputText = "HELLO WORLD" }) => {
  const svgRef = useRef(null);
  const [activeShifts, setActiveShifts] = useState([]);
  const allShifts = [3, 7, 11, 15, 19, 23, 25, 1]; // 8 predefined shifts

  useEffect(() => {
    if (svgRef.current) {
      createVisualization();
    }
  }, [inputText, activeShifts]);

  const caesarCipher = (text, shift) => {
    return text.split('').map(char => {
      if (char.match(/[A-Z]/)) {
        const code = char.charCodeAt(0);
        return String.fromCharCode(((code - 65 + shift) % 26) + 65);
      }
      return char;
    }).join('');
  };

  const createVisualization = () => {
    const width = 800;
    const height = 800;
    const centerX = width / 2;
    const centerY = height / 2;

    d3.select(svgRef.current).selectAll("*").remove();

    const svg = d3.select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height]);

    const nodes = [
      { id: 'center', label: inputText, shift: 0, fx: centerX, fy: centerY },
      ...activeShifts.map(shift => ({
        id: `shift-${shift}`,
        label: caesarCipher(inputText, shift),
        shift: shift
      }))
    ];

    const links = activeShifts.map(shift => ({
      source: 'center',
      target: `shift-${shift}`
    }));

    const simulation = d3.forceSimulation(nodes)
      .force("link", d3.forceLink(links).id(d => d.id).distance(300))
      .force("charge", d3.forceManyBody().strength(-500))
      .force("center", d3.forceCenter(centerX, centerY))
      .force("collision", d3.forceCollide().radius(70))
      .force("x", d3.forceX(centerX).strength(0.1))
      .force("y", d3.forceY(centerY).strength(0.1));

    const link = svg.append("g")
      .selectAll("line")
      .data(links)
      .enter().append("line")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6)
      .attr("stroke-width", 2);

    const node = svg.append("g")
      .selectAll("g")
      .data(nodes)
      .enter().append("g");

    node.append("circle")
      .attr("r", d => d.id === 'center' ? 120 : 90)
      .attr("fill", d => d.id === 'center' ? "#4299e1" : "#9ae6b4");

    node.append("text")
      .attr("text-anchor", "middle")
      .attr("dy", d => d.id === 'center' ? "0.3em" : "-1em")
      .attr("font-size", d => d.id === 'center' ? "26px" : "22px")
      .attr("fill", d => d.id === 'center' ? "white" : "black")
      .text(d => d.id === 'center' ? d.label : `Shift: ${d.shift}`);

    node.append("text")
      .attr("text-anchor", "middle")
      .attr("dy", "1em")
      .attr("font-size", "24px")
      .text(d => d.id === 'center' ? "" : d.label);

    simulation.on("tick", () => {
      link
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);

      node.attr("transform", d => {
        const x = Math.max(60, Math.min(width - 60, d.x));
        const y = Math.max(60, Math.min(height - 60, d.y));
        return `translate(${x},${y})`;
      });
    });
  };

  const addShift = () => {
    if (activeShifts.length < allShifts.length) {
      const newShift = allShifts[activeShifts.length];
      setActiveShifts([...activeShifts, newShift]);
    }
  };

  return (
    <div className="w-full h-full flex flex-col items-center">
      <svg ref={svgRef} className="w-full max-w-[800px] h-[300px]"></svg>
      <button 
        onClick={addShift} 
        className="mt-4 px-6 py-3 bg-blue-500 text-white rounded text-xl hover:bg-blue-600 transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
        disabled={activeShifts.length >= allShifts.length}
      >
        {activeShifts.length >= allShifts.length ? "Add Shift" : "Add Shift"}
      </button>
    </div>
  );
};

export default CaesarCipherVisualizer;