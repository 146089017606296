import React, { useState } from 'react';
import { Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import VigenereCipher from '../../components/ciphers/VigenereCipherApp';
import VigenereCipherAlphabet from '../../components/ciphers/VigenereCipherAlphabet';

const VigenereCipherPage = () => {
  const [expandedSection, setExpandedSection] = useState('generalInfo');

  const handleToggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <div className="min-h-screen flex flex-col bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <div className="flex flex-1 flex-col md:flex-row">
        <div className="md:w-2/3 p-6">
        <h1 className="text-3xl font-bold mb-6">
        Vigenère Cipher: The Polyalphabetic Game Changer
          </h1>
          <div className="space-y-4">
            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('generalInfo')}>
                <h2 className="text-xl font-semibold">General Information</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'generalInfo' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'generalInfo'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Vigenère cipher is a polyalphabetic substitution cipher that uses a keyword to shift letters in the plaintext. It's an extension of the simpler Caesar cipher, but with multiple shift values determined by the keyword [1].
                  </p>
                  <p className="mb-4">
                    This cipher is known for its resistance to frequency analysis, making it significantly more secure than simple substitution ciphers for its time. It achieved this by using multiple cipher alphabets, effectively flattening the frequency distribution of letters in the ciphertext [2].
                  </p>
                  <p>
                    The strength of the Vigenère cipher lies in its use of a keyword, which introduces a level of complexity that simple monoalphabetic substitution ciphers lack. This made it a formidable encryption method for several centuries [1].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('history')}>
                <h2 className="text-xl font-semibold">History</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'history' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'history'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Vigenère cipher was first described by Giovan Battista Bellaso in his 1553 book "La cifra del. Sig. Giovan Battista Bellaso". However, it is named after Blaise de Vigenère, who described a stronger autokey cipher in his 1586 treatise "Traicté des Chiffres" [3].
                  </p>
                  <p className="mb-4">
                    For three centuries, the cipher was thought to be unbreakable. This earned it the nickname "le chiffre indéchiffrable" (French for "the indecipherable cipher"). Its reputation was so strong that it was featured in Edgar Allan Poe's story "The Gold-Bug" as an unbreakable cipher [2].
                  </p>
                  <p>
                    However, the cipher's invulnerability was eventually disproven. Charles Babbage and Friedrich Kasiski independently developed methods to break the Vigenère cipher in the mid-19th century. Kasiski published his method in 1863, marking the end of the Vigenère cipher's reign as a secure encryption method [4].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('usage')}>
                <h2 className="text-xl font-semibold">Usage and Characteristics</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'usage' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'usage'}>
                <div className="mt-2">
                  <p className="mb-4">
                    To use the Vigenère cipher, a keyword is repeated to match the length of the plaintext. Each letter of the keyword indicates how many positions to shift the corresponding plaintext letter [1].
                  </p>
                  <p className="mb-4">
                    The Vigenère cipher uses a table (often called a Vigenère square) to encrypt the message. This table consists of the alphabet written out 26 times, with each row shifted one position to the left from the previous row [2].
                  </p>
                  <p className="mb-4">
                    Key characteristics of the Vigenère cipher include:
                  </p>
                  <ul className="list-disc pl-5 mb-4">
                    <li>Polyalphabetic: It uses multiple substitution alphabets.</li>
                    <li>Keyword-based: The strength of the encryption depends on the length and randomness of the keyword.</li>
                    <li>Periodic: The encryption repeats after the length of the keyword, which can be exploited for cryptanalysis.</li>
                    <li>Resistant to frequency analysis: Simple frequency analysis techniques are ineffective against it.</li>
                  </ul>
                  <p>
                    While more secure than simple substitution ciphers, the Vigenère cipher can still be broken with enough ciphertext, especially if the length of the keyword can be determined [4].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('alphabet')}>
                <h2 className="text-xl font-semibold">Vigenère Cipher Table</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'alphabet' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'alphabet'}>
                <div className="mt-2">
                  <p className="mb-4">
                    Below is the Vigenère square, also known as the tabula recta. To encrypt, find the row headed by the key letter and the column headed by the plaintext letter. The letter at the intersection is the ciphertext letter [2]. In the example below you can see the key has less characters than the plaintext. In this case the key would be extended to match the amounts of characters used in the plaintext eg : CODECO
                  </p>
                  <VigenereCipherAlphabet />
                  <p className="mt-4">
                    To decrypt, find the row headed by the key letter, then find the position of the ciphertext letter in that row. The column's heading is the plaintext letter.
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('references')}>
                <h2 className="text-xl font-semibold">References</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'references' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'references'}>
                <div className="mt-2">
                  <ol className="list-decimal pl-5">
                    <li>
                      Singh, S. (1999). The Code Book: The Science of Secrecy from Ancient Egypt to Quantum Cryptography. Anchor Books. pp. 63-78.
                    </li>
                    <li>
                      Kahn, D. (1996). The Codebreakers: The Comprehensive History of Secret Communication from Ancient Times to the Internet. Scribner. pp. 146-151.
                    </li>
                    <li>
                      Mollin, R. A. (2005). Codes: The Guide to Secrecy from Ancient to Modern Times. Chapman and Hall/CRC. pp. 103-108.
                    </li>
                    <li>
                      Bauer, C. P. (2013). Secret History: The Story of Cryptology. CRC Press. pp. 89-95.
                    </li>
                  </ol>
                </div>
              </Collapse>
            </div>
          </div>
        </div>

        <div className="md:w-1/3 p-6 bg-secondary-light dark:bg-secondary-dark shadow-lg">
          <VigenereCipher />
        </div>
      </div>
    </div>
  );
};

export default VigenereCipherPage;