import React, { useState } from 'react';
import { Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import BaconCipher from '../../components/ciphers/BaconCipherApp';
import BaconCipherAlphabet from '../../components/ciphers/BaconCipherAlphabet';

const BaconCipherPage = () => {
  const [expandedSection, setExpandedSection] = useState('generalInfo');

  const handleToggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <div className="min-h-screen flex flex-col bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <div className="flex flex-1 flex-col md:flex-row">
        <div className="md:w-2/3 p-6">
        <h1 className="text-3xl font-bold mb-6">
        Bacon's Cipher: Hidden Messages in Plain Sight
          </h1>
          <div className="space-y-4">
            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('generalInfo')}>
                <h2 className="text-xl font-semibold">General Information</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'generalInfo' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'generalInfo'}>
                <div className="mt-2">
                  <p className="mb-4">
                    Bacon's cipher, devised by Sir Francis Bacon in 1605, is a method of steganography - the practice of concealing a secret message within an ordinary message. It is a binary encoding system that represents each letter of the alphabet using a unique sequence of five binary digits [1].
                  </p>
                  <p className="mb-4">
                    In its original form, Bacon's cipher uses two different typefaces to represent the binary digits. Each letter in the plaintext is replaced by a group of five letters consisting of these two typefaces, effectively hiding the message within an apparently innocent text [2].
                  </p>
                  <p>
                    While not a strong encryption method by modern standards, Bacon's cipher was an important development in the history of cryptography and steganography, laying groundwork for future binary encoding systems [1].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('history')}>
                <h2 className="text-xl font-semibold">History</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'history' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'history'}>
                <div className="mt-2">
                  <p className="mb-4">
                    Sir Francis Bacon, an English philosopher and statesman, developed this cipher in 1605 as part of his work on the advancement of learning. It was described in his book "The Advancement of Learning", published in 1605 [3].
                  </p>
                  <p className="mb-4">
                    Bacon's cipher is significant in the history of cryptography for two main reasons:
                  </p>
                  <ol className="list-decimal pl-5 mb-4">
                    <li className="mb-2">It was one of the first binary encoding schemes, predating modern binary systems by centuries.</li>
                    <li className="mb-2">It introduced the concept of steganography in a systematic way, showing how secret messages could be hidden within ordinary text.</li>
                  </ol>
                  <p>
                    Interestingly, Bacon's cipher has been at the center of some historical controversies. Some theorists have suggested that Bacon used this cipher to hide messages in Shakespeare's works, fueling the debate about the true authorship of Shakespeare's plays. However, these claims are generally not accepted by mainstream scholars [2].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('usage')}>
                <h2 className="text-xl font-semibold">Usage and Characteristics</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'usage' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'usage'}>
                <div className="mt-2">
                  <p className="mb-4">
                    In Bacon's original cipher, each letter is assigned a unique sequence of five 'A's and 'B's, which can be thought of as binary digits. For example:
                  </p>
                  <ul className="list-disc pl-5 mb-4">
                    <li>A = aaaaa</li>
                    <li>B = aaaab</li>
                    <li>C = aaaba</li>
                    <li>...</li>
                  </ul>
                  <p className="mb-4">
                    To encode a message, each letter of the plaintext is replaced by its corresponding five-letter sequence. The resulting ciphertext can then be hidden in a covertext by using two different typefaces to represent the 'A's and 'B's [4].
                  </p>
                  <p className="mb-4">
                    Key characteristics of Bacon's cipher include:
                  </p>
                  <ul className="list-disc pl-5 mb-4">
                    <li>Binary encoding: It uses a binary system, predating modern computer binary codes.</li>
                    <li>Steganographic nature: The secret message is hidden within an innocent-looking text.</li>
                    <li>Fixed-length encoding: Each letter is represented by exactly five binary digits.</li>
                    <li>Flexibility: The 'A' and 'B' can be represented by any two distinctly different marks, not just typefaces.</li>
                  </ul>
                  <p>
                    While not secure against modern cryptanalysis, Bacon's cipher remains an important historical cipher and a fascinating example of early steganography [1].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('alphabet')}>
                <h2 className="text-xl font-semibold">Bacon's Cipher Alphabet</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'alphabet' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'alphabet'}>
                <div className="mt-2">
                  <p className="mb-4">
                    Below is the complete alphabet for Bacon's cipher, showing the binary representation for each letter:
                  </p>
                  <BaconCipherAlphabet />
                  <p className="mt-4">
                    Note that in Bacon's original cipher, I and J share the same code, as do U and V. This is because in the English alphabet of Bacon's time, these letters were often used interchangeably [4].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('references')}>
                <h2 className="text-xl font-semibold">References</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'references' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'references'}>
                <div className="mt-2">
                  <ol className="list-decimal pl-5">
                    <li>
                      Singh, S. (1999). The Code Book: The Science of Secrecy from Ancient Egypt to Quantum Cryptography. Anchor Books. pp. 45-46.
                    </li>
                    <li>
                      Kahn, D. (1996). The Codebreakers: The Comprehensive History of Secret Communication from Ancient Times to the Internet. Scribner. pp. 882-883.
                    </li>
                    <li>
                      Bacon, F. (1605). The Advancement of Learning. London: Henrie Tomes.
                    </li>
                    <li>
                      Fouché Gaines, H. (1956). Cryptanalysis: A Study of Ciphers and Their Solution. Dover Publications. p. 198.
                    </li>
                  </ol>
                </div>
              </Collapse>
            </div>
          </div>
        </div>

        <div className="md:w-1/3 p-6 bg-secondary-light dark:bg-secondary-dark shadow-lg">
          <BaconCipher />
        </div>
      </div>
    </div>
  );
};

export default BaconCipherPage;