import { BookOpenIcon, PuzzlePieceIcon, ComputerDesktopIcon } from '@heroicons/react/20/solid';
import { useContext } from 'react';
import { ThemeContext } from '../utils/themeContext';
import { Transition } from '@headlessui/react';

const features = [
  {
    name: 'Historical Context',
    description:
      'Learn about the historical context of ciphers and how they have been used throughout history, from ancient times to modern-day encryption.',
    href: '#',
    icon: BookOpenIcon,
  },
  {
    name: 'Types of Ciphers',
    description:
      'Explore various types of ciphers, including substitution ciphers, transposition ciphers, and more advanced cryptographic techniques.',
    href: '#',
    icon: PuzzlePieceIcon,
  },
  {
    name: 'Interactive Learning',
    description:
      'Engage with interactive tools and resources to learn how to encode and decode messages using different cipher techniques.',
    href: '#',
    icon: ComputerDesktopIcon,
  },
];

export default function Example() {
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark';

  return (
    <div className={`py-24 sm:py-32 ${isDarkMode ? 'bg-secondary-dark' : 'bg-secondary-light'}`}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <Transition
          appear
          show
          enter="transition-opacity duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          <div>
            <div className="mx-auto max-w-2xl lg:text-center">
              <h2 className={`text-base font-semibold leading-7 ${isDarkMode ? 'text-primary-dark' : 'text-primary-light'}`}>
                Learn about Ciphers
              </h2>
              <p className={`mt-2 text-3xl font-bold tracking-tight ${isDarkMode ? 'text-text-dark' : 'text-text-light'} sm:text-4xl`}>
                Discover the World of Cryptography
              </p>
              <p className={`mt-6 text-lg leading-8 ${isDarkMode ? 'text-text-dark' : 'text-text-light'} opacity-80`}>
                Delve into the fascinating world of cryptography and uncover the secrets behind secure communication.
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                {features.map((feature) => (
                  <div key={feature.name} className="flex flex-col">
                    <dt className={`flex items-center gap-x-3 text-base font-semibold leading-7 ${isDarkMode ? 'text-text-dark' : 'text-text-light'}`}>
                      <feature.icon className={`h-5 w-5 flex-none ${isDarkMode ? 'text-primary-dark' : 'text-primary-light'}`} aria-hidden="true" />
                      {feature.name}
                    </dt>
                    <dd className={`mt-4 flex flex-auto flex-col text-base leading-7 ${isDarkMode ? 'text-text-dark' : 'text-text-light'} opacity-80`}>
                      <p className="flex-auto">{feature.description}</p>
                      <p className="mt-6">
                        
                      </p>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}