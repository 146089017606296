// src/components/EnigmaMachineApp.js
import React, { useState } from 'react';

const EnigmaMachineApp = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [initialRotorPositions, setInitialRotorPositions] = useState([0, 0, 0]);
  const [mode, setMode] = useState('encode');

  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  // Simplified rotor wirings
  const rotors = [
    'EKMFLGDQVZNTOWYHXUSPAIBRCJ',
    'AJDKSIRUXBLHWTMCQGZNPYFVOE',
    'BDFHJLCPRTXVZNYEIWGAKMUSQO'
  ];

  // Notch positions (when these positions are reached, the next rotor will turn)
  const notches = [16, 4]; // Q and E for rotors 1 and 2 respectively

  const reflector = 'YRUHQSLDPXNGOKMIEBFZCWVJAT';

  const enigmaSubstitution = (char, positions) => {
    if (!alphabet.includes(char)) return char;

    let index = alphabet.indexOf(char);

    // Forward through rotors
    for (let i = 0; i < 3; i++) {
      index = (index + positions[i]) % 26;
      index = rotors[i].indexOf(alphabet[index]);
    }

    // Reflector
    index = reflector.indexOf(alphabet[index]);

    // Backward through rotors
    for (let i = 2; i >= 0; i--) {
      index = alphabet.indexOf(rotors[i][index]);
      index = (index - positions[i] + 26) % 26;
    }

    return alphabet[index];
  };

  const rotateRotors = (positions) => {
    let newPositions = [...positions];
    
    // Check if middle rotor is at notch position
    if (newPositions[1] === notches[1]) {
      newPositions[1] = (newPositions[1] + 1) % 26;
      newPositions[2] = (newPositions[2] + 1) % 26;
    }
    
    // Check if first rotor is at notch position
    if (newPositions[0] === notches[0]) {
      newPositions[1] = (newPositions[1] + 1) % 26;
    }
    
    // Always rotate the first rotor
    newPositions[0] = (newPositions[0] + 1) % 26;
    
    return newPositions;
  };

  const processInput = (text, initialPositions) => {
    let currentPositions = [...initialPositions];
    let result = '';
    for (let char of text.toUpperCase()) {
      if (alphabet.includes(char)) {
        result += enigmaSubstitution(char, currentPositions);
        currentPositions = rotateRotors(currentPositions);
      } else {
        result += char;
      }
    }
    return result;
  };

  const handleInputChange = (e) => {
    const newInput = e.target.value;
    setInput(newInput);
    setOutput(processInput(newInput, initialRotorPositions));
  };

  const handleModeChange = (newMode) => {
    setMode(newMode);
    setInput('');
    setOutput('');
    setInitialRotorPositions([0, 0, 0]);
  };

  const handleRotorChange = (index, value) => {
    const newPositions = [...initialRotorPositions];
    newPositions[index] = parseInt(value) % 26;
    setInitialRotorPositions(newPositions);
    setOutput(processInput(input, newPositions));
  };

  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-4">Enigma Machine Simulator</h2>
      <p>This example is not a fully working Enigma machine code; however, it provides some mechanisms used in the Enigma machine for the encryption process.</p>
      <br></br>
      <div className="mb-4">
        <button 
          onClick={() => handleModeChange('encode')} 
          className={`mr-2 px-4 py-2 rounded ${mode === 'encode' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Encode
        </button>
        <button 
          onClick={() => handleModeChange('decode')} 
          className={`px-4 py-2 rounded ${mode === 'decode' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Decode
        </button>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">Rotor Positions:</label>
        <div className="flex space-x-2">
          {initialRotorPositions.map((pos, index) => (
            <input
              key={index}
              type="number"
              min="0"
              max="25"
              value={pos}
              onChange={(e) => handleRotorChange(index, e.target.value)}
              className="w-16 p-1 border rounded"
            />
          ))}
        </div>
      </div>

      <div className="mb-4">
        <textarea
          value={input}
          onChange={handleInputChange}
          placeholder={`Enter text to ${mode}`}
          className="w-full p-2 border rounded"
          rows="4"
        />
      </div>

      <div className="mb-4">
        <h3 className="font-bold mb-2">{mode === 'encode' ? 'Encoded' : 'Decoded'} Text:</h3>
        <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded break-all">
          {output}
        </div>
      </div>
    </div>
  );
};

export default EnigmaMachineApp;