import React, { useContext } from 'react';
import { ThemeContext } from '../utils/themeContext';

const KeyWords = () => {
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark';

  return (
    <div className={`max-w-3xl mx-auto p-6 ${isDarkMode ? 'bg-dark text-text-dark' : 'bg-secondary-light text-text-light'}`}>
      <header className="border-b-2 border-primary-light dark:border-primary-dark pb-4 mb-6">
        <h1 className={`text-4xl font-bold ${isDarkMode ? 'text-primary-dark' : 'text-primary-light'}`}>
          Key Words in Cryptography
        </h1>
      </header>

      <div>
        {[
          { term: 'Cryptography', definition: 'The science and practice of secure communication techniques. It involves creating and analyzing protocols that prevent third parties from reading private messages. Cryptography covers a wide range of applications beyond just keeping secrets, including data integrity, authentication, and non-repudiation.' },
          { term: 'Cryptanalysis', definition: 'The study of methods for breaking ciphers and codes without knowing the key. It involves analyzing cryptographic systems to find weaknesses that could be exploited to decrypt messages. Cryptanalysis has played a crucial role in warfare and diplomacy throughout history.' },
          { term: 'Encryption', definition: 'The process of converting information into a secret code to prevent unauthorized access. It involves using an algorithm and a key to transform plaintext into ciphertext. Encryption is fundamental to protecting digital communications and stored data in the modern world.' },
          { term: 'Decryption', definition: 'The process of converting encrypted information back to its original, readable form. It requires the correct key and algorithm to reverse the encryption process. Successful decryption allows the intended recipient to access the original message or data.' },
          { term: 'Plaintext', definition: 'The original, readable message before encryption. It can be in any form - text, numerical data, or even binary code. In cryptography, the goal is to protect this plaintext from unauthorized readers by converting it into ciphertext.' },
          { term: 'Ciphertext', definition: 'The encrypted, unreadable version of a message. It\'s the output of an encryption process and appears as a seemingly random string of characters. Without the proper key and decryption method, ciphertext should be indecipherable to unauthorized parties.' },
          { term: 'Substitution cipher', definition: 'A method of encryption where each unit (usually a letter) in the plaintext is replaced with another unit according to a fixed system. This can range from simple letter-for-letter substitutions to more complex systems using symbols or numbers. While basic substitution ciphers are easy to break, they form the foundation for more complex encryption methods.' },
          { term: 'Transposition cipher', definition: 'A method of encryption where the positions of units (typically letters) in the plaintext are rearranged according to a regular system. The units themselves are not changed, only their order. Transposition ciphers can be combined with other methods to create more secure encryption systems.' },
          { term: 'Polyalphabetic cipher', definition: 'A cipher that uses multiple substitution alphabets to encrypt a message. This method was a significant advancement over simple substitution ciphers as it helps to mask the frequency distribution of letters, making the cipher more resistant to frequency analysis attacks.' },
          { term: 'Monoalphabetic cipher', definition: 'A cipher that uses a single substitution alphabet throughout the entire message. While simpler to implement than polyalphabetic ciphers, they are also more vulnerable to frequency analysis. The Caesar cipher is a well-known example of a monoalphabetic cipher.' },
          { term: 'Frequency analysis', definition: 'A technique for breaking ciphers by analyzing the frequency of letters or symbols in the ciphertext. It\'s based on the fact that certain letters appear more frequently than others in any given language. While highly effective against simple substitution ciphers, more complex encryption methods were developed to resist this type of analysis.' },
          { term: 'Key (cryptography)', definition: 'Secret information used to control the encryption and decryption processes. In modern cryptography, the security of a system typically relies on the secrecy of the key rather than the secrecy of the algorithm. Keys can be symmetric (the same key for encryption and decryption) or asymmetric (different keys for encryption and decryption).' },
          { term: 'Public-key cryptography', definition: 'A cryptographic system that uses pairs of keys: public keys which may be disseminated widely, and private keys which are known only to the owner. This allows users to communicate securely over an insecure channel without having to share a secret key in advance. It\'s the foundation of many modern secure communication systems, including secure web browsing and digital signatures.' },
          { term: 'Symmetric-key algorithm', definition: 'An encryption method where the same key is used for both encryption and decryption. This requires that both the sender and receiver have access to the same secret key. While generally faster than asymmetric algorithms, the main challenge lies in securely distributing the key to both parties.' },
          { term: 'Asymmetric-key algorithm', definition: 'An encryption method using different keys for encryption and decryption, typically a public key for encryption and a private key for decryption. This solves the key distribution problem of symmetric-key systems but is generally more computationally intensive. RSA is a well-known example of an asymmetric-key algorithm.' },
          { term: 'Cryptographic hash function', definition: 'A mathematical algorithm that maps data of arbitrary size to a fixed-size output (hash value). It\'s designed to be a one-way function, infeasible to invert. Hash functions are used for data integrity checks, password verification, and digital signatures. Common examples include SHA-256 and MD5.' },
          { term: 'Digital signature', definition: 'A mathematical scheme for verifying the authenticity of digital messages or documents. It provides a way to ensure that a message was created by a known sender (authentication), that the sender cannot deny having sent the message (non-repudiation), and that the message was not altered in transit (integrity).' },
          { term: 'Quantum cryptography', definition: 'A method of encryption that uses principles of quantum mechanics to secure communication. It allows two parties to produce a shared random secret key known only to them, which can then be used to encrypt and decrypt messages. The most well-known application is quantum key distribution (QKD).' },
          { term: 'Brute-force attack', definition: 'A cryptanalytic technique that involves systematically checking all possible keys until the correct one is found. While theoretically able to crack any encrypted data, the time required for a successful brute-force attack grows exponentially with key size, making it impractical for well-designed crypto systems with large key spaces.' },
          { term: 'Man-in-the-middle attack', definition: 'A type of cyberattack where the attacker secretly relays and possibly alters the communication between two parties who believe they are directly communicating with each other. This attack is particularly dangerous because it can circumvent many cryptographic protocols if proper authentication measures are not in place.' },
          { term: 'Side-channel attack', definition: 'A form of attack based on information gained from the physical implementation of a cryptosystem, rather than weaknesses in the algorithm itself. This could include analyzing power consumption, electromagnetic leaks, or even the sounds made by the hardware during encryption. These attacks highlight the importance of secure implementation in addition to strong algorithms.' },
          { term: 'Kerckhoffs\'s principle', definition: 'A fundamental concept in cryptography stating that a cryptosystem should be secure even if everything about the system, except the key, is public knowledge. This principle, formulated by Auguste Kerckhoffs in the 19th century, emphasizes that the security of a cryptosystem should rely on the secrecy of the key rather than the secrecy of the algorithm.' },
          { term: 'Perfect secrecy', definition: 'A theoretical concept in cryptography where the ciphertext provides no information about the plaintext other than its length. Claude Shannon proved that the one-time pad is the only cryptosystem that achieves perfect secrecy. While theoretically ideal, perfect secrecy is often impractical due to the challenges of key distribution and management.' },
        ].map((item, index) => (
          <div key={index} className="mb-6">
            <div className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-primary-dark' : 'text-primary-light'}`}>
              {item.term}
            </div>
            <p className={`${isDarkMode ? 'text-text-dark' : 'text-text-light'}`}>
              {item.definition}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeyWords;