// src/components/BifidCipherApp.js
import React, { useState, useEffect } from 'react';

const BifidCipher = () => {
  const [input, setInput] = useState('');
  const [key, setKey] = useState('CRYPTOGRAPHY');
  const [output, setOutput] = useState('');
  const [mode, setMode] = useState('encode');
  const [testQuestion, setTestQuestion] = useState('');
  const [testAnswer, setTestAnswer] = useState('');
  const [testKey, setTestKey] = useState('');
  const [feedback, setFeedback] = useState('');

  const generateGrid = (key) => {
    const alphabet = 'ABCDEFGHIKLMNOPQRSTUVWXYZ';
    let grid = [];
    let usedChars = new Set();

    key = key.toUpperCase().replace(/J/g, 'I').replace(/[^A-Z]/g, '');
    for (let char of key) {
      if (!usedChars.has(char)) {
        grid.push(char);
        usedChars.add(char);
      }
    }

    for (let char of alphabet) {
      if (!usedChars.has(char)) {
        grid.push(char);
      }
    }

    return grid;
  };

  const findPosition = (grid, letter) => {
    const index = grid.indexOf(letter);
    return [Math.floor(index / 5) + 1, (index % 5) + 1];
  };

  const encodeBifid = (text, key) => {
    const grid = generateGrid(key);
    let rowNumbers = [];
    let colNumbers = [];

    text = text.toUpperCase().replace(/J/g, 'I').replace(/[^A-Z]/g, '');

    for (let char of text) {
      let [row, col] = findPosition(grid, char);
      rowNumbers.push(row);
      colNumbers.push(col);
    }

    let combined = [...rowNumbers, ...colNumbers];
    let result = '';

    for (let i = 0; i < combined.length; i += 2) {
      result += grid[(combined[i] - 1) * 5 + combined[i + 1] - 1];
    }

    return result;
  };

  const decodeBifid = (text, key) => {
    const grid = generateGrid(key);
    let numbers = [];

    text = text.toUpperCase().replace(/J/g, 'I').replace(/[^A-Z]/g, '');

    for (let char of text) { 
      let [row, col] = findPosition(grid, char);
      numbers.push(row);
      numbers.push(col);
    }

    let rowNumbers = numbers.slice(0, numbers.length / 2);
    let colNumbers = numbers.slice(numbers.length / 2);
    let result = '';

    for (let i = 0; i < rowNumbers.length; i++) {
      result += grid[(rowNumbers[i] - 1) * 5 + colNumbers[i] - 1];
    }

    return result;
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    processInput(e.target.value, key);
  };

  const handleKeyChange = (e) => {
    setKey(e.target.value);
    processInput(input, e.target.value);
  };

  const processInput = (text, key) => {
    const processedText = mode === 'encode' ? encodeBifid(text, key) : decodeBifid(text, key);
    setOutput(processedText);
  };

  const handleAppModeChange = (newMode) => {
    setMode(newMode);
    setInput('');
    setOutput('');
    setTestQuestion('');
    setTestAnswer('');
    setTestKey('');
    setFeedback('');
  };

  const generateTestQuestion = () => {
    const words = ['BIFID', 'CIPHER', 'CRYPTOGRAPHY', 'SECRET', 'MESSAGE'];
    const keys = ['KEYWORD', 'CIPHER', 'CRYPTOGRAPHY', 'SECRET'];
    const randomWord = words[Math.floor(Math.random() * words.length)];
    const randomKey = keys[Math.floor(Math.random() * keys.length)];
    const encryptedWord = encodeBifid(randomWord, randomKey);
    setTestQuestion(encryptedWord);
    setTestKey(randomKey);
    setTestAnswer('');
    setFeedback('');
  };

  const checkTestAnswer = () => {
    const decryptedAnswer = decodeBifid(testQuestion, testKey);
    if (testAnswer.toUpperCase() === decryptedAnswer) {
      setFeedback('Correct! Well done!');
    } else {
      setFeedback('Incorrect. Try again!');
    }
  };

  useEffect(() => {
    if (mode === 'test') {
      generateTestQuestion();
    }
  }, [mode]);

  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-4">Bifid Cipher</h2>
      
      <div className="mb-4">
        <button 
          onClick={() => handleAppModeChange('encode')} 
          className={`mr-2 px-4 py-2 rounded ${mode === 'encode' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Encode
        </button>
        <button 
          onClick={() => handleAppModeChange('decode')} 
          className={`mr-2 px-4 py-2 rounded ${mode === 'decode' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Decode
        </button>
        <button 
          onClick={() => handleAppModeChange('test')} 
          className={`px-4 py-2 rounded ${mode === 'test' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Test Skills
        </button>
      </div>

      {mode !== 'test' && (
        <>
          <div className="mb-4">
            <input
              type="text"
              value={key}
              onChange={handleKeyChange}
              placeholder="Enter key"
              className="w-full p-2 border rounded mb-2"
            />
            <textarea
              value={input}
              onChange={handleInputChange}
              placeholder={`Enter text to ${mode}`}
              className="w-full p-2 border rounded"
              rows="4"
            />
          </div>
          <div className="mb-4">
            <h3 className="font-bold mb-2">{mode === 'encode' ? 'Encoded' : 'Decoded'} Text:</h3>
            <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded break-all">
              {output}
            </div>
          </div>
        </>
      )}

      {mode === 'test' && (
        <>
          <div className="mb-4">
            <h3 className="font-bold mb-2">Decrypt this code:</h3>
            <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded break-all">
              {testQuestion}
            </div>
            <p className="mt-2">Key: {testKey}</p>
          </div>
          <div className="mb-4">
            <input
              type="text"
              value={testAnswer}
              onChange={(e) => setTestAnswer(e.target.value)}
              placeholder="Enter decrypted text"
              className="w-full p-2 border rounded"
            />
          </div>
          <button 
            onClick={checkTestAnswer}
            className="px-4 py-2 bg-green-500 text-white rounded"
          >
            Check Answer
          </button>
          {feedback && (
            <div className="mt-4 p-2 bg-gray-100 dark:bg-gray-700 rounded">
              {feedback}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BifidCipher;