// src/components/RailFenceVisual.js
import React, { useState, useEffect } from 'react';

const RailFenceVisual = () => {
  const [text, setText] = useState('RAILFENCECIPHER');
  const [rails, setRails] = useState(3);
  const [fence, setFence] = useState([]);

  useEffect(() => {
    createFence();
  }, [text, rails]);

  const createFence = () => {
    let newFence = new Array(rails).fill().map(() => new Array(text.length).fill(''));
    let rail = 0;
    let direction = 1;

    for (let i = 0; i < text.length; i++) {
      newFence[rail][i] = text[i];
      rail += direction;
      if (rail === 0 || rail === rails - 1) direction *= -1;
    }

    setFence(newFence);
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-lg bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <div className="mb-4">
        <input
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value.toUpperCase())}
          placeholder="Enter text"
          className="w-full p-2 border rounded mb-2"
        />
        <input
          type="number"
          min="2"
          value={rails}
          onChange={(e) => setRails(Math.max(2, parseInt(e.target.value) || 2))}
          className="w-full p-2 border rounded"
        />
      </div>
      <div className="grid gap-1">
        {fence.map((rail, i) => (
          <div key={i} className="flex">
            {rail.map((char, j) => (
              <div key={j} className="w-6 h-6 border flex items-center justify-center font-bold">
                {char}
              </div>
            ))}
          </div>
        ))}

      </div>
      <div className="mt-4">
        <h4 className="font-semibold">Encoded Message:</h4>
        <p className="mt-2 font-mono">
          {fence.flat().filter(char => char !== '').join('')}
        </p>
      </div>
    </div>
  );
};

export default RailFenceVisual;