// src/components/FeaturedCiphers.js
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const FeaturedCiphers = () => {
  const cipherTypes = [
    { name: 'Morse Code', path: '/learn/morse-code', icon: '•−•−•−', desc: 'Decode dots and dashes' },
    { name: "Caesar's Cipher", path: '/learn/caesars-cipher', icon: 'A→D', desc: 'Shift letters to encode messages' },
    { name: 'Pigpen Cipher', path: '/learn/pigpen-cipher', icon: '◊⊡⊟', desc: 'Use symbols to represent letters' },
    { name: 'Bacon Cipher', path: '/learn/bacon-cipher', icon: 'AABBA', desc: 'Hide messages in plain sight' },
    { name: 'Playfair Cipher', path: '/learn/playfair-cipher', icon: '5×5', desc: 'Encrypt pairs of letters' },
    { name: 'Rail Fence Cipher', path: '/learn/rail-fence-cipher', icon: '/\\/\\', desc: 'Scramble text in a zigzag pattern' },
    { name: 'Vigenère Cipher', path: '/learn/vigenere-cipher', icon: 'Aa-Zz', desc: 'Use a keyword to encrypt text' },
    { name: 'Enigma', path: '/learn/enigma', icon: '⚙️', desc: 'Explore the famous WWII machine' },
    { name: 'Bifid Cipher', path: '/learn/bifid-cipher', icon: '5²', desc: 'Combine fractionation and substitution' },
    { name: 'Affine Cipher', path: '/learn/affine-cipher', icon: 'αx+β', desc: 'Apply mathematics to encryption' },
    { name: 'AES (Advanced Encryption Standard)', path: '/learn/aes', icon: '🔐', desc: 'Learn modern block cipher encryption' },
    { name: 'RSA (Rivest–Shamir–Adleman)', path: '/learn/rsa', icon: '🗝️', desc: 'Discover public-key cryptography' },
  ];

  return (
    <div className="mt-16 px-4 max-w-6xl mx-auto">
      <motion.h2 
        className="text-3xl font-bold mb-8 text-center text-primary-light dark:text-primary-dark"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Featured Ciphers
      </motion.h2>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
        {cipherTypes.map((cipher, index) => (
          <motion.div 
            key={index}
            className="bg-secondary-light dark:bg-secondary-dark p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2"
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <Link to={cipher.path} className="block">
              <div className="text-4xl mb-4 text-primary-light dark:text-primary-dark font-bold">{cipher.icon}</div>
              <h3 className="text-xl font-semibold mb-2 text-text-light dark:text-text-dark">{cipher.name}</h3>
              <p className="text-text-light dark:text-text-dark opacity-80">{cipher.desc}</p>
              <div className="mt-4 text-primary-light dark:text-primary-dark font-semibold">
                Learn More →
              </div>
            </Link>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedCiphers;