import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";
import { ThemeContext } from "../../utils/themeContext"; // Adjust this import path as needed

const Modal = ({ isOpen, onClose, children }) => {
  const { theme } = useContext(ThemeContext);
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div
        className={`${
          theme === "dark"
            ? "bg-background-dark text-text-dark"
            : "bg-background-light text-text-light"
        } p-6 rounded-lg`}
      >
        {children}
        <button
          onClick={onClose}
          className={`mt-4 px-4 py-2 ${
            theme === "dark" ? "bg-primary-dark" : "bg-primary-light"
          } text-white rounded hover:bg-opacity-80`}
        >
          Close
        </button>
      </div>
    </div>
  );
};

const MorseFlashlight = ({ isOn }) => {
  return (
    <div
      className={`w-20 h-20 rounded-full mx-auto mb-4 transition-all duration-100 ${
        isOn ? "bg-gradient-to-r from-yellow-300 to-yellow-500" : "bg-gray-300"
      }`}
    />
  );
};

const EnhancedMorseCodeDemo = () => {
  const { theme } = useContext(ThemeContext);
  const [inputText, setInputText] = useState("");
  const [morseCode, setMorseCode] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(0.8);
  const [gameMode, setGameMode] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameDifficulty, setGameDifficulty] = useState("easy");
  const [currentChallenge, setCurrentChallenge] = useState("");
  const [userGuess, setUserGuess] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [isFlashOn, setIsFlashOn] = useState(false);
  const audioContextRef = useRef(null);
  const timeoutsRef = useRef([]);

  const easyWords = [
    "HELLO",
    "WORLD",
    "MORSE",
    "CODE",
    "RADIO",
    "SIGNAL",
    "DASH",
    "DOT",
    "LEARN",
    "FUN",
  ];
  const hardSentences = [
    "THE QUICK BROWN FOX",
    "JUMPS OVER THE LAZY DOG",
    "SOS PLEASE HELP ME",
    "WE ARE UNDER ATTACK",
    "COME HERE WATSON",
    "I NEED YOU",
    "MAYDAY MAYDAY MAYDAY",
    "ALPHA BRAVO CHARLIE",
    "THE MAN ON THE MOON",
    "TO BE OR NOT TO BE",
  ];

  const morseCodeMap = {
    A: ".-",
    B: "-...",
    C: "-.-.",
    D: "-..",
    E: ".",
    F: "..-.",
    G: "--.",
    H: "....",
    I: "..",
    J: ".---",
    K: "-.-",
    L: ".-..",
    M: "--",
    N: "-.",
    O: "---",
    P: ".--.",
    Q: "--.-",
    R: ".-.",
    S: "...",
    T: "-",
    U: "..-",
    V: "...-",
    W: ".--",
    X: "-..-",
    Y: "-.--",
    Z: "--..",
    0: "-----",
    1: ".----",
    2: "..---",
    3: "...--",
    4: "....-",
    5: ".....",
    6: "-....",
    7: "--...",
    8: "---..",
    9: "----.",
  };

  const textToMorse = useCallback((text) => {
    return text
      .toUpperCase()
      .split("")
      .map((char) => morseCodeMap[char] || char)
      .join(" ");
  }, []);

  useEffect(() => {
    setMorseCode(textToMorse(inputText));
  }, [inputText, textToMorse]);

  const playMorseCode = useCallback(
    (code) => {
      setIsPlaying(true);
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      const dot = 60 / playbackSpeed;
      const dash = dot * 3;
      const pause = dot;
      const letterPause = dot * 3;

      let time = 0;

      code.split("").forEach((char) => {
        switch (char) {
          case ".":
            timeoutsRef.current.push(
              setTimeout(() => {
                playBeep(dot);
                setIsFlashOn(true);
              }, time)
            );
            timeoutsRef.current.push(
              setTimeout(() => setIsFlashOn(false), time + dot)
            );
            time += dot + pause;
            break;
          case "-":
            timeoutsRef.current.push(
              setTimeout(() => {
                playBeep(dash);
                setIsFlashOn(true);
              }, time)
            );
            timeoutsRef.current.push(
              setTimeout(() => setIsFlashOn(false), time + dash)
            );
            time += dash + pause;
            break;
          case " ":
            time += letterPause;
            break;
          default:
            break;
        }
      });

      timeoutsRef.current.push(setTimeout(() => setIsPlaying(false), time));
    },
    [playbackSpeed]
  );

  const playBeep = (duration) => {
    const oscillator = audioContextRef.current.createOscillator();
    oscillator.type = "sine";
    oscillator.frequency.value = 600;
    oscillator.connect(audioContextRef.current.destination);
    oscillator.start();
    oscillator.stop(audioContextRef.current.currentTime + duration / 1000);
  };

  const startGame = () => {
    setGameMode(true);
    setGameStarted(false);
    setUserGuess("");
  };

  const beginChallenge = () => {
    setGameStarted(true);
    newChallenge();
  };

  const newChallenge = () => {
    const challenges = gameDifficulty === "easy" ? easyWords : hardSentences;
    const challenge = challenges[Math.floor(Math.random() * challenges.length)];
    setCurrentChallenge(challenge);
    setUserGuess("");
  };

  const playCurrentChallenge = () => {
    playMorseCode(textToMorse(currentChallenge));
  };

  const checkAnswer = () => {
    if (userGuess.toUpperCase() === currentChallenge) {
      setModalContent("Correct! Well done!");
      setModalOpen(true);
      newChallenge();
    } else {
      setModalContent("Sorry, that's not correct. Try again!");
      setModalOpen(true);
    }
  };

  const playSOS = () => {
    const sosText = "SOS";
    setInputText(sosText);
    setMorseCode(textToMorse(sosText));
    playMorseCode(textToMorse(sosText));
  };

  return (
    <div
      className={`max-w-md mx-auto mt-10 p-6 rounded-lg shadow-xl ${
        theme === "dark"
          ? "bg-background-dark text-text-dark"
          : "bg-background-light text-text-light"
      }`}
    >
      <h1
        className={`text-3xl font-bold mb-6 text-center ${
          theme === "dark" ? "text-text-dark" : "text-primary-light"
        }`}
      >
        Interactive Morse Code
      </h1>

      <MorseFlashlight isOn={isFlashOn} />

      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <p>{modalContent}</p>
      </Modal>

      {!gameMode ? (
        <>
          <input
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            className={`w-full px-4 py-2 border-2 ${
              theme === "dark"
                ? "border-primary-dark bg-secondary-dark"
                : "border-primary-light bg-secondary-light"
            } rounded-md mb-4 focus:outline-none focus:border-opacity-75`}
            placeholder="Enter text to translate"
          />
          <div
            className={`${
              theme === "dark" ? "bg-secondary-dark" : "bg-secondary-light"
            } p-4 rounded-md mb-4 shadow-inner`}
          >
            <h2 className="text-lg font-semibold mb-2">Morse Code:</h2>
            <p className="font-mono text-lg break-words">{morseCode}</p>
          </div>
          <div className="flex space-x-4 mb-4">
            <button
              onClick={() => playMorseCode(morseCode)}
              disabled={isPlaying || !morseCode}
              className={`flex-1 py-2 px-4 rounded-md transition-colors duration-200 ${
                isPlaying || !morseCode
                  ? "bg-gray-300 cursor-not-allowed"
                  : theme === "dark"
                  ? "bg-primary-dark hover:bg-opacity-80"
                  : "bg-primary-light hover:bg-opacity-80"
              } text-white`}
            >
              {isPlaying ? "Playing..." : "Play Morse Code"}
            </button>
            <button
              onClick={playSOS}
              className={`flex-1 py-2 px-4 rounded-md ${
                theme === "dark" ? "bg-red-700" : "bg-red-500"
              } hover:bg-opacity-80 text-white transition-colors duration-200`}
            >
              SOS Signal
            </button>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium">Playback Speed</label>
            <input
              type="range"
              min="0.5"
              max="2"
              step="0.1"
              value={playbackSpeed}
              onChange={(e) => setPlaybackSpeed(parseFloat(e.target.value))}
              className="w-full"
            />
          </div>
          <button
            onClick={startGame}
            className={`w-full py-2 px-4 rounded-md ${
              theme === "dark" ? "bg-primary-dark" : "bg-primary-light"
            } hover:bg-opacity-80 text-white transition-colors duration-200`}
          >
            Start Morse Code Game
          </button>
        </>
      ) : (
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Decode the Morse Code</h2>
          <div className="mb-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio"
                name="difficulty"
                value="easy"
                checked={gameDifficulty === "easy"}
                onChange={() => setGameDifficulty("easy")}
              />
              <span className="ml-2">Easy</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                className="form-radio"
                name="difficulty"
                value="hard"
                checked={gameDifficulty === "hard"}
                onChange={() => setGameDifficulty("hard")}
              />
              <span className="ml-2">Hard</span>
            </label>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium">Playback Speed</label>
            <input
              type="range"
              min="0.5"
              max="2"
              step="0.1"
              value={playbackSpeed}
              onChange={(e) => setPlaybackSpeed(parseFloat(e.target.value))}
              className="w-full"
            />
          </div>
          {!gameStarted ? (
            <button
              onClick={beginChallenge}
              className={`w-full py-2 px-4 rounded-md ${
                theme === "dark" ? "bg-green-700" : "bg-green-500"
              } hover:bg-opacity-80 text-white transition-colors duration-200 mb-4`}
            >
              Start Challenge
            </button>
          ) : (
            <>
              <button
                onClick={playCurrentChallenge}
                disabled={isPlaying}
                className={`w-full py-2 px-4 rounded-md mb-4 transition-colors duration-200 ${
                  isPlaying
                    ? "bg-gray-300 cursor-not-allowed"
                    : theme === "dark"
                    ? "bg-green-700 hover:bg-opacity-80"
                    : "bg-green-500 hover:bg-opacity-80"
                } text-white`}
              >
                {isPlaying ? "Playing..." : "Play Morse Code"}
              </button>
              <input
                type="text"
                value={userGuess}
                onChange={(e) => setUserGuess(e.target.value)}
                className={`w-full px-4 py-2 border-2 ${
                  theme === "dark"
                    ? "border-primary-dark bg-secondary-dark"
                    : "border-primary-light bg-secondary-light"
                } rounded-md mb-4 focus:outline-none focus:border-opacity-75`}
                placeholder="Enter your guess"
              />
              <button
                onClick={checkAnswer}
                className={`w-full py-2 px-4 rounded-md ${
                  theme === "dark" ? "bg-primary-dark" : "bg-primary-light"
                } hover:bg-opacity-80 text-white transition-colors duration-200 mb-2`}
              >
                Submit Answer
              </button>
              <button
                onClick={newChallenge}
                className={`w-full py-2 px-4 rounded-md ${
                  theme === "dark" ? "bg-yellow-600" : "bg-yellow-500"
                } hover:bg-opacity-80 text-white transition-colors duration-200 mb-2`}
              >
                New Word(s)
              </button>
            </>
          )}
          <button
            onClick={() => setGameMode(false)}
            className={`w-full py-2 px-4 rounded-md ${
              theme === "dark" ? "bg-red-700" : "bg-red-500"
            } hover:bg-opacity-80 text-white transition-colors duration-200`}
          >
            Exit Game
          </button>
        </div>
      )}
    </div>
  );
};

export default EnhancedMorseCodeDemo;
