import React, { useState, useContext } from "react";
import { Collapse, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EnhancedMorseCodeDemo from "../../components/ciphers/MorseCodeApp";
import MorseCodeChart from "../../components/ciphers/MorseCodeChart";
import { ThemeContext } from "../../utils/themeContext";

const MorseCodePage = () => {
  const [expandedSection, setExpandedSection] = useState("generalInfo");
  const { isDarkMode } = useContext(ThemeContext);

  const handleToggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <div
      className={`min-h-screen flex flex-col bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark`}
    >
      
      <div className="flex flex-1 flex-col md:flex-row">
        <div className="md:w-2/3 p-6">
        <h1 className="text-3xl font-bold mb-6">
            Morse Code: Dots and Dashes of Communication
          </h1>
          <div className="space-y-4">
            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("generalInfo")}
              >
                
                <h2 className="text-xl font-semibold">General Information</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "generalInfo" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "generalInfo"}>
                <div className="mt-2">
                  <p className="mb-4">
                    Morse code is a method of encoding text characters as
                    standardized sequences of two different signal durations,
                    called dots and dashes. It's named after Samuel Morse, one
                    of the inventors of the telegraph. In Morse code, each
                    letter and number is represented by a unique combination of
                    dots and dashes. A dash is three times longer than a dot,
                    and each dot or dash is followed by a short silence equal to
                    the dot duration.
                  </p>
                  <p>
                    Morse code can be transmitted in various ways, including:
                    <ul className="list-disc pl-5 mt-2">
                      <li>Electrical pulses along a telegraph wire</li>
                      <li>Audio tones</li>
                      <li>Radio signals</li>
                      <li>Visual signals (like flashing lights)</li>
                    </ul>
                  </p>
                  <p className="mt-4">
                    This versatility has made Morse code useful in many
                    different situations throughout history.
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("history")}
              >
                <h2 className="text-xl font-semibold">History</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "history" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "history"}>
                <div className="mt-2">
                  <p className="mb-4">
                    Morse code was developed in the 1830s and 1840s by Samuel
                    Morse, Alfred Vail, and other inventors for use in
                    telegraphy. It was designed to transmit text messages over
                    long distances using a series of electrical pulses.
                  </p>
                  <p className="mb-4">
                    The first public demonstration of Morse code took place on
                    May 24, 1844, when Samuel Morse sent the famous message
                    "WHAT HATH GOD WROUGHT" from Washington, D.C. to Baltimore,
                    Maryland [1]. This event marked the beginning of the
                    telegraph era, revolutionizing long-distance communication.
                  </p>

                  <h3 className="text-lg font-semibold mb-2">
                    The Spark of Inspiration
                  </h3>
                  <p className="mb-4">
                    The idea for the telegraph came to Samuel Morse in a moment
                    of personal tragedy. In 1825, while Morse was painting a
                    portrait of the Marquis de Lafayette in Washington, D.C.,
                    his wife died in New Haven, Connecticut. It took seven days
                    for the news to reach him [2]. This experience highlighted
                    the need for faster long-distance communication and set
                    Morse on the path to developing the telegraph and Morse
                    code.
                  </p>

                  <p>
                    Morse code played a crucial role in various fields
                    throughout history:
                    <ul className="list-disc pl-5 mt-2">
                      <li>
                        It was widely used in maritime communications, helping
                        ships communicate with each other and with shore
                        stations.
                      </li>
                      <li>
                        In aviation, it was used for navigation and
                        communication before voice radio became common.
                      </li>
                      <li>
                        During both World Wars, Morse code was extensively used
                        for military communications due to its simplicity and
                        reliability.
                      </li>
                    </ul>
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("useCases")}
              >
                <h2 className="text-xl font-semibold">Use Cases</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "useCases" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "useCases"}>
                <div className="mt-2">
                  <p className="mb-4">
                    While Morse code is no longer the primary method of
                    communication in most fields, it still finds applications in
                    various areas:
                  </p>
                  <ol className="list-decimal pl-5 mb-4">
                    <li>
                      Amateur Radio: Many ham radio operators (amateur radio
                      enthusiasts) still use Morse code. It's often more
                      reliable than voice communication in poor signal
                      conditions.
                    </li>
                    <li>
                      Aviation: Morse code is used in radio navigation aids like
                      VOR (VHF Omnidirectional Range) and NDB (Non-Directional
                      Beacon). These systems help pilots determine their
                      position and navigate safely.
                    </li>
                    <li>
                      Assistive Technology: For individuals with disabilities
                      who cannot communicate verbally, Morse code can be an
                      alternative method of communication.
                    </li>
                    <li>
                      Emergency Situations: When other forms of communication
                      are unavailable, Morse code can be used to signal for
                      help. The famous SOS distress signal (••• --- •••) is an
                      example of this.
                    </li>
                    <li>
                      Cultural and Educational Uses: Morse code is popular among
                      enthusiasts and is sometimes used in puzzles, games, and
                      as a novelty in various media. It's also taught in some
                      scouting programs and military training.
                    </li>
                  </ol>
                </div>
              </Collapse>
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("interactiveModel")}
              >
                <h2 className="text-xl font-semibold">Interactive Model</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "interactiveModel" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "interactiveModel"}>
                <div className="mt-2">
                  <p className="mb-4">
                    Our interactive Morse code translator allows users to input
                    text and see its Morse code equivalent in real-time. Users
                    can also play back the Morse code as audio, helping them
                    understand how the code sounds when transmitted.
                  </p>
                  <p className="mb-4">
                    This tool is useful for learning Morse code, as it provides
                    both visual and auditory feedback. Users can experiment with
                    different words and phrases, observing how they are
                    represented in Morse code.
                  </p>
                  <p className="mb-4">
                    The playback feature simulates how Morse code would sound
                    when transmitted, with short beeps for dots and longer beeps
                    for dashes. This auditory component is crucial for
                    understanding the rhythm and timing of Morse code
                    transmission.
                  </p>
                  <p className="mb-4">
                    We've also included a simple Morse code game to help users
                    practice their skills, as well as a demonstration of the
                    famous SOS signal. The adjustable playback speed allows
                    users to start slow and gradually increase their speed as
                    they become more proficient.
                  </p>
                  <p>
                    Learning Morse code can be a fun challenge that exercises
                    your memory and concentration. It's also a unique skill that
                    connects you to the history of communication technology.
                    While it's rarely used in everyday life, understanding Morse
                    code can be useful in emergency situations or as a hobby in
                    amateur radio.
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("morseChart")}
              >
                <h2 className="text-xl font-semibold">Morse Code Chart</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "morseChart" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "morseChart"}>
                <div className="mt-2">
                  <p className="mb-4">
                    Below is a complete chart of the Morse code alphabet. This
                    visual representation shows the unique combination of dots
                    and dashes for each letter and number. A helpful tip for
                    memorizing Morse code is to look for patterns. For example,
                    the letter 'E' (•) is the most common letter in English and
                    has the shortest code, while less common letters like 'Q'
                    (--•-) have longer codes.
                  </p>
                  <MorseCodeChart />
                </div>
              </Collapse>
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggleSection("references")}
              >
                <h2 className="text-xl font-semibold">References</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === "references" ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse in={expandedSection === "references"}>
                <div className="mt-2">
                  <ol className="list-decimal pl-5">
                    <li>
                      Smithsonian Institution. (n.d.). Samuel F.B. Morse's
                      Telegram.{" "}
                      <a
                        href="https://americanhistory.si.edu/blog/2012/05/samuel-finley-breese-morse-artist-and-inventor.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        https://americanhistory.si.edu/blog/2012/05/samuel-finley-breese-morse-artist-and-inventor.html
                      </a>
                    </li>
                    <li>
                      Lienhard, J. H. (n.d.). How Samuel Morse Got His Big Idea.
                      Smithsonian Magazine.{" "}
                      <a
                        href="https://www.smithsonianmag.com/smithsonian-institution/how-samuel-morse-got-his-big-idea-16403094/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        https://www.smithsonianmag.com/smithsonian-institution/how-samuel-morse-got-his-big-idea-16403094/
                      </a>
                    </li>
                  </ol>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
        <div
          className={`md:w-1/3 p-6 dark:bg-third-light text-text-light dark:text-text-dark shadow-lg`}
        >
          <EnhancedMorseCodeDemo isDarkMode={isDarkMode} />
        </div>
      </div>
    </div>
  );
};

export default MorseCodePage;
