import React, { useState } from 'react';
import CryptoJS from 'crypto-js';

const AESEncryptionApp = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [key, setKey] = useState('');
  const [iv, setIv] = useState('');
  const [mode, setMode] = useState('encrypt');
  const [keyError, setKeyError] = useState('');

  const validateKey = (key) => {
    const keyBytes = CryptoJS.enc.Utf8.parse(key).sigBytes;
    if (keyBytes !== 16 && keyBytes !== 24 && keyBytes !== 32) {
      setKeyError('Key must be 16, 24, or 32 bytes long');
      return false;
    }
    setKeyError('');
    return true;
  };

  const handleKeyChange = (e) => {
    const newKey = e.target.value;
    setKey(newKey);
    validateKey(newKey);
  };

  const handleEncrypt = () => {
    if (!validateKey(key)) return;
    try {
      const encrypted = CryptoJS.AES.encrypt(input, CryptoJS.enc.Utf8.parse(key), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      setOutput(encrypted.toString());
    } catch (error) {
      setOutput('Encryption error: ' + error.message);
    }
  };

  const handleDecrypt = () => {
    if (!validateKey(key)) return;
    try {
      const decrypted = CryptoJS.AES.decrypt(input, CryptoJS.enc.Utf8.parse(key), {
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      setOutput(decrypted.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      setOutput('Decryption error: ' + error.message);
    }
  };

  const handleProcess = () => {
    if (mode === 'encrypt') {
      handleEncrypt();
    } else {
      handleDecrypt();
    }
  };

  const generateRandomIv = () => {
    const randomIv = CryptoJS.lib.WordArray.random(16);
    setIv(CryptoJS.enc.Hex.stringify(randomIv));
  };

  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-4">AES Encryption/Decryption</h2>
      
      <div className="mb-4">
        <button 
          onClick={() => setMode('encrypt')} 
          className={`mr-2 px-4 py-2 rounded ${mode === 'encrypt' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Encrypt
        </button>
        <button 
          onClick={() => setMode('decrypt')} 
          className={`px-4 py-2 rounded ${mode === 'decrypt' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Decrypt
        </button>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">Key (16, 24, or 32 bytes):</label>
        <input
          type="text"
          value={key}
          onChange={handleKeyChange}
          className={`w-full p-2 border rounded ${keyError ? 'border-red-500' : ''}`}
          placeholder="Enter encryption key"
        />
        {keyError && <p className="text-red-500 text-sm mt-1">{keyError}</p>}
        <p className="text-sm text-gray-600 mt-1">
          Current key length: {CryptoJS.enc.Utf8.parse(key).sigBytes} bytes
        </p>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">IV (16 bytes):</label>
        <div className="flex">
          <input
            type="text"
            value={iv}
            onChange={(e) => setIv(e.target.value)}
            className="flex-grow p-2 border rounded-l"
            placeholder="Enter IV or generate random"
          />
          
        </div>
        <button 
            onClick={generateRandomIv}
            className="px-4 py-2 bg-green-500 text-white rounded-r"
          >
            Generate Random IV
          </button>
      </div>

      <div className="mb-4">
        <textarea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder={`Enter text to ${mode}`}
          className="w-full p-2 border rounded"
          rows="4"
        />
      </div>

      <button 
        onClick={handleProcess}
        className="mb-4 px-4 py-2 bg-blue-500 text-white rounded"
        disabled={!!keyError}
      >
        {mode === 'encrypt' ? 'Encrypt' : 'Decrypt'}
      </button>

      <div className="mb-4">
        <h3 className="font-bold mb-2">{mode === 'encrypt' ? 'Encrypted' : 'Decrypted'} Text:</h3>
        <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded break-all">
          {output}
        </div>
      </div>
    </div>
  );
};

export default AESEncryptionApp;