import React from 'react';

const Resources = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-background-light dark:bg-background-dark">
      <div className="text-center">
        <h1 className="text-5xl md:text-6xl font-extrabold text-text-light dark:text-text-dark mb-4">
          Coming Soon
        </h1>
        <p className="text-xl md:text-2xl text-text-light dark:text-text-dark mb-8">
          We're working hard to bring you amazing resources. Stay tuned!
        </p>
      </div>
    </div>
  );
};

export default Resources;