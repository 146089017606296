// src/components/RailFenceCipherApp.js
import React, { useState } from 'react';

const RailFenceCipher = () => {
  const [input, setInput] = useState('');
  const [rails, setRails] = useState(3);
  const [output, setOutput] = useState('');
  const [mode, setMode] = useState('encode');
  const [testQuestion, setTestQuestion] = useState('');
  const [testAnswer, setTestAnswer] = useState('');
  const [testRails, setTestRails] = useState(3);
  const [feedback, setFeedback] = useState('');

  const encodeRailFence = (text, rails) => {
    if (rails < 2) return text;
    let fence = new Array(rails).fill().map(() => []);
    let rail = 0;
    let direction = 1;

    for (let char of text) {
      fence[rail].push(char);
      rail += direction;
      if (rail === 0 || rail === rails - 1) direction *= -1;
    }

    return fence.flat().join('');
  };

  const decodeRailFence = (text, rails) => {
    if (rails < 2) return text;
    let fence = new Array(rails).fill().map(() => new Array(text.length).fill(''));
    let rail = 0;
    let direction = 1;

    for (let i = 0; i < text.length; i++) {
      fence[rail][i] = '*';
      rail += direction;
      if (rail === 0 || rail === rails - 1) direction *= -1;
    }

    let i = 0;
    for (let r = 0; r < rails; r++) {
      for (let c = 0; c < text.length; c++) {
        if (fence[r][c] === '*' && i < text.length) {
          fence[r][c] = text[i++];
        }
      }
    }

    let result = '';
    rail = 0;
    direction = 1;
    for (let i = 0; i < text.length; i++) {
      result += fence[rail][i];
      rail += direction;
      if (rail === 0 || rail === rails - 1) direction *= -1;
    }

    return result;
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    processInput(e.target.value, rails);
  };

  const handleRailsChange = (e) => {
    const newRails = parseInt(e.target.value) || 2;
    setRails(newRails);
    processInput(input, newRails);
  };

  const processInput = (text, rails) => {
    const processedText = mode === 'encode' ? encodeRailFence(text, rails) : decodeRailFence(text, rails);
    setOutput(processedText);
  };

  const handleAppModeChange = (newMode) => {
    setMode(newMode);
    setInput('');
    setOutput('');
    setTestQuestion('');
    setTestAnswer('');
    setTestRails(3);
    setFeedback('');
    if (newMode === 'test') {
      generateTestQuestion();
    }
  };

  const generateTestQuestion = () => {
    const words = ['RAILFENCE', 'ZIGZAG', 'TRANSPOSITION', 'CIPHER', 'ENCRYPTION', 'LEARNCIPHERISFUN', 'WEAREUNDERATTACK', 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];
    const randomWord = words[Math.floor(Math.random() * words.length)];
    const randomRails = Math.floor(Math.random() * 4) + 2; // 2 to 6 rails
    const encryptedWord = encodeRailFence(randomWord, randomRails);
    setTestQuestion(encryptedWord);
    setTestRails(randomRails);
    setTestAnswer('');
    setFeedback('');
  };

  const checkTestAnswer = () => {
    const decryptedAnswer = decodeRailFence(testQuestion, testRails);
    if (testAnswer.toUpperCase() === decryptedAnswer) {
      setFeedback('Correct! Well done!');
    } else {
      setFeedback('Incorrect. Try again!');
    }
  };

  const handleTestButtonClick = () => {
    if (mode === 'test') {
      generateTestQuestion();
    } else {
      handleAppModeChange('test');
    }
  };

  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-4">Rail Fence Cipher</h2>
      
      <div className="mb-4">
        <button 
          onClick={() => handleAppModeChange('encode')} 
          className={`mr-2 px-4 py-2 rounded ${mode === 'encode' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Encode
        </button>
        <button 
          onClick={() => handleAppModeChange('decode')} 
          className={`mr-2 px-4 py-2 rounded ${mode === 'decode' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Decode
        </button>
        <button 
          onClick={handleTestButtonClick}
          className={`px-4 py-2 rounded ${mode === 'test' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Test Skills
        </button>
      </div>

      {mode !== 'test' && (
        <>
          <div className="mb-4">
            <label className="block mb-2">Number of Rails:</label>
            <input
              type="number"
              min="2"
              value={rails}
              onChange={handleRailsChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <textarea
              value={input}
              onChange={handleInputChange}
              placeholder={`Enter text to ${mode}`}
              className="w-full p-2 border rounded"
              rows="4"
            />
          </div>
          <div className="mb-4">
            <h3 className="font-bold mb-2">{mode === 'encode' ? 'Encoded' : 'Decoded'} Text:</h3>
            <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded break-all">
              {output}
            </div>
          </div>
        </>
      )}

      {mode === 'test' && (
        <>
          <div className="mb-4">
            <h3 className="font-bold mb-2">Decrypt this code:</h3>
            <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded break-all">
              {testQuestion}
            </div>
            <p className="mt-2">Number of Rails: {testRails}</p>
          </div>
          <div className="mb-4">
            <input
              type="text"
              value={testAnswer}
              onChange={(e) => setTestAnswer(e.target.value)}
              placeholder="Enter decrypted text"
              className="w-full p-2 border rounded"
            />
          </div>
          <button 
            onClick={checkTestAnswer}
            className="px-4 py-2 bg-green-500 text-white rounded"
          >
            Check Answer
          </button>
          {feedback && (
            <div className="mt-4 p-2 bg-gray-100 dark:bg-gray-700 rounded">
              {feedback}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RailFenceCipher;