// src/components/PigpenCipherApp.js
import React, { useState, useEffect } from "react";

const PigpenCipher = () => {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [mode, setMode] = useState("encode"); // 'encode' or 'test'
  const [testQuestion, setTestQuestion] = useState("");
  const [testAnswer, setTestAnswer] = useState("");
  const [feedback, setFeedback] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState("");

  const pigpenAlphabet = {
    A: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 90 L90 90 L90 10"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
      </svg>
    ),
    B: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 10 L10 90 L90 90 L90 10"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
      </svg>
    ),
    C: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 10 L10 90 L90 90"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
      </svg>
    ),
    D: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 10 L90 10 L90 90 L10 90"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
      </svg>
    ),
    E: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 10 L10 90 L90 90 L90 10 L10 10"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
      </svg>
    ),
    F: (
      <svg viewBox="0 0 100 100">
        <path
          d="M90 10 L10 10 L10 90 L90 90"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
      </svg>
    ),
    G: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 10 L90 10 L90 90"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
      </svg>
    ),
    H: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 90 L10 10 L90 10 L90 90"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
      </svg>
    ),
    I: (
      <svg viewBox="0 0 100 100">
        <path
          d="M90 10 L10 10 L10 90"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
      </svg>
    ),
    J: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 90 L90 90 L90 10"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
        <circle cx="50" cy="50" r="10" fill="currentColor" />
      </svg>
    ),
    K: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 10 L10 90 L90 90 L90 10"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
        <circle cx="50" cy="50" r="10" fill="currentColor" />
      </svg>
    ),
    L: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 10 L10 90 L90 90"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
        <circle cx="50" cy="50" r="10" fill="currentColor" />
      </svg>
    ),
    M: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 10 L90 10 L90 90 L10 90"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
        <circle cx="50" cy="50" r="10" fill="currentColor" />
      </svg>
    ),
    N: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 10 L10 90 L90 90 L90 10 L10 10"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
        <circle cx="50" cy="50" r="10" fill="currentColor" />
      </svg>
    ),
    O: (
      <svg viewBox="0 0 100 100">
        <path
          d="M90 10 L10 10 L10 90 L90 90"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
        <circle cx="50" cy="50" r="10" fill="currentColor" />
      </svg>
    ),
    P: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 10 L90 10 L90 90"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
        <circle cx="60" cy="50" r="10" fill="currentColor" />
      </svg>
    ),
    Q: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 90 L10 10 L90 10 L90 90"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
        <circle cx="50" cy="50" r="10" fill="currentColor" />
      </svg>
    ),
    R: (
      <svg viewBox="0 0 100 100">
        <path
          d="M90 10 L10 10 L10 90"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
        <circle cx="50" cy="50" r="10" fill="currentColor" />
      </svg>
    ),
    S: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 10 L50 90 L90 10"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
      </svg>
    ),
    T: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 10 L90 50 L10 90"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
      </svg>
    ),
    U: (
      <svg viewBox="0 0 100 100">
        <path
          d="M90 10 L10 50 L90 90"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
      </svg>
    ),
    V: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 90 L50 10 L90 90"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
      </svg>
    ),
    W: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 10 L50 90 L90 10"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
        <circle cx="50" cy="30" r="10" fill="currentColor" />
      </svg>
    ),
    X: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 10 L90 50 L10 90"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
        <circle cx="30" cy="50" r="10" fill="currentColor" />
      </svg>
    ),
    Y: (
      <svg viewBox="0 0 100 100">
        <path
          d="M90 10 L10 50 L90 90"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
        <circle cx="70" cy="50" r="10" fill="currentColor" />
      </svg>
    ),
    Z: (
      <svg viewBox="0 0 100 100">
        <path
          d="M10 90 L50 10 L90 90"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
        />
        <circle cx="50" cy="70" r="10" fill="currentColor" />
      </svg>
    ),
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    if (mode === "encode") {
      processInput(e.target.value);
    }
  };

  const processInput = (text) => {
    const processedText = text
      .toUpperCase()
      .split("")
      .map((char) => pigpenAlphabet[char] || char);
    setOutput(processedText);
  };

  const handleAppModeChange = (newMode) => {
    setMode(newMode);
    setInput("");
    setOutput("");
    setTestQuestion("");
    setTestAnswer("");
    setFeedback("");
    if (newMode === "test") {
      generateTestQuestion();
    }
  };

  const generateTestQuestion = () => {
    const words = ["HELLO", "WORLD", "CIPHER", "SECRET", "MESSAGE"];
    const randomWord = words[Math.floor(Math.random() * words.length)];
    const encryptedWord = randomWord
      .split("")
      .map((char) => pigpenAlphabet[char] || char);
    setTestQuestion(encryptedWord);
    setCorrectAnswer(randomWord);
    setTestAnswer("");
    setFeedback("");
  };

  const checkTestAnswer = () => {
    if (testAnswer.toUpperCase().trim() === correctAnswer) {
      setFeedback("Correct! Well done!");
    } else {
      setFeedback("Incorrect. Try again!");
    }
  };

  useEffect(() => {
    if (mode === "test") {
      generateTestQuestion();
    }
  }, [mode]);

  const PigpenSymbol = ({ symbol }) => {
    return <span className="inline-block w-6 h-6">{symbol}</span>;
  };

  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-4">Pigpen Cipher</h2>

      <div className="mb-4">
        <button
          onClick={() => handleAppModeChange("encode")}
          className={`mr-2 px-4 py-2 rounded ${
            mode === "encode" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
        >
          Encode
        </button>
        <button
          onClick={() => handleAppModeChange("test")}
          className={`px-4 py-2 rounded ${
            mode === "test" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
        >
          Test Skills
        </button>
      </div>

      {mode === "encode" && (
        <>
          <div className="mb-4">
            <textarea
              value={input}
              onChange={handleInputChange}
              placeholder="Enter text to encrypt"
              className="w-full p-2 border rounded"
              rows="4"
            />
          </div>
          <div className="mb-4">
            <h3 className="font-bold mb-2">Encrypted Text:</h3>
            <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded">
              {output &&
                output.map((symbol, index) => (
                  <PigpenSymbol key={index} symbol={symbol} />
                ))}
            </div>
          </div>
        </>
      )}

      {mode === "test" && (
        <>
          <div className="mb-4">
            <h3 className="font-bold mb-2">Decrypt this code:</h3>
            <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded">
              {testQuestion &&
                testQuestion.map((symbol, index) => (
                  <PigpenSymbol key={index} symbol={symbol} />
                ))}
            </div>
          </div>
          <div className="mb-4">
            <input
              type="text"
              value={testAnswer}
              onChange={(e) => setTestAnswer(e.target.value)}
              placeholder="Enter decrypted text"
              className="w-full p-2 border rounded"
            />
          </div>
          <button
            onClick={checkTestAnswer}
            className="px-4 py-2 bg-green-500 text-white rounded"
          >
            Check Answer
          </button>
          {feedback && (
            <div className="mt-4 p-2 bg-gray-100 dark:bg-gray-700 rounded">
              {feedback}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PigpenCipher;
