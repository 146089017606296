import React, { useState } from 'react';
import { Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PigpenCipher from '../../components/ciphers/PigpenCipherApp';

const PigpenCipherPage = () => {
  const [expandedSection, setExpandedSection] = useState('generalInfo');

  const handleToggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <div className="min-h-screen flex flex-col bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark">
      <div className="flex flex-1 flex-col md:flex-row">
        <div className="md:w-2/3 p-6">
        <h1 className="text-3xl font-bold mb-6">
        Pigpen Cipher: Symbols and Secrets in Squares
          </h1>
          <div className="space-y-4">
            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('generalInfo')}>
                <h2 className="text-xl font-semibold">General Information</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'generalInfo' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'generalInfo'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Pigpen cipher, also known as the masonic cipher or Freemason's cipher, is a geometric simple substitution cipher. It replaces letters with symbols based on a grid pattern, creating a visually distinct ciphertext [1].
                  </p>
                  <p className="mb-4">
                    This cipher uses a set of grids or "pens" to encode letters. Each letter is represented by the shape of the "pen" around it, with dots added to differentiate between similar shapes. This unique approach results in a ciphertext that looks like a series of abstract symbols.
                  </p>
                  <p>
                    Despite its visual complexity, the Pigpen cipher is relatively easy to crack once the pattern is recognized. It remains popular in puzzles, games, and as an introduction to substitution ciphers in cryptography education [2].
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('history')}>
                <h2 className="text-xl font-semibold">History</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'history' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'history'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Pigpen cipher has its origins in the 18th century. It was primarily used by Freemasons to keep their records private and for correspondence between lodge members. The earliest known appearance of this cipher is in the 1700s, but its exact date of creation remains unknown [3].
                  </p>
                  <p className="mb-4">
                    While commonly associated with Freemasonry, the cipher's use extended beyond this group. During the American Civil War, Union soldiers used the Pigpen cipher to communicate secretly, especially when held as prisoners of war [4].
                  </p>
                  <p>
                    The cipher's popularity has endured over centuries, finding its way into various contexts including literature, puzzles, and even modern digital games. Its enduring appeal lies in its visual distinctiveness and the intrigue it adds to coded messages.
                  </p>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('layout')}>
                <h2 className="text-xl font-semibold">Pigpen Layout</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'layout' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'layout'}>
                <div className="mt-2">
                  <p className="mb-4">
                    The Pigpen cipher uses a specific grid layout to represent letters. The basic structure consists of two 3x3 grids and two X shapes. Each letter is represented by the shape of the "pen" around it, with dots added to differentiate between similar shapes [5].
                  </p>
                  <p className="mb-4">
                    The layout typically follows this pattern:
                    - The first grid contains the letters A to I
                    - The second grid contains the letters J to R
                    - The first X contains the letters S to V
                    - The second X contains the letters W to Z
                  </p>
                  <p className="mb-4">
                    Variations of this layout exist, including versions that use different arrangements or include numbers and punctuation marks.
                  </p>
                  <img src="/PigPenFrame.png" alt="Pigpen Cipher Frame" className="max-w-full h-auto" />
                  <img src="/Pigpen.png" alt="Pigpen Cipher Layout" className="max-w-full h-auto" />
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('useCases')}>
                <h2 className="text-xl font-semibold">Use Cases</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'useCases' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'useCases'}>
                <div className="mt-2">
                  <p className="mb-4">
                    While the Pigpen cipher is not secure for modern cryptographic purposes, it finds applications in various areas:
                  </p>
                  <ol className="list-decimal pl-5 mb-4">
                    <li className="mb-2">Educational tools: Used to introduce basic concepts of cryptography and substitution ciphers.</li>
                    <li className="mb-2">Puzzles and games: Often featured in escape rooms, mystery novels, and puzzle books.</li>
                    <li className="mb-2">Artistic and decorative purposes: The unique symbols make it popular for creating visually interesting coded messages.</li>
                    <li className="mb-2">Scouting activities: Sometimes used in scouting programs to teach about codes and ciphers.</li>
                    <li>Novelty communications: Used by some groups for non-sensitive communications, adding an element of fun or mystique.</li>
                  </ol>
                </div>
              </Collapse>
            </div>

            <div>
              <div className="flex justify-between items-center cursor-pointer" onClick={() => handleToggleSection('references')}>
                <h2 className="text-xl font-semibold">References</h2>
                <IconButton className="text-text-light dark:text-text-dark">
                  {expandedSection === 'references' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <Collapse in={expandedSection === 'references'}>
                <div className="mt-2">
                  <ol className="list-decimal pl-5">
                    <li>
                      Kahn, D. (1996). The Codebreakers: The Comprehensive History of Secret Communication from Ancient Times to the Internet. Scribner. pp. 772-773.
                    </li>
                    <li>
                      Singh, S. (1999). The Code Book: The Science of Secrecy from Ancient Egypt to Quantum Cryptography. Anchor Books. pp. 16-17.
                    </li>
                    <li>
                      Pratt, F. (1942). Secret and Urgent: The Story of Codes and Ciphers. Blue Ribbon Books. pp. 126-128.
                    </li>
                    <li>
                      Kahn, D. (1997). Seizing the Enigma: The Race to Break the German U-Boat Codes, 1939-1943. Houghton Mifflin. p. 35.
                    </li>
                    <li>
                      Wrixon, F. B. (1998). Codes, Ciphers, Secrets and Cryptic Communication: Making and Breaking Secret Messages from Hieroglyphs to the Internet. Black Dog & Leventhal Publishers. pp. 180-181.
                    </li>
                  </ol>
                </div>
              </Collapse>
            </div>
          </div>
        </div>

        <div className="md:w-1/3 p-6 bg-secondary-light dark:bg-secondary-dark shadow-lg">
          <PigpenCipher />
        </div>
      </div>
    </div>
  );
};

export default PigpenCipherPage;