// src/components/VigenereCipherAlphabet.js
import React, { useState, useEffect } from 'react';

const VigenereCipherAlphabet = () => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const [keyword, setKeyword] = useState('CODE');
  const [message, setMessage] = useState('SECRET');
  const [currentPosition, setCurrentPosition] = useState(-1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPosition((prev) => (prev + 1) % message.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [message.length]);

  const getHighlightedCells = () => {
    if (currentPosition === -1) return { yellow: [], green: null };
    
    const keyLetter = keyword[currentPosition % keyword.length];
    const msgLetter = message[currentPosition];
    const rowIndex = alphabet.indexOf(keyLetter);
    const colIndex = alphabet.indexOf(msgLetter);
    
    return {
      yellow: [
        { row: 0, col: colIndex + 1 },
        { row: rowIndex + 1, col: 0 },
      ],
      green: { row: rowIndex + 1, col: colIndex + 1 }
    };
  };

  const highlightedCells = getHighlightedCells();

  const getCellHighlight = (row, col) => {
    if (highlightedCells.yellow.some(cell => cell.row === row && cell.col === col)) {
      return 'bg-yellow-200';
    }
    if (highlightedCells.green && highlightedCells.green.row === row && highlightedCells.green.col === col) {
      return 'bg-green-200';
    }
    return '';
  };

  return (
    <div className="bg-white p-2 sm:p-6 rounded-lg shadow-lg bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark overflow-x-auto">
      <h2 className="text-xl sm:text-2xl font-bold mb-4">Vigenère Cipher Table</h2>
      <div className="mb-4 space-y-2">
        <div>
          <label className="block text-sm font-medium text-gray-700">Key</label>
          <input 
            value={keyword} 
            onChange={(e) => setKeyword(e.target.value.toUpperCase())} 
            placeholder="Enter keyword"
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Plaintext</label>
          <input 
            value={message} 
            onChange={(e) => setMessage(e.target.value.toUpperCase())} 
            placeholder="Enter message"
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>
      <div className="mb-4">
        <p className="text-sm">Current Position: {currentPosition === -1 ? 'None' : currentPosition + 1}</p>
      </div>
      <div className="overflow-x-auto">
        <table className="border-collapse w-full text-xs sm:text-sm">
          <thead>
            <tr>
              <th className="border p-1 sm:p-2"></th>
              {alphabet.split('').map((letter, index) => (
                <th key={letter} className={`border p-1 sm:p-2 ${getCellHighlight(0, index + 1)}`}>{letter}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {alphabet.split('').map((rowLetter, rowIndex) => (
              <tr key={rowLetter}>
                <th className={`border p-1 sm:p-2 ${getCellHighlight(rowIndex + 1, 0)}`}>{rowLetter}</th>
                {alphabet.split('').map((colLetter, colIndex) => (
                  <td 
                    key={colLetter} 
                    className={`border p-1 sm:p-2 text-center ${getCellHighlight(rowIndex + 1, colIndex + 1)}`}
                  >
                    {String.fromCharCode(((rowIndex + colIndex) % 26) + 65)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VigenereCipherAlphabet;